@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap');

$blueBackground: #87a8fb;
$bluseTextColor: #0245f5;
$grayBackground: #cacaca;
$grayTextColor: #575757;
$transparentLightBackgroundColor: rgba(255, 255, 255, 1);
$meunBoxshadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
$red: #f51818;

.whitespace-normal {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

textarea {
    resize: none;
}

@font-face {
    font-family: sourcecode;
    src: url("fonts/SourceCodePro-Regular.ttf");
}

@font-face {
    font-family: segoeui;
    src: url('fonts/Segoe_UI.woff');
}

* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    ;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    width: 100%;
}

button {
    outline: none;
}

.app {
    width: 100%;
    max-width: 1500px;
    margin: auto;
}

.line {
    width: calc(100% - 15px);
    height: 2px;
    background-color: #dbdbdb;
    margin: auto;
    border-radius: 3px;
}

.navbar {
    width: 100%;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
    z-index: 500;
    backdrop-filter: blur(1rem);

    .logo {
        width: 130px;
        margin-left: 25px;
    }

    .nav_items {
        width: fit-content;
        display: flex;
        justify-content: center;
        list-style-type: none;

        .nav_item {
            cursor: pointer;
            margin-right: 15px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .icon {
                padding: 5px;
                background-color: #fff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: #f1f1f1;
                border: 1px solid #999;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 20px;
                    height: fit-content;
                    fill: #555;
                }
            }

            .menu {
                position: absolute;
                // left: 0;
                box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
                margin-top: 15px;
                top: -500px;
                z-index: 0;
            }

            .menu-active {
                top: 0;
                transition: all 0.2s ease-in-out;
            }

            .mobile-nav {
                margin: auto;
                margin-top: 50px;

                .mobile-nav-list {
                    padding: 10px;
                    background-color: #fff;
                    width: 270px;
                    height: 246px;
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    border-radius: 7px;

                    li {
                        display: flex;
                        align-items: center;
                        margin-top: 5px;
                        padding: 7px;
                        cursor: pointer;
                        border-radius: 5px;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.1);
                        }

                        &:nth-child(1) {
                            margin-top: 0;
                        }

                        svg {
                            width: 20px;
                            // height: fit-content;
                            height: 20px;
                            fill: rgba(0, 0, 0, 0.5);
                        }

                        p {
                            margin-left: 15px;
                            font-weight: 400;
                        }
                    }
                }
            }

            .notifications-area {
                width: 270px;
                margin-right: 120px;
                margin-top: 50px;

                h5 {
                    color: rgba(0, 0, 0, 0.8);
                    font-size: 0.9rem;
                    margin-bottom: 10px;
                }

                .notifications-list {
                    width: 100%;
                    height: 100%;
                    max-height: 350px;
                    min-height: 300px;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    border-radius: 7px;
                    padding: 10px;
                    list-style-type: none;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 0.6vw;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        display: none;

                        &:hover {
                            display: block;
                        }
                    }

                    &:hover {
                        &::-webkit-scrollbar-thumb {
                            background-color: #c8c8c9;
                            border-radius: 5px;
                            display: block;
                        }
                    }

                    .notification {
                        padding: 7px 5px;
                        cursor: pointer;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        color: rgba(0, 0, 0, 0.9);

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.1);
                        }

                        p {
                            font-size: 0.8rem;
                            font-weight: 400;
                        }

                        h6 {
                            font-size: 0.7rem;
                        }
                    }
                }
            }

            .profile-img-navbar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #999;
                display: flex;
                justify-content: center;
                align-items: center;
                background-size: cover;
                background-position: center;
            }

            .profile-dropdown {
                width: 280px;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                border-radius: 7px;
                padding: 10px;
                height: fit-content;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-right: 210px;
                margin-top: 50px;

                .profile-nav-link {
                    width: 92%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 10px;
                    cursor: pointer;
                    border-radius: 5px;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }

                .footer {
                    display: flex;
                    list-style-type: none;
                    flex-wrap: wrap;
                    margin-top: 7px;

                    li {
                        color: $grayTextColor;
                        font-weight: 500;
                        font-size: 0.7rem;
                        margin-right: 7px;
                        cursor: pointer;

                        &:hover {
                            text-decoration-line: underline;
                        }
                    }
                }

                .copyright {
                    font-size: 0.7rem;
                    color: $grayTextColor;
                    font-weight: 400;
                    margin-top: 7px;
                }
            }
        }

        // .nav_item {
        //     width: 40px;
        //     height: 40px;
        //     border-radius: 50%;
        //     background-color: rgba(0,0,0,0.1);
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     margin-right: 15px;
        //     transition: all 0.2 ease-in-out;
        //     cursor: pointer;
        //     svg {
        //         width: 20px;
        //         height: fit-content;
        //         fill: #3c3c3c;
        //         margin-top: 5px;
        //     }
        //     &:hover {
        //         background-color: rgba(0,0,0,0.15);
        //     }
        // }
        // .profile-image {
        //     width: 35px;
        //     height: 35px;
        //     min-width: 35px;
        //     min-height: 35px;
        //     background-size: cover;
        //     background-position: center;
        //     border: 2px solid #0245f5;
        // }
        // .drop-shadow {
        //     box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
        //     margin-top: 10px;
        // }
        // .dropdown {
        //     .menu {
        //         width: 100%;
        //         max-width: 280px;
        //         background-color: #fff;
        //         opacity: 0;
        //         z-index: -1;
        //         position: absolute;
        //         right: 0px;
        //         padding: 10px;
        //         border-radius: 5px;
        //         // top: 0;
        //         // right: 0;
        //         transform: translateY(-10px);
        //         transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
        //         top: -600px;
        //     }
        // }
        // .notifi-dropdown {
        //     width: 100%;
        //     display: flex;
        //     flex-direction: column;
        //     align-items: flex-start;
        //     height: 370px;
        //     overflow-y: auto;
        //     &::-webkit-scrollbar {
        //         width: 0.6vw;
        //     }
        //     &::-webkit-scrollbar-thumb {
        //         background-color: #c8c8c9;
        //         border-radius: 5px;
        //         display: none;
        //         &:hover {
        //             display: block;
        //         }
        //     }
        //     &:hover {
        //         &::-webkit-scrollbar-thumb {
        //             background-color: #c8c8c9;
        //             border-radius: 5px;
        //             display: block;
        //         }
        //     }
        //     li {
        //         cursor: pointer;
        //         margin-top: 5px;
        //         padding: 7px 5px;
        //         width: calc(100% - 15px);
        //         border-radius: 4px;
        //         position: relative;
        //         p {
        //             font-size: 0.8rem;
        //             font-weight: 400;
        //         }
        //         &:hover {
        //             // text-decoration: underline;
        //             background-color: rgba(0,0,0,0.1);
        //         }
        //     }
        // }
        // .menu.active {
        //     opacity: 1;
        //     position: absolute;
        //     left: calc(100vw - 330px);
        //     transform: translateY(0);
        //     top: 50px;
        //     z-index: 200;
        // }
        // .profile-drop {
        //     .menu {
        //         height: fit-content;
        //         max-height: 500px;
        //         height: 400px;
        //         .goto-profile {
        //             display: flex;
        //             align-items: center;
        //             padding: 8px;
        //             cursor: pointer;
        //             border-radius: 5px;
        //             .img {
        //                 width: 35px;
        //                 height: 35px;
        //                 background-size: cover;
        //                 background-position: center;
        //                 border-radius: 50%;
        //                 border: 2px solid #0245f5;
        //             }
        //             h5 {
        //                 margin-left: 10px;
        //             }
        //             &:hover {
        //                 background-color: rgba(0,0,0,0.05);
        //             }
        //         }
        //         .logout {
        //             display: flex;
        //             align-items: center;
        //             padding: 8px;
        //             cursor: pointer;
        //             border-radius: 5px;
        //             margin-top: 15px;
        //             height: fit-content;
        //             li {
        //                 width: 28px;
        //                 height: 28px;
        //                 display: flex;
        //                 justify-content: center;
        //                 align-items: center;
        //                 svg {
        //                     width: 13px;
        //                 }
        //             }
        //             h5 {
        //                 margin-left: 10px;
        //             }
        //             &:hover {
        //                 background-color: rgba(0,0,0,0.05);
        //             }
        //         }
        //     }
        // .footer {
        //     display: flex;
        //     list-style-type: none;
        //     flex-wrap: wrap;
        //     margin-top: 7px;
        //     li {
        //         color: $grayTextColor;
        //         font-weight: 500;
        //         font-size: 0.7rem;
        //         margin-right: 7px;
        //         cursor: pointer;
        //         &:hover {
        //             text-decoration-line: underline;
        //         }
        //     }
        // }
        // .copyright {
        //     font-size: 0.7rem;
        //     color: $grayTextColor;
        //     font-weight: 400;
        //     margin-top: 7px;
        // }
        // }
        // .mobile-nav {
        //     li {
        //         svg {
        //             width: 20px;
        //         }
        //     }
        //     // display: flex;
        //     // flex-direction: column;
        //     // align-items: flex-start;
        //     // li {
        //     //     display: flex;
        //     //     padding: 10px;
        //     //     align-items: center;
        //     //     cursor: pointer;
        //     //     // margin-top: 10px;
        //     //     width: calc(100% - 30px);
        //     //     border-radius: 5px;
        //     //     svg {
        //     //         width: 15px;
        //     //         height: fit-content;
        //     //         fill: #5e5c5c;
        //     //     }
        //     //     p {
        //     //         margin-left: 20px;
        //     //         font-weight: 400;
        //     //         color: #5e5c5c;
        //     //     }
        //     //     &:hover {
        //     //         background-color: rgba(0,0,0,0.1);
        //     //     }
        //     // }
        // }
        .notify-badge {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: rgb(242, 71, 71);
            color: #fff;
            font-size: 0.8rem;
            text-align: center;
            position: absolute;
            margin-top: -20px;
            margin-right: -27px;
        }

        @media screen and (max-width: 600px) {
            .lg {
                display: none;
            }

            .mobile {
                display: flex;
            }
        }
    }

    @media screen and (max-width: 420px) {
        .logo {
            margin-left: 10px;
            width: 100px;
        }

        ul {
            margin-right: 0px;
        }
    }
}

.notifi {
    .notifi-area {
        overflow-y: auto;
        height: 100%;
        max-height: 500px;

        &::-webkit-scrollbar {
            width: 0.6vw;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c8c8c9;
            border-radius: 5px;
            // opacity: hi;
            display: none;

            &:hover {
                display: block;
            }
        }

        &:hover {
            &::-webkit-scrollbar-thumb {
                background-color: #c8c8c9;
                border-radius: 5px;
                display: block;
            }
        }

        overflow-x: hidden;

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            max-height: 500px;
            // overflow-y: auto;
        }
    }
}

.notification-icon {
    .notifications {
        position: absolute;
        width: 250px;
        height: fit-content;
        padding: 10px;
        background-color: #fff;
        right: 15px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        h4 {
            display: flex;
            align-items: center;

            img {
                width: 15px;
                background: transparent;
                border: none;
            }
        }

        ul {
            width: 100%;
            display: flex;
            flex-direction: column;

            li {
                padding: 8px 0px;
                cursor: pointer;
                width: 100%;
                margin: auto;
                border-radius: 5px;

                &:hover {
                    background-color: #fafafa;
                }

                span {
                    font-size: 0.9rem;
                    font-weight: 400;
                    padding: 10px;
                }
            }
        }
    }
}

.none {
    display: none;
}

.icons-d {
    display: none;
}

.user-icon-link {
    display: none;
}

@media screen and (max-width: 850px) {
    .icons-d {
        display: block;
    }

    .group-icon {
        display: none;
    }

    .nav-link {
        display: none;
    }

    // .navbar {
    //     ul {
    //         // li {
    //             // margin-top: -5px;
    //         // }
    //     }
    // }
}

.group-icon {
    display: none;
}

@media screen and (max-width: 500px) {
    .create-icon {
        display: none;
    }
}

.sidebar {
    padding-top: 65px;
    width: 250px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    z-index: 1;

    &::-webkit-scrollbar {
        width: 0.6vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c8c8c9;
        border-radius: 5px;
        // opacity: hi;
        display: none;

        &:hover {
            display: block;
        }
    }

    .nav-profile-image {
        width: 200px;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
        margin-top: 25px;
        display: flex;
        align-items: center;
        z-index: -2551;
        position: static;

        .img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            border: 2px solid #1b81f5;
        }

        h3 {
            font-size: 0.8rem;
            margin-left: 10px;
            width: 150px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
            color: #7f7f7f;
        }
    }

    .groups {
        width: 100%;
        margin-top: 25px;
        // padding-bottom: 12px;

        h5 {
            color: #999696;
            margin-left: 15px;
            margin-bottom: 10px;
            font-size: 1rem;
            cursor: pointer;

            &:hover {
                text-decoration-line: underline;
            }
        }

        ul {
            width: 220px;
            background-color: #fff;
            // height: 500px;
            margin: auto;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 10px;
            border-radius: 10px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
            padding-top: 0px;

            // padding-bottom: 12px;
            li {
                display: flex;
                align-items: center;
                padding-top: 7px;
                padding-bottom: 7px;
                width: 100%;
                border-radius: 10px;
                cursor: pointer;

                &:hover {
                    background-color: #f0f1f2;
                }

                .img {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                    margin-left: 15px;
                }

                h6 {
                    color: #646464;
                    margin-left: 15px;
                }
            }
        }
    }

    .line {
        display: none;
    }

    @media screen and (max-width: 760px) {
        display: none;
    }
}

.home {
    width: calc(100% - 250px);
    margin-left: 250px;
    display: flex;
    justify-content: space-around;
    padding-top: 70px;
    padding-bottom: 25px;

    .post-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 18px);
        margin-top: 25px;
        .create-top {
            width: calc(100% - 10px);
            max-width: 585px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #eeeeee;
            display: flex;
            align-items: center;
            background-color: #fff;

            .img {
                width: 50px;
                height: 50px;
                min-width: 50px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                border: 2px solid #eeeeee;
            }

            h5 {
                width: 100%;
                max-width: 510px;
                margin-left: 20px;
                padding: 10px 15px;
                background-color: #edebeb;
                border-radius: 25px;
                color: #8c8c8c;
                cursor: pointer;

                &:hover {
                    background-color: #e0dede;
                }
            }
        }

        .posts {
            margin: 0;
            margin-left: 16px;
            margin-top: 5px;
        }
    }

    .desc {
        width: 100%;
        max-width: 330px;
        margin: 10px;
        z-index: 1;
        margin-top: 20px;

        .scroll {
            position: sticky;
            top: 60px;

            section {
                overflow-y: auto;
                margin-bottom: 25px;

                .con {

                    // overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 0.6vw;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        // opacity: hi;
                        display: none;

                        &:hover {
                            display: block;
                        }
                    }

                    .top {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
                        padding: 10px;
                        border-radius: 8px;
                        border: 1px solid #eeeeee;
                        background-color: #fff;

                        h3 {
                            color: #3b3a3a;
                            font-size: 1rem;
                            color: #0245f5;
                            opacity: 0.8;
                        }

                        p {
                            margin-top: 15px;
                            font-size: 0.85rem;
                            font-weight: 400;
                            color: #4c4c4c;
                        }

                        button {
                            width: 120px;
                            padding: 8px 13px;
                            border-radius: 5px;
                            margin-top: 15px;
                            border: 2px solid #0245f5;
                            color: #0245f5;
                            font-family: 'Poppins', sans-serif;
                            background: transparent;
                            cursor: pointer;
                            transition: linear 0.1s;

                            &:hover {
                                background-color: #0245f5;
                                color: #fff;
                            }
                        }

                        .line {
                            margin-top: 15px;
                        }
                    }

                    .bottom {
                        max-height: calc(100vh - 444px);
                        padding: 10px;
                        border-radius: 8px;
                        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
                        overflow-y: auto;
                        padding-left: 0px;

                        h3 {
                            color: #3b3a3a;
                            font-size: 1rem;
                            color: #0245f5;
                            opacity: 0.8;
                        }

                        &::-webkit-scrollbar {
                            width: 0.6vw;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #c8c8c9;
                            border-radius: 5px;
                            // opacity: hi;
                            display: none;

                            &:hover {
                                display: block;
                            }
                        }

                        ul {
                            border-radius: 10px;
                            height: 100%;
                            list-style-type: none;
                            display: flex;
                            margin: auto;
                            flex-direction: column;
                            align-items: flex-start;
                            border-radius: 10px;
                            padding-top: 0px;
                            padding-bottom: 12px;

                            // margin-top: 15px;
                            li {
                                display: flex;
                                align-items: center;
                                padding-top: 7px;
                                padding-bottom: 7px;
                                width: 100%;
                                border-radius: 10px;
                                cursor: pointer;
                                margin-top: 10px;

                                &:hover {
                                    background-color: #f0f1f2;
                                }

                                .img {
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    background-size: cover;
                                    background-position: center;
                                    // margin-left: 15px;
                                    border: 1.5px solid #1b81f5;
                                }

                                h6 {
                                    color: #646464;
                                    margin-left: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .desc {
            display: none;
        }
    }
}

.posts {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px;
    max-width: 600px;

    .post {
        width: calc(100% - 15px);
        max-width: 700px;
        margin-top: 15px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
        border: 1px solid #eeeeee;
        background-color: #fff;
        margin-left: -12px;

        .head {
            display: flex;
            width: 100%;
            justify-content: space-between;

            section {
                display: flex;
                align-items: center;

                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-left: 10px;
                    cursor: pointer;

                    h3 {
                        font-size: 0.9rem;
                        color: #252525;

                        &:hover {
                            text-decoration-line: underline;
                        }
                    }

                    p {
                        font-size: 0.8rem;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        color: #979797;
                    }
                }

                .img {
                    width: 43px;
                    height: 43px;
                    border-radius: 50%;
                    background-color: #f6f6f6;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid #1b81f5;
                    cursor: pointer;

                    section {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                    }
                }
            }

            .menu-area {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;

                label {
                    width: fit-content;
                    min-width: fit-content;
                    height: fit-content;
                    position: absolute;
                    margin: 10px;
                    margin-right: 50px;
                    padding: 7px;
                    background-color: rgba(238, 238, 238, 1);
                    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(1.5rem);
                    border-radius: 4px;
                    margin-top: 40px;

                    h5 {
                        width: 100px;
                        font-size: 0.8rem;
                        text-align: center;
                    }
                }

                .icon {
                    width: 20px;
                    cursor: pointer;
                    padding: 7px;
                    border-radius: 50%;
                    // margin-top: 15px;
                    border: 1px solid transparent;
                    z-index: 1;

                    &:hover {
                        border: 1px solid rgba(0, 0, 0, 0.07);
                        background-color: #e3e3e3d1;
                    }
                }

                .menu {
                    right: 10px;
                    // padding: 5px 8px;
                    //    background-color: #e7e7e7;
                    width: 180px;
                    border-radius: 5px;
                    opacity: 0;
                    //    position: relative;
                    transform: translateY(-10px);
                    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                    z-index: -5;
                    margin-top: 0px;
                    height: fit-content;
                    position: absolute;
                    background-color: $transparentLightBackgroundColor;
                    box-shadow: $meunBoxshadow;
                    backdrop-filter: blur(1rem);

                    section {
                        flex-direction: column;
                        top: 50px;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        width: 160px;
                        padding: 10px;
                        border-radius: 5px;

                        // margin-top: 5px;
                        &:hover {
                            background-color: rgb(243, 243, 243);
                        }

                        cursor: pointer;

                        img {
                            width: 20px;
                        }

                        h6 {
                            margin-left: 10px;
                        }
                    }
                }

                .show-menu {
                    z-index: 1;
                    opacity: 1;
                    transform: translateY(0);
                    margin-top: 30px;
                }
            }

            svg {
                width: 20px;
                cursor: pointer;
                height: fit-content;
                padding: 7px;
                border-radius: 50%;
                // margin-top: 15px;
                border: 1px solid transparent;
                z-index: 1;

                &:hover {
                    border: 1px solid rgba(0, 0, 0, 0.07);
                    background-color: #e3e3e3d1;
                }
            }
        }

        .content {
            display: flex;
            justify-content: center;
            margin-top: 15px;
            flex-direction: column;

            p {
                font-weight: 400;
                width: calc(100% - 15px);
                font-size: 0.8rem;
                margin: auto;
            }

            .tags {
                margin-top: 15px;
                width: calc(100% - 30px);
                display: flex;
                flex-wrap: wrap;
                height: auto;

                h5 {
                    margin: 10px;
                    padding: 5px 10px;
                    background-color: #eae8e8;
                    width: fit-content;
                    border: 1px solid rgba(0, 0, 0, 0.07);
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }

        .post-img {
            width: 100%;
            margin: auto;
            text-align: center;
            margin-top: 15px;
            border-radius: 10px;
            cursor: pointer;
        }

        .action {
            display: flex;
            align-items: center;
            margin: 15px;
            margin-left: -5px;

            section {
                &:last-child {
                    img {
                        margin-top: 2px;
                    }
                }

                display: flex;
                align-items: center;
                margin-left: 10px;

                img {
                    width: 20px;
                    margin: 10px;
                    cursor: pointer;
                    z-index: 1;
                }

                .support {
                    position: relative;
                    height: 35px;

                    h4 {
                        position: absolute;
                        font-size: 0.7rem;
                        padding: 7px;
                        background-color: rgba(238, 238, 238, 0.733);
                        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
                        backdrop-filter: blur(1.5rem);
                        border-radius: 4px;
                        opacity: 0;
                        margin-left: -10px;
                        margin-top: -5px;
                        z-index: 100;
                    }

                    &:hover {
                        background-position: right;
                        animation: animatesupport .8s steps(28) 1;

                        @keyframes animatesupport {
                            0% {
                                background-position: left;
                            }

                            100% {
                                background-position: right;
                            }
                        }

                        h4 {
                            opacity: 1;
                        }
                    }
                }

                img {
                    &:hover {
                        background-position: right;
                        animation: animatesupport .8s steps(28) 1;

                        @keyframes animatesupport {
                            0% {
                                background-position: left;
                            }

                            100% {
                                background-position: right;
                            }
                        }
                    }
                }

                span {
                    font-size: 0.8rem;
                    font-weight: 400;
                }
            }
        }

        .add-comment {
            width: 100%;
            display: flex;
            align-items: flex-start;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 8px;

            img {
                margin-top: 10px;
                cursor: pointer;
                margin-left: 10px;

                &:hover {
                    filter: brightness(2);
                }
            }

            textarea {
                width: 100%;
                padding: 10px;
                // border: 1px solid rgba(0,0,0,0.4);
                border-radius: 5px;
                font-family: 'Poppins', sans-serif;
                outline-color: $blueBackground;
                margin-left: 5px;
                border: none;
                outline: none;
                background: transparent;
            }
        }

        .comment-action {
            width: 100%;
            height: fit-content;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: all 0.2 ease-in-out;

            .checkbox {
                display: flex;
                align-items: center;
                margin-top: 10px;

                label {
                    h5 {
                        color: gray;
                        font-size: 0.7rem;
                    }
                }

                input {
                    cursor: pointer;
                    margin-left: 10px;
                }
            }

            button {
                margin-top: 10px;
                outline: none;
                padding: 7px;
                cursor: pointer;
                border: 1.5px solid #0c6ddc;
                background: transparent;
                border-radius: 5px;
                font-family: 'Poppins', sans-serif;
                font-size: 0.7rem;
                font-weight: 400;
                color: #0c6ddc;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: #0c6ddc;
                    color: #fff;
                }
            }
        }

        .comments {
            margin-top: 15px;
            width: fit-content;

            .user-com {
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: fit-content;

                section {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    padding: 0px;
                    margin: 0px;
                    flex-direction: column;

                    .img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        border: 1.5px solid #0245f5;

                        h5 {
                            margin-left: 20px;
                        }
                    }
                }

                .icon {
                    width: 20px;
                    cursor: pointer;
                    // padding: 7px;
                    border-radius: 50%;
                    margin-left: 10px;
                    margin-top: 5px;
                    border: 1px solid transparent;

                    &:hover {
                        border: 1px solid rgba(0, 0, 0, 0.07);
                        background-color: #e3e3e3d1;
                    }
                }

                .menu-area {
                    position: relative;

                    .icon {
                        width: 20px;
                        cursor: pointer;
                        padding: 7px;
                        border-radius: 50%;
                        // margin-top: 15px;
                        border: 1px solid transparent;

                        &:hover {
                            border: 1px solid rgba(0, 0, 0, 0.07);
                            background-color: #e3e3e3d1;
                        }
                    }

                    .menu {
                        right: -50px;
                        background-color: $transparentLightBackgroundColor;
                        backdrop-filter: blur(1rem);
                        backdrop-filter: 1rem;
                        width: 180px;
                        border-radius: 5px;
                        opacity: 0;
                        //    position: relative;
                        transform: translateY(-10px);
                        transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                        z-index: -5;
                        margin-top: 5px;
                        height: fit-content;
                        position: absolute;
                        box-shadow: $meunBoxshadow;
                        margin-left: 25px;

                        section {
                            flex-direction: column;
                            top: 50px;
                        }

                        span {
                            display: flex;
                            align-items: center;
                            width: 160px;
                            padding: 10px;
                            border-radius: 5px;

                            &:hover {
                                background-color: rgb(243, 243, 243);
                            }

                            cursor: pointer;

                            img {
                                width: 20px;
                            }

                            h6 {
                                margin-left: 10px;
                            }
                        }
                    }

                    .show-menu {
                        z-index: 1;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            .comment {
                margin-top: 20px;
                border-left: 1px solid #c4c4c4;
                margin-left: 15px;
                margin-top: -8px;
                max-width: 450px;
                width: calc(100% - 50px);

                p {
                    padding: 10px;
                    background-color: #f6f6f6;
                    width: fit-content;
                    font-size: 0.8rem;
                    border-radius: 5px;
                    max-width: 400px;
                    display: flex;
                    flex-wrap: wrap;
                }

                button {
                    border: none;
                    background: transparent;
                    outline: none;
                    margin-left: 35px;
                    cursor: pointer;
                    color: #4c4c4c;

                    &:hover {
                        text-decoration-line: underline;
                    }
                }

                .reply {
                    margin-top: 5px;
                    padding-top: 5px;
                    width: 100%;
                    margin-left: 35px;

                    form {
                        background: transparent;

                        textarea {
                            background-color: rgba(0, 0, 0, 0.1);
                        }

                        .user-img {
                            width: 35px;
                            height: 35px;
                            min-width: 35px;
                            min-height: 35px;
                            border-radius: 50%;
                            border: 2px solid #1b81f5;
                        }
                    }

                    button {
                        margin-top: 10px;
                        outline: none;
                        padding: 7px;
                        cursor: pointer;
                        border: 1.5px solid #0c6ddc;
                        background: transparent;
                        border-radius: 5px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 0.7rem;
                        font-weight: 400;
                        color: #0c6ddc;
                        transition: all 0.3s ease-in-out;
                        text-decoration: none;

                        &:hover {
                            background-color: #0c6ddc;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .replies {
            ul {
                list-style-type: none;
                margin-left: 60px;

                li {
                    width: fit-content;
                    margin-top: 15px;
                    height: fit-content;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: -60px;

                    .line {
                        width: 30px;
                        height: 1px;
                        background-color: #c4c4c4;
                        margin-top: 22px;
                        border-top-left-radius: 5px;
                    }

                    .repl {
                        display: flex;
                        flex-direction: column;

                        .user {
                            width: fit-content;
                            display: flex;
                            align-items: center;

                            .img {
                                width: 35px;
                                height: 35px;
                                min-width: 35px;
                                min-height: 35px;
                                border-radius: 50%;
                                background-size: cover;
                                background-position: center;
                                background-repeat: no-repeat;
                                border: 1.5px solid #0245f5;
                            }

                            img {
                                width: 15px;
                                margin-left: 15px;
                                cursor: pointer;
                                padding: 8px;
                                border-radius: 50%;

                                &:hover {
                                    background-color: #f6f6f6;
                                }
                            }

                            .menu-area {
                                position: relative;

                                .icon {
                                    width: 20px;
                                    cursor: pointer;
                                    padding: 7px;
                                    border-radius: 50%;
                                    // margin-top: 15px;
                                    border: 1px solid transparent;

                                    &:hover {
                                        border: 1px solid rgba(0, 0, 0, 0.07);
                                        background-color: #e3e3e3d1;
                                    }
                                }

                                .menu {
                                    right: 0px;
                                    background-color: #e7e7e7;
                                    width: 180px;
                                    border-radius: 5px;
                                    opacity: 0;
                                    transform: translateY(0px);
                                    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                                    z-index: -5;
                                    margin-top: 5px;
                                    height: fit-content;
                                    position: absolute;
                                    padding: 0px;
                                    background-color: $transparentLightBackgroundColor;
                                    backdrop-filter: blur(1rem);
                                    box-shadow: $meunBoxshadow;

                                    section {
                                        flex-direction: column;
                                        top: 30px;
                                    }

                                    span {
                                        display: flex;
                                        align-items: center;
                                        width: 160px;
                                        // padding: 10px;
                                        border-radius: 5px;
                                        margin: 0px;
                                        height: 20px;

                                        &:hover {
                                            background-color: rgb(243, 243, 243);
                                        }

                                        cursor: pointer;

                                        img {
                                            width: 20px;
                                        }

                                        h6 {
                                            margin-left: 10px;
                                        }
                                    }
                                }

                                .show-menu {
                                    z-index: 1;
                                    opacity: 1;
                                    transform: translateY(0);
                                }
                            }
                        }

                        .repl-text {
                            margin-left: 10px;

                            p {
                                padding: 10px;
                                background-color: #f6f6f6;
                                width: fit-content;
                                max-width: 500px;
                            }

                            h6 {
                                cursor: pointer;
                                font-size: 0.7rem;
                                font-weight: 400;
                                margin-left: 30px;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            span {
                                margin-left: 0px;
                            }

                            .add_sub_reply {
                                display: flex;
                                align-items: center;
                                margin-top: 20px;
                                margin-left: 0px;

                                .img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 50%;
                                    background-size: cover;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    border: 1.5px solid #0245f5;
                                }

                                input {
                                    width: calc(100% - 50px);
                                    max-width: 400px;
                                    padding: 10px;
                                    outline: none;
                                    border: 1px solid rgba(0, 0, 0, 0.06);
                                    background-color: rgba(0, 0, 0, 0.05);
                                    border-radius: 25px;
                                    font-family: 'Poppins', sans-serif;
                                    margin-left: 15px;
                                    padding-left: 15px;
                                }
                            }

                            .sub_replies {
                                margin-left: 30px;
                                margin-right: 5px;

                                ul {
                                    display: flex;
                                    flex-direction: column;

                                    li {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .sub_user {
                                            display: flex;
                                            align-items: center;
                                            position: relative;

                                            .img {
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                                background-size: cover;
                                                background-position: center;
                                                background-repeat: no-repeat;
                                                border: 1.5px solid #0245f5;
                                            }

                                            h5 {
                                                margin-left: 15px;
                                            }

                                            .menu-area {
                                                position: relative;

                                                .icon {
                                                    width: 20px;
                                                    cursor: pointer;
                                                    padding: 7px;
                                                    border-radius: 50%;
                                                    margin-top: 7px;
                                                    margin-left: 5px;
                                                    border: 1px solid transparent;

                                                    &:hover {
                                                        border: 1px solid rgba(0, 0, 0, 0.07);
                                                        background-color: #e3e3e3d1;
                                                    }
                                                }

                                                .menu {
                                                    position: absolute;
                                                    right: 10px;
                                                    width: 170px;
                                                    border-radius: 5px;
                                                    opacity: 0;
                                                    transform: translateY(-10px);
                                                    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                                                    z-index: -5;
                                                    background-color: $transparentLightBackgroundColor;
                                                    backdrop-filter: blur(1rem);
                                                    box-shadow: $meunBoxshadow;
                                                    padding-left: 15px;
                                                    padding-top: 5px;

                                                    section {
                                                        flex-direction: column;
                                                    }

                                                    span {
                                                        display: flex;
                                                        align-items: center;
                                                        width: 160px;
                                                        padding: 10px;
                                                        border-radius: 5px;
                                                        margin-right: -20px;
                                                        padding: 7px 0px;

                                                        h6 {
                                                            text-decoration-line: none;
                                                        }

                                                        &:hover {
                                                            background-color: rgb(243, 243, 243);
                                                        }

                                                        cursor: pointer;

                                                        img {
                                                            width: 20px;
                                                        }

                                                        h6 {
                                                            margin-left: 10px;
                                                        }
                                                    }
                                                }

                                                .show-menu {
                                                    z-index: 1;
                                                    opacity: 1;
                                                    transform: translateY(0);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .more {
            font-size: 0.8rem;
            color: #171616;
            cursor: pointer;
            margin-top: 20px;

            &:hover {
                color: #3a3a3a;
                text-decoration-line: underline;
            }
        }

        
        span h6 {
            color: #000;
            font-size: 0.7rem;
            font-weight: 400;
        }
    }
}

@media screen and (max-width: 760px) {
    .home {
        width: 100%;
        justify-content: center;
        margin: 0px;

        .posts {
            width: 100%;
        }
    }
}

.post-view-area {
    width: calc(100% - 250px);
    margin-left: 250px;
    padding-top: 70px;
    padding-bottom: 25px;
    overflow-x: hidden;

    .post-view {
        display: flex;
        justify-content: center;

        .post {
            width: calc(100% - 20px);
            max-width: 660px;
            background-color: #fff;
            border-radius: 7px;
            padding-bottom: 17px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            border: 1px solid #eeeeee;
            background-color: #fff;

            .head {
                display: flex;
                align-items: center;
                padding: 8px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.07);

                img {
                    width: 30px;
                    cursor: pointer;
                    margin-right: 10px;
                }
            }

            .user {
                padding: 10px;
                display: flex;
                align-items: center;
                margin-top: 15px;
                justify-content: space-between;
                align-items: center;

                .img {
                    width: 52px;
                    height: 52px;
                    min-width: 52px;
                    min-height: 52px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 50%;
                    border: 1.5px solid #0245f5;
                }

                section {
                    margin-left: 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    h5 {
                        color: #656565;
                        font-size: 0.9rem;

                        &:hover {
                            text-decoration-line: underline;
                        }
                    }

                    p {
                        color: #7f7f7f;
                        font-size: 0.8rem;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }
                }

                .menu-area {
                    position: relative;

                    .icon {
                        width: 20px;
                        cursor: pointer;
                        padding: 7px;
                        border-radius: 50%;
                        // margin-top: 15px;
                        border: 1px solid transparent;

                        &:hover {
                            border: 1px solid rgba(0, 0, 0, 0.07);
                            background-color: #e3e3e3d1;
                        }
                    }

                    .menu {
                        position: absolute;
                        right: 10px;
                        //    padding: 5px 8px;
                        //    background-color: #e7e7e7;
                        width: 180px;
                        border-radius: 5px;
                        opacity: 0;
                        transform: translateY(-10px);
                        transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                        z-index: -5;
                        background-color: $transparentLightBackgroundColor;
                        backdrop-filter: blur(1rem);
                        box-shadow: $meunBoxshadow;

                        section {
                            flex-direction: column;
                        }

                        span {
                            display: flex;
                            align-items: center;
                            width: 160px;
                            padding: 10px;
                            border-radius: 5px;
                            // margin-top: 5px;
                            margin-left: 0px;

                            &:hover {
                                background-color: rgb(243, 243, 243);
                            }

                            cursor: pointer;

                            img {
                                width: 20px;
                            }

                            h6 {
                                margin-left: 10px;
                            }
                        }
                    }

                    .show-menu {
                        z-index: 1;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 10px;

                .tags {
                    margin-top: 15px;
                    width: calc(100% - 30px);
                    display: flex;
                    flex-wrap: wrap;
                    height: auto;

                    h5 {
                        margin: 10px;
                        padding: 5px 10px;
                        background-color: #eae8e8;
                        width: fit-content;
                        border: 1px solid rgba(0, 0, 0, 0.07);
                        border-radius: 5px;
                        cursor: pointer;
                        min-width: fit-content;
                    }
                }
            }

            .post-image {
                width: calc(100% - 20px);
                margin-left: 10px;
                margin-top: 15px;
                border-radius: 7px;
            }

            .action {
                display: flex;
                align-items: center;
                margin: 15px;
                margin-left: -5px;

                section {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;

                    &:last-child {
                        margin-top: -8px;
                    }

                    img {
                        width: 21px;
                        margin: 10px;
                        cursor: pointer;
                        z-index: 1;
                    }

                    .support {
                        h4 {
                            position: absolute;
                            font-size: 0.7rem;
                            padding: 7px;
                            background-color: rgba(238, 238, 238, 0.733);
                            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
                            backdrop-filter: blur(1.5rem);
                            border-radius: 4px;
                            opacity: 0;
                            margin-left: -10px;
                            margin-top: -5px;
                            z-index: 100;
                        }

                        &:hover {
                            h4 {
                                opacity: 1;
                            }
                        }
                    }

                    span {
                        font-size: 0.8rem;
                        font-weight: 400;
                    }
                }
            }

            .add-comment-area {
                width: calc(100% - 20px);
                // min-width: 100%;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                form {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 8px;

                    img {
                        margin-top: 10px;
                        cursor: pointer;
                        margin-left: 10px;

                        &:hover {
                            filter: brightness(2);
                        }
                    }

                    textarea {
                        width: 100%;
                        padding: 10px;
                        border-radius: 5px;
                        font-family: 'Poppins', sans-serif;
                        outline-color: $blueBackground;
                        margin-left: 5px;
                        border: none;
                        outline: none;
                        // background-color: rgba(0,0,0,0.1);
                        background: transparent;
                        // padding-left: 10px;       
                    }
                }
            }

            .comment-action {
                width: 100%;
                height: fit-content;
                display: flex;
                justify-content: space-between;
                align-items: center;
                transition: all 0.2 ease-in-out;

                .checkbox {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    label {
                        h5 {
                            color: gray;
                            font-size: 0.7rem;
                        }
                    }

                    input {
                        cursor: pointer;
                        margin-left: 10px;
                    }
                }

                button {
                    margin-top: 10px;
                    outline: none;
                    padding: 7px;
                    cursor: pointer;
                    border: 1.5px solid #0c6ddc;
                    background: transparent;
                    border-radius: 5px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #0c6ddc;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        background-color: #0c6ddc;
                        color: #fff;
                    }
                }
            }

            .comments {
                ul {
                    list-style-type: none;
                    margin-left: 25px;

                    li {
                        margin-top: 5px;

                        // border-left: 2px solid #000;
                        section {
                            display: flex;
                            align-items: center;
                            margin-left: -25px;
                            width: fit-content;

                            img {
                                margin-left: 10px;
                                margin-top: 5px;
                            }

                            .avatar {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                background-size: cover;
                                background-position: center;
                                margin-right: 10px;
                                border: 1.5px solid #0245f5;
                            }

                            section {
                                display: flex;

                                h5 {
                                    font-size: 0.83rem;
                                    color: #3f3e3e;
                                }

                                span {
                                    font-size: 0.8rem;
                                    color: #5e5c5c;
                                    font-weight: 400;
                                    margin-left: 5px;
                                }
                            }
                        }

                        .menu-area {
                            position: relative;

                            .icon {
                                width: 20px;
                                cursor: pointer;
                                padding: 7px;
                                border-radius: 50%;
                                // margin-top: 15px;
                                border: 1px solid transparent;

                                &:hover {
                                    border: 1px solid rgba(0, 0, 0, 0.07);
                                    background-color: #e3e3e3d1;
                                }
                            }

                            .menu {
                                right: 0px;
                                // background-color: #e7e7e7;
                                width: 180px;
                                border-radius: 5px;
                                opacity: 0;
                                transform: translateY(0px);
                                transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                                z-index: -5;
                                margin-top: 5px;
                                height: fit-content;
                                position: absolute;
                                padding: 0px;
                                background-color: $transparentLightBackgroundColor;
                                backdrop-filter: blur(1rem);
                                box-shadow: $meunBoxshadow;

                                section {
                                    flex-direction: column;
                                    top: 30px;
                                    margin: 0px;
                                    padding: 0px;
                                }

                                span {
                                    display: flex;
                                    align-items: center;
                                    width: 160px;
                                    // padding: 10px;
                                    border-radius: 5px;
                                    margin: 0px;
                                    height: 20px;

                                    &:hover {
                                        background-color: rgb(243, 243, 243);
                                    }

                                    cursor: pointer;

                                    img {
                                        width: 20px;
                                    }

                                    h6 {
                                        margin-left: 10px;
                                    }
                                }
                            }

                            .show-menu {
                                z-index: 1;
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }

                        .comment {
                            border-left: 1px solid #c4c4c4;
                            margin-left: 0px;
                            padding-left: -15px;

                            p {
                                font-size: 0.8rem;
                                font-weight: 400;
                                margin-top: 10px;
                                max-width: calc(100% - 80px);
                                width: fit-content;
                                color: #3f3e3e;
                                background-color: #f6f6f6;
                                padding: 10px;
                                border-radius: 7px;
                                margin-left: 25px;
                                @extend .whitespace-normal;
                            }

                            button {
                                padding: 2px 10px;
                                background: transparent;
                                border: none;
                                margin-left: 27px;
                                margin-top: 5px;
                                cursor: pointer;
                                color: #4c4c4c;
                            }

                            // .reply {
                            //     margin-top: 8px;
                            //     padding-top: 15px;
                            //     width: 100%;
                            //     display: flex;
                            //     justify-content: center;
                            //     .user-img {
                            //         width: 40px;
                            //         height: 40px;
                            //         min-width: 40px;
                            //         min-height: 40px;
                            //         background-size: cover;
                            //         background-position: center;
                            //         border-radius: 50%;
                            //         margin-left: 45px;
                            //         border: 1.5px solid #0245f5;
                            //     }
                            //     .form {
                            //         width: 100%;
                            //         display: flex;
                            //         flex-direction: column;
                            //         // margin-left: 25px;
                            //         align-items: flex-start;
                            //         // textarea {
                            //         // }
                            //         input {
                            //             width: calc(100% - 50px);
                            //             max-width: 400px;
                            //             padding: 10px;
                            //             outline: none;
                            //             border: 1px solid rgba(0,0,0,0.06);
                            //             background-color: rgba(0,0,0,0.05);
                            //             border-radius: 25px;
                            //             font-family: 'Poppins', sans-serif;
                            //             margin-left: 15px;
                            //             // margin-top: -20px;
                            //             padding-left: 15px;
                            //         }
                            //         button {
                            //             width: 50px;
                            //             padding: 4px 10px;
                            //             text-align: right;
                            //             margin-top: 10px;
                            //             outline: none;
                            //             width: fit-content;
                            //             border: 1px solid rgba(0,0,0,0.07);
                            //             border-radius: 3px;
                            //             width: fit-content;
                            //             background-color: #eae8e8;
                            //             margin-left: 15px;
                            //             cursor: pointer;
                            //             &:hover {
                            //                 background-color: #cecccc;
                            //             }
                            //             h5 {
                            //                 font-family: 'Poppins', sans-serif;
                            //                 // padding: 3px 5;
                            //                 font-size: 0.8rem;
                            //             }
                            //         }
                            //     }
                            // }
                            .reply {
                                margin-top: 5px;
                                padding-top: 5px;
                                width: calc(100% - 30px);
                                margin-left: 35px;

                                form {
                                    width: calc(100% - 30px);
                                    display: flex;
                                    align-items: flex-start;

                                    img {
                                        margin-top: 10px;
                                        cursor: pointer;

                                        &:hover {
                                            filter: brightness(2);
                                        }
                                    }

                                    textarea {
                                        width: 100%;
                                        padding: 10px;
                                        border-radius: 5px;
                                        font-family: 'Poppins', sans-serif;
                                        outline-color: $blueBackground;
                                        margin-left: 10px;
                                        border: none;
                                        outline: none;
                                        background-color: rgba(0, 0, 0, 0.1);
                                    }
                                }

                                form {
                                    .user-img {
                                        width: 35px;
                                        height: 35px;
                                        min-width: 35px;
                                        min-height: 35px;
                                        border-radius: 50%;
                                        border: 2px solid #1b81f5;
                                    }
                                }

                                button {
                                    margin-top: 10px;
                                    outline: none;
                                    padding: 7px;
                                    cursor: pointer;
                                    border: 1.5px solid #0c6ddc;
                                    background: transparent;
                                    border-radius: 5px;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 0.7rem;
                                    font-weight: 400;
                                    color: #0c6ddc;
                                    transition: all 0.3s ease-in-out;
                                    text-decoration: none;
                                    margin-right: 10px;

                                    &:hover {
                                        background-color: #0c6ddc;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
                span h6 {
                    color: #000;
                    font-size: 0.7rem;
                    font-weight: 400;
                }

                .user {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: fit-content;

                    section {
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        padding: 0px;
                        margin: 0px;
                        flex-direction: column;

                        .img {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;

                            h5 {
                                margin-left: 20px;
                            }
                        }
                    }

                    .icon {
                        width: 20px;
                        cursor: pointer;
                        padding: 7px;
                        border-radius: 50%;
                        // margin-top: 15px;
                        border: 1px solid transparent;

                        &:hover {
                            border: 1px solid rgba(0, 0, 0, 0.07);
                            background-color: #e3e3e3d1;
                        }
                    }

                    .menu-area {
                        position: relative;

                        .icon {
                            width: 20px;
                            cursor: pointer;
                            padding: 7px;
                            border-radius: 50%;
                            // margin-top: 15px;
                            border: 1px solid transparent;

                            &:hover {
                                border: 1px solid rgba(0, 0, 0, 0.07);
                                background-color: #e3e3e3d1;
                            }
                        }

                        .menu {
                            position: absolute;
                            right: -40px;
                            //    padding: 5px 8px;
                            //    background-color: #e7e7e7;
                            background-color: $transparentLightBackgroundColor;
                            box-shadow: $meunBoxshadow;
                            backdrop-filter: blur(1rem);
                            width: 180px;
                            border-radius: 5px;
                            opacity: 0;
                            transform: translateY(-10px);
                            transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                            z-index: -5;
                            margin: 0px;

                            section {
                                flex-direction: column;
                            }

                            span {
                                display: flex;
                                align-items: center;
                                width: 160px;
                                padding: 10px;
                                border-radius: 5px;

                                // margin-top: 5px;
                                &:hover {
                                    background-color: rgb(243, 243, 243);
                                }

                                cursor: pointer;

                                img {
                                    width: 20px;
                                }

                                h6 {
                                    margin-left: 10px;
                                }
                            }
                        }

                        .show-menu {
                            z-index: 1;
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }
            }

            .replies {
                ul {
                    list-style-type: none;
                    margin-left: 60px;

                    li {
                        width: fit-content;
                        margin-top: 15px;
                        height: fit-content;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-left: -60px;

                        .line {
                            width: 30px;
                            height: 1px;
                            background-color: #c4c4c4;
                            margin-top: 22px;
                            border-top-left-radius: 5px;
                        }

                        .repl {
                            display: flex;
                            flex-direction: column;
                            margin-top: -25px;

                            .user {
                                width: fit-content;
                                display: flex;
                                align-items: center;

                                .img {
                                    width: 35px;
                                    height: 35px;
                                    min-width: 35px;
                                    min-height: 35px;
                                    border-radius: 50%;
                                    background-size: cover;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    border: 1.5px solid #0245f5;
                                }

                                img {
                                    width: 15px;
                                    margin-left: 15px;
                                    cursor: pointer;
                                    padding: 8px;
                                    border-radius: 50%;

                                    &:hover {
                                        background-color: #f6f6f6;
                                    }
                                }

                                .menu-area {
                                    position: relative;

                                    .icon {
                                        width: 20px;
                                        cursor: pointer;
                                        padding: 7px;
                                        border-radius: 50%;
                                        margin-top: 5px;
                                        margin-left: 0px;
                                        border: 1px solid transparent;

                                        &:hover {
                                            border: 1px solid rgba(0, 0, 0, 0.07);
                                            background-color: #e3e3e3d1;
                                        }
                                    }

                                    .menu {
                                        right: 0px;
                                        background-color: #e7e7e7;
                                        width: 180px;
                                        border-radius: 5px;
                                        opacity: 0;
                                        transform: translateY(0px);
                                        transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                                        z-index: -5;
                                        margin-top: 5px;
                                        height: fit-content;
                                        position: absolute;
                                        padding: 0px;
                                        background-color: $transparentLightBackgroundColor;
                                        backdrop-filter: blur(1rem);
                                        box-shadow: $meunBoxshadow;

                                        section {
                                            flex-direction: column;
                                            top: 30px;
                                        }

                                        span {
                                            display: flex;
                                            align-items: center;
                                            width: 160px;
                                            // padding: 10px;
                                            border-radius: 5px;
                                            margin: 0px;
                                            height: 20px;

                                            &:hover {
                                                background-color: rgb(243, 243, 243);
                                            }

                                            cursor: pointer;

                                            img {
                                                width: 20px;
                                            }

                                            h6 {
                                                margin-left: 10px;
                                            }
                                        }
                                    }

                                    .show-menu {
                                        z-index: 1;
                                        opacity: 1;
                                        transform: translateY(0);
                                    }
                                }
                            }

                            .repl-text {
                                margin-top: -25px;
                                margin-left: 10px;

                                p {
                                    padding: 10px;
                                    background-color: #f6f6f6;
                                    width: fit-content;
                                    max-width: 500px;
                                }

                                h6 {
                                    cursor: pointer;
                                    font-size: 0.7rem;
                                    font-weight: 400;
                                    margin-left: 30px;
                                }

                                span {
                                    margin-left: 30px;
                                }

                                .add_sub_reply {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 20px;
                                    margin-left: 0px;

                                    .img {
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 50%;
                                        background-size: cover;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        border: 1.5px solid #0245f5;
                                    }

                                    input {
                                        width: calc(100% - 50px);
                                        max-width: 400px;
                                        padding: 10px;
                                        outline: none;
                                        border: 1px solid rgba(0, 0, 0, 0.06);
                                        background-color: rgba(0, 0, 0, 0.05);
                                        border-radius: 25px;
                                        font-family: 'Poppins', sans-serif;
                                        margin-left: 15px;
                                        padding-left: 15px;
                                    }
                                }

                                .sub_replies {
                                    margin-left: 30px;
                                    margin-right: 5px;

                                    ul {
                                        display: flex;
                                        flex-direction: column;

                                        li {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-start;

                                            .sub_user {
                                                display: flex;
                                                align-items: center;
                                                position: relative;

                                                .img {
                                                    width: 30px;
                                                    height: 30px;
                                                    border-radius: 50%;
                                                    background-size: cover;
                                                    background-position: center;
                                                    background-repeat: no-repeat;
                                                    border: 1.5px solid #0245f5;
                                                }

                                                h5 {
                                                    margin-left: 15px;
                                                }

                                                // img {
                                                //     width: 20px;
                                                //     margin-left: 5px;
                                                //     padding: 7px;
                                                //     border-radius: 50%;
                                                //     border: 1px solid transparent;
                                                //     cursor: pointer;
                                                //     &:hover {
                                                //         background-color: #f1f1f1;
                                                //         border: 1px solid rgba(0,0,0,0.1);
                                                //     }
                                                // }
                                                .menu-area {
                                                    position: relative;

                                                    .icon {
                                                        width: 20px;
                                                        cursor: pointer;
                                                        padding: 7px;
                                                        border-radius: 50%;
                                                        margin-top: 7px;
                                                        margin-left: 5px;
                                                        border: 1px solid transparent;

                                                        &:hover {
                                                            border: 1px solid rgba(0, 0, 0, 0.07);
                                                            background-color: #e3e3e3d1;
                                                        }
                                                    }

                                                    .menu {
                                                        position: absolute;
                                                        right: 10px;
                                                        //    padding: 5px 8px;
                                                        //    background-color: #e7e7e7;
                                                        width: 170px;
                                                        border-radius: 5px;
                                                        opacity: 0;
                                                        transform: translateY(-10px);
                                                        transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
                                                        z-index: -5;
                                                        background-color: $transparentLightBackgroundColor;
                                                        backdrop-filter: blur(1rem);
                                                        box-shadow: $meunBoxshadow;
                                                        padding-left: 15px;
                                                        padding-top: 5px;

                                                        section {
                                                            flex-direction: column;
                                                        }

                                                        span {
                                                            display: flex;
                                                            align-items: center;
                                                            width: 160px;
                                                            padding: 10px;
                                                            border-radius: 5px;
                                                            margin-right: -20px;

                                                            h6 {
                                                                text-decoration-line: none;
                                                            }

                                                            &:hover {
                                                                background-color: rgb(243, 243, 243);
                                                            }

                                                            cursor: pointer;

                                                            img {
                                                                width: 20px;
                                                            }

                                                            h6 {
                                                                margin-left: 10px;
                                                            }
                                                        }
                                                    }

                                                    .show-menu {
                                                        z-index: 1;
                                                        opacity: 1;
                                                        transform: translateY(0);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
                span h6 {
                    color: #000;
                    font-size: 0.7rem;
                    font-weight: 400;
                }
            }
        }
    }
}

.post-desc {
    p {
        width: calc(100% - 25px);
        font-weight: 400;
        color: #656565;
        font-size: 0.9rem;
        margin: auto;

        button {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            color: #0245f5;
            font-size: 0.9rem;
            background: transparent;
            outline: none;
            border: none;
            margin-left: 7px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 760px) {
    .post-view-area {
        width: 100%;
        justify-content: center;
        margin: auto;
    }
}

.create-post-area {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 800;
    overflow-y: auto;

    .overlay {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        padding-left: 8px;

        .modal {
            width: calc(100% - 20px);
            max-width: 500px;
            margin-top: 10px;
            background-color: rgba(255, 255, 255, 0.9);
            backdrop-filter: blur(2rem);
            border-radius: 6px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            border: 1px solid #bbbbbb;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-left: -7px;
            margin-right: 10px;
            position: static;

            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid rgba(0, 0, 0, 0.06);
                width: 100%;

                .user {
                    display: flex;
                    align-items: center;
                    margin: 7px;

                    .img {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                    }
                }

                img {
                    width: 17px;
                    padding: 10px;
                    background-color: #efefef;
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    transform: rotate(45deg);
                    margin-right: 7px;
                    cursor: pointer;

                    &:hover {
                        background-color: #d1d1d1;
                    }
                }
            }

            .body {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                textarea {
                    width: calc(100% - 50px);
                    outline: none;
                    border: none;
                    background: transparent;
                    font-family: 'Poppins', sans-serif;
                    margin: auto;
                    margin-top: 15px;
                    color: #4c4c4c;
                    height: 100px;

                    &::-webkit-scrollbar {
                        width: 0.6vw;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #a7a7a8;
                        border-radius: 5px;
                    }
                }

                .img-area {
                    margin-top: 10px;

                    .icon {
                        width: 11px;
                        cursor: pointer;
                        padding: 6px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 50%;
                        transform: rotate(45deg);
                        margin-left: 20px;
                    }

                    .img {
                        width: calc(100% - 25px);
                        height: fit-content;
                        max-height: 300px;
                        overflow-y: auto;
                        text-align: center;

                        img {
                            width: calc(100% - 80px);
                            border-radius: 5px;
                        }

                        &::-webkit-scrollbar {
                            width: 0.6vw;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #c8c8c9;
                            border-radius: 5px;
                            // opacity: hi;
                            display: none;

                            &:hover {
                                display: block;
                            }
                        }

                        &:hover {
                            &::-webkit-scrollbar-thumb {
                                background-color: #c8c8c9;
                                border-radius: 5px;
                                display: block;
                            }
                        }

                        @media screen and (max-height: 655px) {
                            max-height: 200px;
                        }

                        @media screen and (max-height: 650px) {
                            max-height: 180px;
                        }
                    }
                }
            }

            .tags {
                display: flex;
                width: calc(100% - 80px);
                max-width: 400px;
                margin: auto;
                margin-top: 25px;
                height: 50px;
                flex-direction: row-reverse;
                justify-content: flex-start;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    height: 0.6vh;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #f7f7fa;
                    border-radius: 5px;
                }

                h5 {
                    padding: 4px 8px;
                    background-color: #fefefe8e;
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 0, 0.135);
                    font-size: 0.8rem;
                    height: 14px;
                    cursor: pointer;
                    min-width: fit-content;

                    img {
                        width: 10px;
                        transform: rotate(45deg);
                        margin-left: 6px;
                    }

                    &:last-child {
                        &:hover {
                            background-color: #cecccc;
                        }

                        img {
                            transform: rotate(0deg);
                        }
                    }
                }
            }

            .anony {
                display: flex;
                align-items: center;
                margin-left: 10px;
                padding: 2px 6px;
                background-color: #e6e5e5;
                border: 1px solid rgba(0, 0, 0, 0.1);
                width: 130px;

                &:hover {
                    background-color: #d5d3d3;
                }

                .cls-anony-text {
                    color: #3a3a3a;
                    margin-left: 10px;
                    font-size: 0.7rem;
                    cursor: pointer;
                    min-width: 150px;
                    font-weight: 400;
                }

                input {
                    cursor: pointer;
                }

                @media screen and (max-width: 355px) {
                    min-width: 120px;
                    margin-left: 0px;
                }
            }

            .bottom {
                width: 100%;
                padding-bottom: 10px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding-top: 15px;

                .post-create-btn {
                    width: 80px;
                    // margin-left: -20px;
                }

                button {
                    outline: none;
                    border: none;
                    background-color: #1b81f5;
                    color: #fff;
                    font-family: 'Poppins', sans-serif;
                    padding: 7px;
                    border-radius: 4px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    height: 30px;

                    &:hover {
                        background-color: #336cfb;
                    }

                    &:nth-child(1) {
                        width: 150px;
                    }

                    &:nth-child(2) {
                        width: 30;
                        // margin-left: 10px;
                        display: flex;
                        align-items: center;

                        img {
                            width: 18px;
                        }
                    }
                }

                label {
                    border-radius: 4px;
                    cursor: pointer;
                    width: 25px;
                    height: 30px;
                    width: 30;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;

                    button {
                        background: transparent;

                        &:hover {
                            background: transparent;
                        }
                    }

                    img {
                        width: 18px;
                        margin: 0px 3px;
                    }
                }

                .tag-people {
                    width: 20px;
                    cursor: pointer;
                }

                @media screen and (max-width: 355px) {
                    label {
                        margin-left: -3px;
                    }
                }
            }
        }
    }
}

.tags-area {
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 600px;
    background-color: rgba(255, 255, 255, 0.894);
    backdrop-filter: blur(2rem);
    border-radius: 6px;
    border: 1px solid #bbbbbb;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 300;
    display: none;
    margin-left: -12px;

    .head {
        width: 100%;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        padding: 10px 0px;
        justify-content: space-between;
        align-items: center;

        h3 {
            margin-left: 15px;
            cursor: pointer;
            font-size: 0.9rem;
        }

        p {
            margin-right: 40px;
            font-size: 0.8rem;
            font-weight: 400;
        }
    }

    .search {
        width: calc(100% - 50px);
        background-color: #dbdbdb;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        margin-top: 15px;

        input {
            width: 100%;
            outline: none;
            border: none;
            background: transparent;
            font-family: 'Poppins', sans-serif;
        }
    }

    span {
        font-weight: 400;
        float: left;
        margin-top: 10px;
    }

    .selected {
        width: calc(100% - 50px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        overflow-y: auto;
        height: 100px;
        overflow-y: auto;

        h5 {
            margin-left: 15px;
            padding: 5px 10px;
            background-color: #eae8e8;
            width: fit-content;
            border: 1px solid rgba(0, 0, 0, 0.07);
            border-radius: 5px;
            cursor: pointer;
            margin: 10px;
            display: flex;
            align-items: center;

            img {
                width: 12px;
                transform: rotate(45deg);
                margin-left: 10px;
            }
        }
    }

    .body {
        width: calc(100% - 50px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        overflow-y: auto;

        h5 {
            margin-left: 15px;
            padding: 5px 10px;
            background-color: #eae8e8;
            width: fit-content;
            border: 1px solid rgba(0, 0, 0, 0.07);
            border-radius: 5px;
            cursor: pointer;
            margin: 10px;
        }
    }
}

.tags-active {
    display: flex;
}

.create-active {
    display: block;
}

.explore-area {
    width: calc(100% - 250px);
    margin-left: 250px;
    display: flex;
    justify-content: space-around;
    padding-top: 70px;
    padding-bottom: 25px;
    margin-top: 20px;

    .explore {
        width: 100%;
        max-width: 800px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 7px;
        padding: 0px 0px 10px 0px;

        .head {
            width: 100%;
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            align-items: center;

            img {
                width: 15px;
                margin: 10px;
                padding: 7px;
                border-radius: 50%;
                border: 1px solid #d5d4d4;
                cursor: pointer;

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }

        .search {
            margin: 10px;
            position: relative;

            input {
                width: calc(100% - 25px);
                max-width: 350px;
                padding: 10px;
                background-color: #f4f4f4;
                outline: none;
                border: 1px solid rgba(0, 0, 0, 0.06);
                border-radius: 5px;
                font-family: 'Poppins', sans-serif;
            }

            .results {
                position: absolute;
                width: calc(100% - 25px);
                max-width: 350px;
                padding: 10px;
                background-color: #fff;
                box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
                transform: translateY(-10px);
                transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
                top: -500px;
                opacity: 0;
                left: 0;

                p {
                    font-weight: 400;
                    font-size: 0.8rem;
                    color: gray;
                    margin-bottom: 15px;
                }

                span {
                    color: #444;
                    font-size: 0.8rem;
                    margin-top: 25px;
                    font-weight: 400;
                }

                ul {
                    list-style-type: none;

                    li {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        section {
                            margin-top: 20px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            width: calc(100% - 16px);
                            padding: 8px;
                            border-radius: 5px;

                            .img {
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                background-position: center;
                                background-size: cover;
                                border: 1.5px solid #0245f5;
                            }

                            h5 {
                                font-size: 0.8rem;
                                margin-left: 15px;
                                color: #4c4c4c;
                            }

                            &:hover {
                                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
                                // transition: all 0.1s ease-in-out;
                                background-color: #f5f5f5;
                            }
                        }
                    }
                }
            }

            .results.active {
                opacity: 1;
                position: absolute;
                // right: 0;
                transform: translateY(0);
                top: 50px;
            }
        }

        .tags {
            width: calc(100% - 50px);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 15px;
            overflow-y: auto;

            h5 {
                margin-left: 15px;
                padding: 5px 10px;
                background-color: #eae8e8;
                width: fit-content;
                border: 1px solid rgba(0, 0, 0, 0.07);
                border-radius: 5px;
                cursor: pointer;
                margin: 10px;

                &:hover {
                    background-color: #d1cfcf;
                }
            }
        }

        .service-links {
            margin-top: 5px;
            margin-left: 10px;

            a {
                text-decoration-line: none;
                color: $bluseTextColor;
            }

            h6 {
                margin-top: 10px;
                font-size: 0.8rem;
            }

            @media screen and (min-width: 761px) {
                display: none;
            }
        }

        h3 {
            margin-top: 15px;
            color: #4c4c4c;
            margin-left: 10px;
        }

        .groups {
            width: calc(100% - 50px);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 15px;
            overflow-y: auto;

            section {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;

                .img {
                    width: 50px;
                    height: 50px;
                    background-size: cover;
                    background-position: center;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    border-radius: 50%;
                    background-repeat: no-repeat;
                }

                h4 {
                    font-size: 0.7rem;
                    margin: 10px;
                    color: #646464;
                }
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .explore-area {
        width: calc(100vw - 20px);
        margin-left: 0px;
        margin: auto;
    }

    .groups-feed-area {
        .groups-feed {
            .group-posts {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

@media screen and (min-width: 760px) {
    .explore-area {
        margin-right: 10px;
    }
}

.search-area {
    width: calc(100% - 250px);
    margin-left: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 25px;

    .head {
        width: calc(100% - 30px);
        max-width: 600px;
        background-color: #fff;
        padding: 5px;
        border-radius: 5px;
        position: sticky;
        top: 70px;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.07);

        input {
            outline: none;
            border: 1px solid rgba(0, 0, 0, 0.04);
            padding: 10px;
            width: calc(100% - 23px);
            font-family: 'Poppins', sans-serif;
            border-radius: 5px;
            margin: auto;

            &:focus {
                border-color: #1b81f5;
            }
        }
    }

    .results-bar {
        position: absolute;
        width: calc(100% - 25px);
        max-width: 350px;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
        transform: translateY(-10px);
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
        top: -500px;
        opacity: 0;
        left: 0;

        p {
            font-weight: 400;
            font-size: 0.8rem;
            color: gray;
            margin-bottom: 15px;
        }

        span {
            color: #444;
            font-size: 0.8rem;
            margin-top: 25px;
            font-weight: 400;
        }

        ul {
            list-style-type: none;

            li {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                section {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    width: calc(100% - 16px);
                    padding: 8px;
                    border-radius: 5px;

                    .img {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                        border: 1.5px solid #0245f5;
                    }

                    h5 {
                        font-size: 0.8rem;
                        margin-left: 15px;
                        color: #4c4c4c;
                    }

                    &:hover {
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
                        // transition: all 0.1s ease-in-out;
                        background-color: #f5f5f5;
                    }
                }
            }
        }
    }

    .results-bar.active {
        opacity: 1;
        position: absolute;
        // right: 0;
        transform: translateY(0);
        top: 50px;
    }

    .results {
        width: calc(100% - 10px);
        display: flex;
        max-width: 600px;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff;
        margin-top: 20px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 7px;

        h5 {
            margin: 10px;
            font-size: 1rem;
            color: #4c4c4c;
        }

        .people {
            border-radius: 5px;
            width: calc(100% - 20px);
            display: flex;
            flex-direction: column;
            padding: 10px;

            section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 28px;

                .user {
                    display: flex;
                    align-items: center;

                    .img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        border: 1.5px solid #0245f5;
                    }

                    .text {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        margin-left: 15px;

                        h3 {
                            font-size: 0.9rem;
                        }

                        span {
                            font-weight: 300;
                            font-size: 0.8rem;
                        }
                    }
                }

                button {
                    outline: none;
                    background-color: #356eff49;
                    color: #0245f5;
                    font-family: 'Poppins', sans-serif;
                    padding: 4px 10px;
                    border-radius: 5px;
                    letter-spacing: 0.5px;
                    cursor: pointer;
                    border: 1px solid #0247f56f;
                }
            }
        }

        h4 {
            font-size: 0.8rem;
            color: #444;
        }
    }

    @media screen and (max-width: 760px) {
        width: 100%;
        margin-left: 0px;

        .results {
            .people {
                section {
                    .user {
                        .text {
                            h3 {
                                width: 150px;
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .side-menu {
        z-index: 1;
        left: -300px;
    }

    .active {
        left: 0;
    }

    .chat-main {
        overflow-x: hidden;

        .chat-area {
            margin-left: 0px;
            width: 100vw;
        }
    }
}

.drawer-active {
    left: 0;
}

.active {
    left: 0;
}

.back-icon {
    display: block;

    @media screen and (min-width: 880px) {
        display: none;
    }
}

.edite-profile {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;

    .overlay {
        height: fit-content;
        width: 100%;
        min-height: calc(100vh + 180px);
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: auto;

        .modal {
            width: calc(100% - 20px);
            max-width: 500px;
            height: fit-content;
            background-color: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(2rem);
            border-radius: 6px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            border: 1px solid #bbbbbb;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;

            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid rgba(0, 0, 0, 0.06);
                width: 100%;

                .head-user {
                    display: flex;
                    align-items: center;
                    margin: 7px;
                    width: fit-content;
                    min-width: 150px;

                    h5 {
                        margin-left: 15px;
                        font-size: 1rem;
                    }

                    .img {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                    }
                }

                img {
                    width: 17px;
                    padding: 10px;
                    background-color: #efefef;
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    transform: rotate(45deg);
                    margin-right: 7px;
                    cursor: pointer;

                    &:hover {
                        background-color: #d1d1d1;
                    }

                    &:nth-child(1) {
                        transform: rotate(0deg);
                    }

                    &:nth-child(2) {
                        margin-right: 15px;
                    }
                }
            }

            .image {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                margin-top: 15px;
                cursor: pointer;
            }

            .description {
                margin-top: 25px;
                display: flex;
                align-items: flex-start;
                width: 100%;
                margin-left: 25px;

                h4 {
                    content: 'About';
                    position: absolute;
                    font-size: 0.8rem;
                    margin-top: -20px;
                }

                textarea {
                    padding: 10px;
                    background: transparent;
                    outline: none;
                    border: none;
                    border-radius: 10px;
                    width: 250px;
                    font-family: 'Poppins', sans-serif;
                    min-height: fit-content;
                    height: 80px;
                }
            }

            .more {
                margin: auto;
                width: calc(100% - 20px);
                margin-left: 25px;

                p {
                    width: 100%;
                    font-size: 0.8rem;
                    font-weight: 400;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    img {
                        width: 20px;
                        margin-right: 10px;
                    }

                    .section {
                        input {
                            font-family: 'Poppins', sans-serif;
                            min-width: 200px;
                            background: transparent;
                            outline: none;
                            border: none;
                            margin-left: 10px;
                        }

                        img {
                            width: 15px;
                            cursor: pointer;
                            margin-left: 10px;
                        }
                    }
                }
            }

            .hobbies {
                margin-top: 15px;
                width: calc(100% - 40px);

                section {
                    display: flex;
                    flex-wrap: wrap;

                    h5 {
                        padding: 5px 10px;
                        background-color: #f0f1f2;
                        margin: 5px;
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                    }
                }

                input {
                    margin-left: 5px;
                    outline: none;
                    border: none;
                    margin-top: 10px;
                    padding: 10px;
                    border-radius: 8px;
                    font-family: 'Poppins', sans-serif;
                    width: 250px;
                    background-color: #efefef;
                    border: 2px solid rgba(0, 0, 0, 0.1);

                    &:focus {
                        border: 2px solid rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }

        // @media screen and (max-height
    }
}

.tags-view-area {
    width: calc(100% - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;

    .head-top {
        background-color: #fff;
        max-width: 600px;
        display: flex;
        width: calc(100% - 15px);
        // max-width: 600px;
        height: fit-content;
        background-color: $transparentLightBackgroundColor;
        box-shadow: $meunBoxshadow;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 3px 10px rgba(0,0,0,0.04);
        border: 1px solid rgba(0, 0, 0, 0.1);
        position: sticky;
        top: 50px;
        backdrop-filter: blur(1rem);
        z-index: 10;
        // @media screen and (min-width: 600px) {
            margin-left: -5px;
        // }

        img {
            width: 25px;
            cursor: pointer;
        }

        h4 {
            margin-left: 15px;
        }
    }
}

.group-side-view {
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: -100vw;

    .overlay {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 300;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;
        transition-delay: 0s;
    }

    .items {
        background-color: #fff;
        height: 100%;
        width: 100%;
        z-index: 400;
        max-width: 250px;
        position: fixed;
        min-height: 100vh;
        overflow: auto;
        border-right: 2px solid #d5d4d4;
        left: -300px;
        transition: all 0.2s ease-in-out;

        ul {
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            height: fit-content;
            align-items: flex-start;

            li {
                display: flex;
                align-items: center;
                padding: 17px;
                width: 100%;
                margin-top: 8px;
                cursor: pointer;

                &:hover {
                    background-color: #efefef;
                }

                .img {
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-left: 10px;
                }

                padding: 10px;

                h4 {
                    margin-left: 15px;
                    font-size: 0.9rem;
                    color: #3f3e3e;
                }
            }
        }
    }
}

.left-0 {
    left: 0;

    .items {
        left: 0;
    }

    .overlay {
        display: block;
    }

    transition: all 0.2s ease-in-out;
}

.mt-25 {
    margin-top: 25px;
}

.friends-area {
    width: 100%;
    height: 100%;

    .overlay {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 400;

        .modal {
            width: 100%;
            max-width: 500px;
            height: fit-content;
            background-color: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(2rem);
            border-radius: 6px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            border: 1px solid #bbbbbb;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;

            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid rgba(0, 0, 0, 0.06);
                width: 100%;

                .head-user {
                    display: flex;
                    align-items: center;
                    margin: 7px;
                    width: fit-content;
                    min-width: 150px;

                    h5 {
                        margin-left: 15px;
                        font-size: 1rem;
                    }

                    .img {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                    }
                }

                img {
                    width: 17px;
                    padding: 10px;
                    background-color: #efefef;
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    transform: rotate(45deg);
                    margin-right: 7px;
                    cursor: pointer;

                    &:hover {
                        background-color: #d1d1d1;
                    }

                    transform: rotate(45deg);
                }
            }

            .find {
                width: calc(100% - 80px);
                margin-top: 15px;

                input {
                    height: 100%;
                    width: calc(100% - 40px);
                    padding: 13px;
                    margin: auto;
                    background-color: #f7f7fa;
                    outline: none;
                    border: 2px solid rgba(0, 0, 0, 0.1);
                    font-family: 'Poppins', sans-serif;
                    border-radius: 10px;

                    &:hover {
                        border: 2px solid rgba(0, 0, 0, 0.3);
                    }

                    &:focus {
                        border: 2px solid rgba(0, 0, 0, 0.3);
                    }
                }

                .body {
                    overflow-y: auto;
                    margin-top: 10px;

                    ul {
                        width: 100%;
                        list-style-type: none;
                        display: flex;
                        flex-wrap: wrap;
                        max-height: 400px;
                        justify-content: center;

                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin: 5px;

                            div {
                                margin: 5px;
                                width: 40px;
                                height: 40px;
                                border-radius: 5px;
                                background-size: cover;
                                background-position: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.update-post-area {
    width: 100%;
    height: 100%;
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;

    .overlay {
        width: 100%;
        height: 100%;
        min-width: 100vw;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;

        .modal {
            width: calc(100% - 25px);
            max-width: 500px;
            height: fit-content;
            background-color: #d1cfcf;

            .head {
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);

                img {
                    width: 20px;
                    padding: 10px;
                    border-radius: 50%;
                    background-color: #e0e4eb;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

.back-btn {
    position: fixed;
    margin-top: (0px + 60px);
    margin-left: 15px;
    // top: ;
    bottom: 10px;
    left: 0;
    z-index: 600;

    img {
        width: 15px;
        background-color: #e6e4e4;
        border-radius: 50%;
        border: 2px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        cursor: pointer;
    }
}

.profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    max-width: 900px;
    padding-bottom: 25px;
    margin: auto;
    overflow-x: hidden;

    .cover-image {
        width: 100%;
        height: 300px;
        max-width: 900px;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
        background-repeat: no-repeat;

        .overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            label {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px;
                cursor: pointer;

                img {
                    width: 15px;
                }
            }
        }
    }

    .user {
        display: flex;
        width: calc(100% - 20px);
        justify-content: space-between;
        align-items: center;

        .sec1 {
            display: flex;
            align-items: flex-end;
            width: fit-content;

            section {
                padding-bottom: 15px;
                margin-left: 15px;

                h3 {
                    font-size: 1.5rem;
                    color: #252525;
                }

                h5 {
                    color: #646464;
                }
            }

            .profile-image {
                width: 150px;
                height: 150px;
                min-width: 150px;
                min-height: 150px;
                border-radius: 50%;
                background-size: cover;
                background-position: center;
                border: 2px solid #3b6eee;
                margin-top: -75px;
                margin-left: 35px;

                .overlay2 {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.5);
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    transition: all 0.2s ease-in-out;

                    &:hover {
                        opacity: 1;
                    }

                    img {
                        width: 20px;
                    }
                }
            }

            @media screen and (max-width: 500px) {
                justify-content: row;
            }
        }

        .btns {
            margin-right: 25px;
            display: flex;
            align-items: center;

            button {
                padding: 7px 10px;
                // background-color: #0245f5;
                outline: none;
                border: 1px solid rgba(182, 182, 182, 0.5);
                font-family: 'Poppins', sans-serif;
                box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
                font-weight: 400;
                // color: #fff;
                border-radius: 7px;
                cursor: pointer;
            }

            img {
                width: 25px;
                cursor: pointer;
                margin-left: 10px;
            }

            @media screen and (max-width: 500px) {
                // width: fit-content;
                // flex-direction: column;
                margin: 0px;
                margin-top: 15px;

                button {
                    margin: 0px;
                }

                img {
                    margin-top: 10px;
                }
            }
        }

        @media screen and (max-width: 650px) {

            // flex-direction: column;
            .sec1 {
                flex-direction: column;
                align-items: center;
                text-align: center;

                .profile-image {
                    margin-left: 0px;
                }
            }
        }
    }

    .user-about {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: auto;
        .user-bio {
            width: calc(100% - 20px);
            max-width: 300px;
            min-width: 220px;
            height: fit-content;
            position: sticky;
            top: 50px;
            margin: 10px;

            h4 {
                color: #3b3a3a;
                margin-top: 15px;
            }

            .bio {
                width: calc(100% - 20px);
                margin-top: 15px;
                min-width: 220px;
                background-color: #fff;
                border-radius: 5px;
                border: 1px solid rgba(236, 196, 196, 0.06);
                padding: 10px;
                height: fit-content;
                .about {
                    width: 100%;
                    margin-top: 10px;
                    margin: auto;

                    p {
                        font-weight: 400;
                        font-size: 0.9rem;
                        margin-bottom: 10px;
                        line-height: 20px;
                        font-family: 'segoeui', sans-serif;
                    }
                }

                .hobbies {
                    max-width: calc(100% - 20px);
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    h5 {
                        padding: 5px 10px;
                        margin-top: 5px;
                        background-color: #e7e7e7;
                        margin: 10px;
                        border-radius: 8px;
                        border: 1px solid rgba(0, 0, 0, 0.06);
                    }
                }

                .more {
                    margin: auto;
                    width: calc(100% - 20px);

                    p {
                        width: 100%;
                        font-size: 0.8rem;
                        font-weight: 400;
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        margin-top: 20px;

                        img {
                            width: 20px;
                            margin-right: 10px;
                        }
                    }
                }

                .friends-head {
                    padding: 5px;

                    h5 {
                        color: #1b81f5;
                        cursor: pointer;
                        margin-top: 15px;
                    }
                }

                .friends {
                    background-color: #f1f1f1;
                    max-height: 250px;
                    margin-top: 15px;
                    overflow-y: auto;
                    border-radius: 5px;

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        padding: 5px;
                        list-style-type: none;

                        li {
                            width: 50px;
                            height: 50px;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            margin: 2px;
                            border-radius: 5px;
                            border: 2px solid #fff;
                        }
                    }
                }
            }

            .activities {
                width: calc(100% - 40px);
                margin-top: 10px;
                min-width: 220px;
                background-color: #fff;
                border-radius: 5px;
                border: 1px solid rgba(0, 0, 0, 0.06);
                padding: 10px;
                height: fit-content;
                max-height: calc(100vh - 370px);
                margin-left: 15px;

                &::-webkit-scrollbar {
                    width: 0.6vw;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    // opacity: hi;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        display: block;
                    }
                }

                // position: sticky;
                // top: 0px;
                // margin-left: 15px;
                h4 {
                    color: #3b3a3a;
                    margin-top: 15px;
                }

                overflow-y: auto;

                ul {
                    list-style-type: none;
                    height: 100%;

                    li {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 17px;

                        .img {
                            width: 40px;
                            height: 40px;
                            min-width: 40px;
                            min-height: 40px;
                            border-radius: 50%;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }

                        p {
                            font-size: 0.8rem;
                            font-weight: 400;
                            margin-left: 8px;
                            cursor: pointer;

                            strong {
                                margin-right: 8px;
                            }

                            &:hover {
                                text-decoration-line: underline;
                            }
                        }
                    }
                }

                @media screen and (max-width: 440px) {
                    max-height: 350px;
                }
            }
        }

        .accept-posts {
            width: calc(100% - 20px);
            max-width: 800px;
            display: flex;
            flex-direction: column;
            margin-top: 25px;
            min-width: 220px;
            border-radius: 5px;
            border: 1px solid rgba(236, 196, 196, 0.06);
            padding: 10px;
            height: fit-content;
            border-radius: 10px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            border: 1px solid #eeeeee;
            background-color: #fff;

            section {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                }

                h3 {
                    font-size: 0.9rem;
                    margin-left: 10px;
                }

                button {
                    margin: 10px;
                    padding: 5px;
                    cursor: pointer;
                    border: 1px solid;
                    border-radius: 5px;

                    &:first-child {
                        margin-left: 4px;
                        border-color: $bluseTextColor;
                    }

                    &:last-child {
                        margin-left: 2px;
                        border-color: $grayBackground;
                    }
                }
            }

            p {
                width: fit-content;
                margin-left: 2px;
                font-size: 0.8rem;
                color: rgba(0, 0, 0, 0.9);
                font-weight: 400;
                margin-top: 10px;
                cursor: pointer;

                &:hover {
                    color: $bluseTextColor;
                    text-decoration-line: underline;
                }
            }
        }

        .user-posts {
            width: 100%;
            display: flex;
            margin-right: -10px;

            h4 {
                color: #3b3a3a;
                margin-top: 60px;
            }

            .post {
                min-width: 500px;

                .support {
                    h4 {
                        top: -15px;
                    }
                }
            }
        }

        @media screen and (max-width: 850px) {
            // width: calc(100% - 20px);
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .user-bio {
                max-width: 500px;
                position: static;
                margin-left: 0px;
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .bio {
                max-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: static;
                margin-left: 0px;

                .about {
                    margin: auto;

                    p {
                        width: calc(100% - 20px);
                    }
                }

                .more {
                    margin: auto;
                    width: calc(100% - 20px);

                    p {
                        width: 100%;
                        font-size: 0.8rem;
                        font-weight: 400;
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        margin-top: 20px;

                        img {
                            width: 20px;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .activities {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: static;
                margin-left: 0px;
                margin-bottom: 20px;
            }

            .profile_user_posts {
                margin-left: 20px;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .user {
            flex-direction: column;

            .sec1 {
                flex-direction: column;
                align-items: center;
            }

            .btns {
                margin-top: 0;
            }
        }
    }
}

.group-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    max-width: 900px;
    padding-bottom: 25px;
    margin: auto;
    padding-top: 55px;

    .cover-image {
        width: 100%;
        height: 300px;
        max-width: 900px;
        background-size: cover;
        background-position: center;
        border-radius: 4px;

        .overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            label {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px;
                cursor: pointer;

                img {
                    width: 25px;
                }
            }

            .sec1 {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                section {
                    padding-bottom: 15px;
                    margin-left: 15px;

                    h3 {
                        font-size: 1.2rem;
                        color: #ffffff;
                        font-weight: 400;
                    }

                    h5 {
                        color: #e8e8e8;
                        font-size: 0.8rem;
                        font-weight: 300;
                    }
                }

                .profile-image {
                    width: 150px;
                    height: 150px;
                    min-width: 150px;
                    min-height: 150px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                    border: 2px solid #3b6eee;
                    margin-left: 20px;

                    .overlay2 {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        background-color: rgba(0, 0, 0, 0.5);
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 0;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            opacity: 1;
                        }

                        img {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

    .second-area {
        width: calc(100% - 20px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
        margin-top: 15px;

        section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
                font-size: 0.8rem;
                font-weight: 400;
                color: #444;
            }

            button {
                padding: 7px 10px;
                background-color: #e93a4b;
                outline: none;
                border: 1px solid rgba(182, 182, 182, 0.5);
                font-family: 'Poppins', sans-serif;
                box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
                font-weight: 400;
                color: #fff;
                border-radius: 7px;
                cursor: pointer;
            }

            &:nth-child(1) {
                margin-left: 15px;
            }

            &:nth-child(2) {
                margin-right: 15px;
            }
        }
    }

    .user {
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .btns {
            margin-right: 25px;
            display: flex;
            align-items: center;

            button {
                padding: 7px 10px;
                background-color: #0245f5;
                outline: none;
                border: 1px solid rgba(182, 182, 182, 0.5);
                font-family: 'Poppins', sans-serif;
                box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
                font-weight: 400;
                color: #fff;
                border-radius: 7px;
                cursor: pointer;
            }

            img {
                width: 25px;
                cursor: pointer;
                margin-left: 10px;
            }

            @media screen and (max-width: 500px) {
                flex-direction: column;
                margin: 0px;

                button {
                    margin: 0px;
                }

                img {
                    margin-top: 10px;
                }
            }
        }

        @media screen and (max-width: 650px) {

            // flex-direction: column;
            .sec1 {
                flex-direction: column;
                align-items: center;
                text-align: center;

                .profile-image {
                    margin-left: 0px;
                }
            }
        }
    }

    .user-about {
        display: flex;
        justify-content: space-around;
        width: calc(100% - 20px);
        max-width: 1000px;

        .bio {
            width: calc(100% - 20px);
            margin-top: 25px;
            max-width: 230px;
            min-width: 220px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.06);
            padding: 10px;
            height: fit-content;
            position: sticky;
            top: 55px;

            h4 {
                color: #3b3a3a;
                margin-top: 15px;
            }

            .about {
                width: 100%;
                margin-top: 10px;
                margin: auto;

                p {
                    font-weight: 400;
                    font-size: 0.9rem;
                    margin-bottom: 10px;
                    line-height: 20px;
                    font-family: 'segoeui', sans-serif;
                }
            }

            .hobbies {
                max-width: calc(100% - 20px);
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;

                h5 {
                    padding: 5px 10px;
                    margin-top: 5px;
                    background-color: #e7e7e7;
                    margin: 10px;
                    border-radius: 8px;
                    border: 1px solid rgba(0, 0, 0, 0.06);
                }
            }

            .more {
                margin: auto;
                width: calc(100% - 20px);

                p {
                    width: 100%;
                    font-size: 0.8rem;
                    font-weight: 400;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    img {
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }

            .friends-head {
                padding: 5px;

                h5 {
                    color: #1b81f5;
                    cursor: pointer;
                    margin-top: 15px;
                }
            }

            .friends {
                background-color: #f1f1f1;
                max-height: 250px;
                margin-top: 15px;
                overflow-y: auto;
                border-radius: 5px;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 5px;
                    list-style-type: none;

                    li {
                        width: 50px;
                        height: 50px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin: 2px;
                        border-radius: 5px;
                        border: 2px solid #fff;
                    }
                }
            }
        }

        @media screen and (max-width: 680px) {
            flex-direction: column;
            align-items: center;
            justify-content: none;

            .bio {
                max-width: 500px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: static;

                .about {
                    margin: auto;

                    p {
                        width: calc(100% - 20px);
                    }
                }

                .more {
                    margin: auto;
                    width: calc(100% - 20px);

                    p {
                        width: 100%;
                        font-size: 0.8rem;
                        font-weight: 400;
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        margin-top: 20px;

                        img {
                            width: 20px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .group-members {
            font-size: 0.8rem;
            color: #1b81f5;
            margin-top: 10px;
            margin-bottom: 10px;
            cursor: pointer;
        }

        .members {

            max-width: calc(100% - 20px);

            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                list-style-type: none;
            }

            li {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                background-size: cover;
                background-position: center;
                margin: 5px;
            }
        }
    }

    .create-top {
        width: calc(100% - 14px);
        max-width: 600px;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
        margin: auto;
        margin-top: 25px;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        background-color: #fff;
        margin-left: -12px;

        .img {
            width: 50px;
            height: 50px;
            min-width: 50px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            border: 2px solid #eeeeee;
        }

        h5 {
            width: 100%;
            max-width: 510px;
            margin-left: 20px;
            padding: 10px 15px;
            background-color: #edebeb;
            border-radius: 25px;
            color: #8c8c8c;
            cursor: pointer;

            &:hover {
                background-color: #e0dede;
            }
        }
    }

    .group-posts-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 570px;
        margin-left: 18px;
    }

    .group-posts {
        width: 100%;
        max-width: 570px;
        display: flex;
        justify-content: center;

        h4 {
            color: #3b3a3a;
            margin-top: 60px;
        }

        .posts {
            margin: 0px;
        }
        .post {
            .support {
                h4 {
                    top: -15px;
                }
            }

            .head {
                width: 100%;

                section {
                    // width: fitcon;
                    display: flex;
                }
            }

            .action {
                width: fit-content;
            }
        }
    }
}

.group-icon {
    @media screen and (min-width: 760px) {
        display: none;
    }
}

.chat-area {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow-y: hidden;

    .chat-main {
        background-color: #fff;
        width: 100%;
        max-width: 1000px;
        height: calc(100vh - 120px);
        padding: 10px 0px 0px 10px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: flex;
        justify-content: space-between;

        .left {
            height: 100%;
            width: 100%;
            max-width: 280px;
            border-right: 2px solid rgba(0, 0, 0, 0.1);
            padding-right: 5px;
            background-color: #fff;

            .top {
                width: 100%;
                padding: 10px 0px;
                padding-top: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .user {
                    display: flex;
                    align-items: center;

                    .img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    section {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-left: 15px;

                        h5 {
                            font-size: 0.9rem;
                        }

                        p {
                            font-size: 0.8rem;
                        }
                    }
                }

                border-bottom: 2px solid rgba(0, 0, 0, 0.1);

                .close-icon {
                    display: none;
                }
            }

            &::-webkit-scrollbar {
                width: 0.4vw;
                padding-right: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                border-radius: 5px;
                opacity: 0;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                    background-color: rgb(217, 217, 217);
                }
            }

            ul {
                list-style-type: none;
                overflow-y: auto;
                height: calc(100% - 65px);

                &::-webkit-scrollbar {
                    width: 0.6vw;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        display: block;
                    }
                }

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 5px;
                    padding: 10px 10px;
                    border-radius: 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: #f3f3f3;
                    }

                    section {
                        display: flex;
                        align-items: center;

                        .img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            border: 2px solid rgba(0, 0, 0, 0.2);
                        }

                        h4 {
                            font-weight: 400;
                            font-size: 0.8rem;
                            margin-left: 15px;
                        }
                    }

                    .icons {
                        display: flex;
                        align-items: center;

                        span {
                            width: 18px;
                            height: 18px;
                            background-color: #336cfb;
                            padding: 5px;
                            border-radius: 50%;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: #fff;
                            text-align: center;
                        }

                        svg {
                            width: 18px;
                            height: fit-content;
                            margin: 0px 7px;
                            fill: $grayTextColor;
                        }
                    }
                }
            }

            .user-chats {
                form {
                    position: relative;

                    input {
                        width: 100%;
                        max-width: 450px;
                        padding: 6px 10px;
                        outline: none;
                        border: 1px solid #999;
                        font-family: 'Poppins', sans-serif;
                        background: transparent;
                        border-radius: 5px;
                        font-size: 0.8rem;
                    }

                    .results {
                        width: 100%;
                        position: absolute;
                        background-color: rgba(255, 255, 255, 0.8);
                        backdrop-filter: blur(2rem);
                        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
                        height: fit-content;
                        max-height: 350px;

                        ul {
                            display: flex;
                            flex-direction: column;
                            height: fit-content;
                            max-height: 350px;
                            overflow-y: auto;

                            li {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                .img {
                                    width: 35px;
                                    height: 35px;
                                    min-width: 35px;
                                    min-height: 35px;
                                    background-size: cover;
                                    background-position: center;
                                    border-radius: 50%;
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .right {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .top {
                width: 100%;
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                height: fit-content;

                .back-icon {
                    width: 25px;
                    cursor: pointer;
                    display: none;
                    margin-right: 15px;
                    margin-left: -10px;
                }

                .user {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    section {
                        margin-left: 15px;
                        width: fit-content;
                        background-color: #fff;
                        display: flex;
                        align-items: center;

                        .img {
                            width: 40px;
                            height: 40px;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            border-radius: 50%;
                            border: 2px solid rgba(0, 0, 0, 0.2);
                        }

                        h5 {
                            margin-left: 15px;
                        }
                    }
                }

                .menu-icon {
                    position: relative;

                    img {
                        padding: 8px;
                        border-radius: 50%;
                        cursor: pointer;
                        margin-top: 4px;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.1);
                        }
                    }

                    .menu {
                        width: 150px;
                        background-color: #fff;
                        opacity: 0;
                        z-index: 200;
                        position: absolute;
                        right: 0px;
                        padding: 0px 10px;
                        border-radius: 5px;
                        // top: 0;
                        right: 10px;
                        transform: translateY(-10px);
                        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
                        top: -500px;
                        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
                        border: 1px solid rgba(0, 0, 0, 0.1);

                        ul {
                            width: 150px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 100%;
                            list-style-type: none;

                            li {
                                width: 150px;
                                cursor: pointer;
                                padding: 10px 10px;
                                font-size: 0.8rem;
                                font-weight: 400;
                                border-radius: 5px;

                                &:hover {
                                    background-color: rgba(0, 0, 0, 0.1);
                                }
                            }
                        }
                    }

                    .menu.active {
                        opacity: 1;
                        position: absolute;
                        right: 0;
                        // transform: translateY(0);
                        top: 50px;
                        left: -120px;
                    }
                }
            }

            .body {
                width: 100%;
                height: 100vh;
                overflow-y: auto;
                padding: 15px 0px;

                &::-webkit-scrollbar {
                    width: 0.6vw;
                }

                scrollbar {
                    width: 0.6vw;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        display: block;
                    }
                }

                .message {
                    width: 100%;
                    height: fit-content;
                    display: flex;

                    section {
                        width: fit-content;
                        max-width: 325px;
                        padding: 10px;
                        display: flex;
                        flex-direction: column;

                        p {
                            font-size: 0.8rem;
                            font-weight: 400;
                            max-width: calc(100% - 16px);
                            width: 100%;
                            padding: 10px;
                            display: flex;
                            flex-direction: column;
                            border: none;
                            @extend .whitespace-normal;

                            span {
                                color: #888888;
                                text-align: right;
                            }
                        }

                        
                        .taged_message {
                            margin-bottom: 3px;
                            // cursor: pointer;
                            p {
                                border-radius: 3px;
                            }
                        }

                    }
                }

                .my_message {
                    width: 100%;
                    justify-content: flex-end;

                    section {
                        justify-content: flex-end;

                        p {
                            background-color: rgba(0, 0, 0, 0.1);
                            border-radius: 15px 15px 0px 15px;
                        }
                    }
                }

                .frined_message {
                    width: 100%;
                    justify-content: flex-start;

                    section {
                        justify-content: flex-start;

                        p {
                            background-color: rgb(0, 123, 255);
                            color: #fff;
                            border-radius: 0px 15px 15px 15px;

                            span {
                                color: #d2d2d2;
                            }
                        }
                    }
                }

                @media screen and (max-width: 480px) {
                    .message {
                        section {
                            max-width: 300px;

                            p {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }

                @media screen and (max-width: 410px) {
                    .message {
                        section {
                            max-width: 280px;

                            p {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }

            .taged_reply {
                width: calc(100% - 20px);
                height: fit-content;
                max-height: 200px;
                background: transparent;

                p {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    svg {
                        margin-bottom: 5px;
                        width: 15px;
                        height: fit-content;
                        cursor: pointer;
                        transform: rotate(45deg);
                        margin-top: -5px;

                    }

                    margin: auto;
                    width: calc(100% - 120px);
                    font-size: 0.8rem;
                    font-weight: 400;
                    padding: 10px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    border-bottom: none;
                }
            }

            .bottom {
                width: 100%;
                padding: 10px 0px 10px 0px;
                background-color: #f3f3f3;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                max-height: 80px;

                img {
                    width: 20px;
                    margin-right: 8px;
                    cursor: pointer;
                }

                input {
                    padding: 7px 10px;
                    margin-left: 8px;
                    background-color: #e1e1e1;
                    outline: none;
                    border: none;
                    width: calc(100% - 120px);
                    height: 25px;
                    font-family: 'Poppins', sans-serif;
                    border-radius: 5px;
                }

                .svg {
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    max-height: 40px;
                    border-radius: 50%;
                    background-color: rgba(2, 71, 245, 0.3);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 15px;
                    margin-right: 15px;

                    img {
                        margin-left: 8px;
                    }
                }
            }
        }

        .without-messages {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            button {
                outline: none;
                background-color: #0245f5;
                border: none;
                padding: 6px 10px;
                cursor: pointer;
                font-family: 'Poppins', sans-serif;
                border-radius: 5px;
                color: #fff;
                margin-top: 10px;
            }
        }

        @media screen and (max-width: 750px) {
            height: calc(100vh - 15px);

            .left {
                position: fixed;
                left: -320px;
                top: 0;
                z-index: 600;
                transition: all 0.2s ease-in-out;

                .top {
                    width: 100%;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .user {
                        display: flex;
                        align-items: center;

                        .img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                        }

                        section {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-left: 15px;

                            h5 {
                                font-size: 0.9rem;
                            }

                            p {
                                font-size: 0.8rem;
                            }
                        }
                    }

                    .close-icon {
                        display: block;
                        width: 20px;
                        transform: rotate(45deg);
                        margin-right: 15px;
                        cursor: pointer;
                    }
                }
            }

            .right {
                .top {
                    position: fixed;
                    top: 0;
                    left: 0;
                    padding-top: 10px;
                    background-color: #fff;

                    .back-icon {
                        display: block;
                    }
                }

                .body {
                    height: calc(100% - 50px);
                    margin-top: 50px;
                }
            }

            .left-active {
                left: 0;
                transition: all 0.2s ease-in-out;
            }

            .chat-bottom-area {
                width: calc(100% + 10px);
                margin-left: -10px;
            }
        }
    }
}

.form-area {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .image {
        width: 50%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #242b81;

        img {
            width: 90%;
            max-width: 550px;
            border-radius: 5px;
            max-height: 100vh;
            box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1);
        }
    }

    form {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        section {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-size: 0.7rem;
                font-weight: 400;
                width: 100%;
                max-width: 300px;
                margin-top: 3px;
                color: #a2a2a2;
            }
        }

        input {
            padding: 10px 15px;
            outline: none;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            width: 100%;
            margin-top: 15px;
            max-width: 300px;
            font-family: 'Poppins', sans-serif;

            &:disabled {
                background-color: #ececec;
            }

            &:focus {
                border-color: #3741C4;
            }
        }

        button {
            outline: none;
            border: none;
            background-color: #3741C4;
            border-radius: 10px;
            width: 100%;
            max-width: 300px;
            padding: 10px 25px;
            font-family: 'Poppins', sans-serif;
            color: #fff;
            margin: auto;
            margin-top: 15px;
            cursor: pointer;
        }

        .bottom {
            display: flex;
            margin-top: 15px;
            width: 100%;
            max-width: 300px;
            justify-content: space-between;

            p {
                font-size: 0.7rem;
                font-weight: 400;
                cursor: pointer;

                &:nth-child(1) {
                    &:hover {
                        text-decoration-line: underline;
                    }
                }

                &:nth-child(2) {
                    color: #3741C4;

                    &:hover {
                        text-decoration-line: underline;
                        text-decoration-color: #3741C4;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 830px) {
        justify-content: center;

        .image {
            display: none;
        }

        form {
            width: 100%;
            max-width: 100vw;

            section {
                width: calc(100% - 50px);
            }

            .bottom {
                width: calc(100% - 30px);
            }
        }
    }
}

.red {
    color: #f02c2c;
}

.mt-7 {
    margin-top: 70px;
}

// .tag_posts {
    // margin: auto;
    // width: calc(100% - 20px);
    // max-width: 600px;
    // display: flex;
    // justify-content: center;
    // margin: 0px;
// }

.center {
    width: 100vw;
    height: calc(100vh - 10vh);
    display: flex;
    justify-content: center;
    align-items: center;

    section {
        display: flex;
        flex-direction: column;
        text-align: center;

        p {
            color: #3b3a3a;
            font-weight: 300;
            font-size: 0.8rem;
        }

        button {
            margin-top: 10px;
            padding: 10px;
            outline: none;
            border: 1px solid #0245f5;
            border-radius: 5px;
            background-color: #1b81f5;
            color: #fff;
            font-family: 'Poppins', sans-serif;
            cursor: pointer;
        }
    }
}

.left-btn {
    margin-top: 15px;
    padding: 5px 10px;
    outline: none;
    // border: 1px solid #f83131;
    // border-radius: 5px;
    // background-color: #f32d37;
    background: transparent;
    border: none;
    // color: #fff;
    color: #f32d37;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;

    &:hover {
        text-decoration-line: underline;
    }
}

.main {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2rem);
}

.chat-friends {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400;

    .overlay {
        width: 100vw;
        height: 100%;
        min-height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 500;
    }

    .modal {
        width: 280px;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        z-index: 800;
        flex-direction: column;
        padding: 10px;
        border-radius: 8px;

        .head {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding-bottom: 7px;

            img {
                width: 15px;
                transform: rotate(45deg);
                padding: 7px;
                background-color: #f5f5f5;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        .body {
            width: 250px;

            ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-height: 500px;
                overflow-y: auto;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 0.6vw;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    // opacity: hi;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        display: block;
                    }
                }

                li {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 10px;
                    width: 100%;
                    cursor: pointer;
                    border-radius: 5px;

                    &:hover {
                        background-color: #f5f5f5;
                    }

                    .img {
                        width: 35px;
                        height: 35px;
                        min-width: 35px;
                        min-height: 35px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                        margin-right: 15px;
                    }

                    h4 {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

.not-found1 {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 15px;
    color: #3a3a3a;
    width: 100%;
}

.not-found2 {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 15px;
    color: #3a3a3a;
    width: 100%;
    display: flex;
    justify-content: center;
}

.groups-feed-area {
    width: 100%;
    height: 100vh;
    margin-top: 50px;

    .groups-feed {
        width: 100%;
        display: flex;
        margin: auto;

        .sidebar-feed {
            width: 100%;
            max-width: 280px;
            background-color: #fff;
            padding: 20px 0px 0px 0px;
            height: calc(100vh - 70px);
            position: sticky;
            top: 50px;
            overflow-y: auto;
            
            &::-webkit-scrollbar {
                width: 0.6vw;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #c8c8c9;
                border-radius: 5px;
                // opacity: hi;
                display: none;

                &:hover {
                    display: block;
                }
            }

            &:hover {
                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    display: block;
                }
            }

            .head {
                width: 100%;
                display: flex;
                // justify-content: center;
                align-items: center;
                padding-bottom: 20px;
                position: sticky;
                top: 0px;
                background-color: #fff;
                height: 60px;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    padding: 10px;
                    border-radius: 50%;
                    fill: #444;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background-color: #f1f1f1;
                    }
                }

                input {
                    width: 190px;
                    padding: 12px 15px;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                    outline: none;
                    border: none;
                    font-family: 'Poppins', sans-serif;
                    font-size: 0.7rem;
                }

                .results {
                    position: absolute;
                    width: calc(100% - 25px);
                    // max-width: 250px;
                    width: 200px;
                    padding: 10px;
                    background-color: #fff;
                    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
                    // transform: translateY(-10px);
                    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
                    top: -1000px;
                    opacity: 0;
                    margin-left: 50px;
                    z-index: -5;
                    @extend .whitespace-normal;
                    // left: 0;

                    
            &::-webkit-scrollbar {
                width: 0.6vw;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #c8c8c9;
                border-radius: 5px;
                // opacity: hi;
                display: none;

                &:hover {
                    display: block;
                }
            }

            &:hover {
                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    display: block;
                }
            }

                    p {
                        font-weight: 400;
                        font-size: 0.8rem;
                        color: gray;
                        margin-bottom: 15px;
                    }

                    span {
                        color: #444;
                        font-size: 0.8rem;
                        margin-top: 25px;
                        font-weight: 400;
                    }

                    ul {
                        list-style-type: none;

                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            section {
                                margin-top: 20px;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                width: calc(100% - 16px);
                                padding: 8px;
                                border-radius: 5px;

                                .img {
                                    width: 35px;
                                    height: 35px;
                                    min-width: 35px;
                                    min-height: 35px;
                                    border-radius: 50%;
                                    background-position: center;
                                    background-size: cover;
                                    border: 1.5px solid #0245f5;
                                }

                                h5 {
                                    font-size: 0.8rem;
                                    margin-left: 15px;
                                    color: #4c4c4c;
                                }

                                &:hover {
                                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
                                    // transition: all 0.1s ease-in-out;
                                    background-color: #f5f5f5;
                                }
                            }
                        }
                    }
                }

                .results-active {
                    opacity: 1;
                    position: absolute;
                    // right: 0;
                    // transform: translateY(0);
                    top: 50px;
                    overflow-y: auto;
                    max-height: 450px;
                }
            }

            .group-list {
                display: flex;
                justify-content: center;
                width: 100%;
                overflow-y: auto;
                height: fit-content;

                &::-webkit-scrollbar {
                    width: 0.6vw;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    // opacity: hi;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        display: block;
                    }
                }

                ul {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    height: fit-content;

                    li {
                        display: flex;
                        // margin-top: 20px;
                        padding: 8px;
                        cursor: pointer;
                        border-radius: 5px;
                        transition: all 0.1s ease-in-out;
                        height: 50px;

                        .img {
                            width: 45px;
                            height: 45px;
                            min-width: 45px;
                            min-height: 45px;
                            border-radius: 10px;
                            border: 1px solid #0245f5;
                            background-size: cover;
                            background-position: center;
                        }

                        section {
                            margin-left: 15px;

                            h5 {
                                font-size: 0.8rem;
                            }

                            p {
                                font-size: 0.7rem;
                                color: gray;
                                font-weight: 400;
                            }
                        }

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.07);
                        }
                    }
                }
            }

            @media screen and (max-width: 760px) {
                max-width: 100%;
            }
        }

        .posts-area {
            width: calc(100% - 250px);
            display: flex;
            justify-content: center;

            .group-posts {
                width: calc(100% - 20px);
                max-width: 600px;
            }
        }
    }
}

.appbar {
    width: 100%;
    height: 50px;
    background-color: #0c6ddc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;

    svg {
        width: 20px;
        margin-left: 15px;
        fill: #fff;
        cursor: pointer;
        height: fit-content;
    }

    .admin-profile-drop {
        position: relative;

        .img {
            width: 36px;
            height: 36px;
            min-width: 36px;
            min-height: 36px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 20px;
            border: 1.5px solid #fff;
            cursor: pointer;
        }

        section {
            width: 230px;
            padding: 10px;
            height: fit-content;
            position: absolute;
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(1rem);
            right: 10px;
            box-shadow: $meunBoxshadow;
            border-radius: 7px;
            margin-top: 10px;
            transform: translateY(-10px);
            transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
            top: -600px;

            .profile-view {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                h5 {
                    margin-top: 7px;
                    font-weight: 400;
                    color: #444;
                }

                p {
                    font-weight: 300;
                    color: #666;
                    font-size: 0.8rem;
                }

                span {
                    font-weight: 400;
                    color: #666;
                    font-size: 0.8rem;
                    cursor: pointer;

                    &:hover {
                        text-decoration-line: underline;
                    }
                }

                .pro-img {
                    width: 45px;
                    height: 45px;
                    min-width: 45px;
                    min-height: 45px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    border: 1.5px solid $bluseTextColor;

                    &:hover {
                        filter: brightness(0.8);
                    }
                }
            }
        }

        .active {
            position: absolute;
            left: -200px;
            opacity: 1;
            transform: translateY(0);
            top: 50px;
        }
    }
}

.admin-drawer {
    width: 250px;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(191, 206, 246, 0.65);
    backdrop-filter: blur(1rem);
    position: fixed;
    top: 50px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
    z-index: 100;
    left: -300px;
    transition: all 0.3s ease-in-out;

    ul {
        width: 100%;
        height: 100%;
        margin-left: 7px;

        li {
            width: calc(100% - 35px);
            height: 20px;
            display: flex;
            padding: 10px;
            margin-top: 15px;
            border-radius: 8px;
            font-size: 0.8rem;
            font-weight: 500;
            align-items: center;
            cursor: pointer;
            color: $grayTextColor;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                fill: #3c3c3c;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}

.admin-drawer.active {
    left: 0;
}

.admin-area {
    padding-top: 50px;

    .overview {
        width: 100%;
        padding-bottom: 25px;

        .head {
            max-width: 1300px;
            margin: auto;
            margin-top: 25px;
            // display: grid;
            // grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
            // place-items: center;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;

            section {
                width: 250px;
                height: 200px;
                padding: 10px;
                border-radius: 10px;
                box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1);
                border: 2px solid $blueBackground;
                margin: 15px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    border-color: $bluseTextColor;
                    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
                }

                &:nth-child(1) {
                    h4 {
                        font-size: 1.1rem;
                        color: #555;
                    }

                    ul {
                        list-style-type: none;

                        li {
                            display: flex;
                            padding: 10px;
                            border-radius: 20px;
                            box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.05);
                            margin-top: 10px;

                            h5 {
                                color: #777;
                            }

                            p {
                                margin-left: 10px;
                                font-weight: 400;
                                font-size: 0.9rem;
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    .no-admins {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        h3 {
                            color: #888;
                        }
                    }

                    h4 {
                        font-size: 1.1rem;
                        color: #555;
                    }

                    ul {
                        list-style-type: none;
                        overflow-y: auto;
                        height: 180px;

                        &::-webkit-scrollbar {
                            width: 0.6vw;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #c8c8c9;
                            border-radius: 5px;
                            // opacity: hi;
                            display: none;

                            &:hover {
                                display: block;
                            }
                        }

                        &:hover {
                            &::-webkit-scrollbar-thumb {
                                background-color: #c8c8c9;
                                border-radius: 5px;
                                display: block;
                            }
                        }

                        li {
                            display: flex;
                            padding: 5px;
                            margin-top: 5px;

                            h5 {
                                color: #777;
                            }

                            p {
                                margin-left: 10px;
                                font-weight: 400;
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }

        .reports {
            width: calc(100% - 20px);
            max-width: 1000px;
            margin: auto;
            margin-top: 25px;

            .top {
                width: calc(100% - 20px);
                height: 30px;
                padding: 7px 10px;
                background-color: #0245f5;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                h4 {
                    color: #fff;
                    font-weight: 500;
                }
            }
            
            .search {
                width: calc(100% - 2px);
                display: flex;
                justify-content: center;
                background-color: #f1f1f1;
                border-left: 1px solid rgba(0,0,0,0.1);
                border-right: 1px solid rgba(0,0,0,0.1);
                input {
                    width: calc(100% - 30px);
                    padding: 10px;
                    background: transparent;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    font-family: 'Poppins', sans-serif;
                    outline: none;
                    border-radius: 8px;
                    margin-top: 10px;
                }
            }

            ul {
                list-style-type: none;
                padding: 10px;
                background-color: #f1f1f1;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-top: none;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding-bottom: 5px;
                    margin-top: 15px;

                    h5 {
                        color: #444;
                        font-weight: 500;
                    }

                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                    }

                    .btns {
                        display: flex;
                        align-items: center;

                        button {
                            padding: 4px 10px;
                            outline: none;
                            border-radius: 4px;
                            border: none;
                            font-family: 'Poppins', sans-serif;
                            background: transparent;
                            cursor: pointer;

                            svg {
                                width: 20px;
                                height: 25px;
                                padding: 7px;
                            }
                        }
                    }
                }
            }
        }
    }

    .admin-create {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 25px;

        form {
            margin-top: 35px;
            width: 100%;
            max-width: 800px;
            background-color: #f1f1f1;

            .top {
                width: calc(100% - 20px);
                height: 30px;
                background-color: #0245f5;
                border-top-left-radius: 7px;
                border-top-right-radius: 7px;
                padding: 10px;

                h4 {
                    color: #fff;
                    font-weight: 500;

                }
            }

            .users {
                width: calc(100% - 22px);
                padding: 10px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;

                .search {
                    width: 100%;

                    input {
                        width: calc(100% - 30px);
                        padding: 10px;
                        background: transparent;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        font-family: 'Poppins', sans-serif;
                        outline: none;
                        border-radius: 8px;
                    }
                }

                ul {
                    list-style-type: none;
                    border-bottom-left-radius: 7px;
                    border-bottom-right-radius: 7px;

                    li {
                        width: calc(100% - 20px);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 25px;
                        border-radius: 7px;
                        padding: 10px;
                        cursor: default;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                        }

                        section {
                            display: flex;
                            margin-right: 10px;
                            align-items: center;

                            .img {
                                width: 40px;
                                height: 40px;
                                min-width: 40px;
                                min-height: 40px;
                                border-radius: 50%;
                                background-size: cover;
                                background-position: center;
                                border: 1px solid #0245f5;
                            }

                            h5 {
                                margin-left: 10px;
                            }
                        }

                        svg {
                            width: 20px;
                            height: fit-content;
                            fill: #0245f5;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .admin-created {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 25px;

        form {
            margin-top: 35px;
            width: 100%;
            max-width: 800px;
            background-color: #f1f1f1;

            .top {
                width: calc(100% - 20px);
                height: 30px;
                background-color: #0245f5;
                border-top-left-radius: 7px;
                border-top-right-radius: 7px;
                padding: 10px;

                h4 {
                    color: #fff;
                    font-weight: 500;

                }
            }

            .users {
                width: calc(100% - 22px);
                padding: 10px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;

                .search {
                    width: 100%;

                    input {
                        width: calc(100% - 30px);
                        padding: 10px;
                        background: transparent;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        font-family: 'Poppins', sans-serif;
                        outline: none;
                        border-radius: 8px;
                    }
                }

                ul {
                    list-style-type: none;
                    border-bottom-left-radius: 7px;
                    border-bottom-right-radius: 7px;

                    li {
                        width: calc(100% - 20px);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 25px;
                        border-radius: 7px;
                        padding: 10px;
                        cursor: default;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                        }

                        section {
                            display: flex;
                            margin-right: 10px;
                            align-items: center;

                            .img {
                                width: 40px;
                                height: 40px;
                                min-width: 40px;
                                min-height: 40px;
                                border-radius: 50%;
                                background-size: cover;
                                background-position: center;
                                border: 1px solid #0245f5;
                            }

                            h5 {
                                margin-left: 10px;
                            }
                        }

                        svg {
                            width: 20px;
                            height: fit-content;
                            fill: #ec4040;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .admin-groups {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 25px;

        form {
            margin-top: 35px;
            width: 100%;
            max-width: 800px;
            background-color: #f1f1f1;

            .top {
                width: calc(100% - 20px);
                height: 30px;
                background-color: #0245f5;
                border-top-left-radius: 7px;
                border-top-right-radius: 7px;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h4 {
                    color: #fff;
                    font-weight: 500;
                }

                svg {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    padding: 10px;
                    border-radius: 50%;
                    fill: '#fff';
                    background-color: rgba(0, 0, 0, 0.07);

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }
            }

            .users {
                width: calc(100% - 22px);
                padding: 10px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;

                .search {
                    width: 100%;

                    input {
                        width: calc(100% - 30px);
                        padding: 10px;
                        background: transparent;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        font-family: 'Poppins', sans-serif;
                        outline: none;
                        border-radius: 8px;
                    }
                }

                ul {
                    list-style-type: none;
                    border-bottom-left-radius: 7px;
                    border-bottom-right-radius: 7px;
                    height: 100%;
                    max-height: 800px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 0.6vw;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        // opacity: hi;
                        display: none;

                        &:hover {
                            display: block;
                        }
                    }

                    &:hover {
                        &::-webkit-scrollbar-thumb {
                            background-color: #c8c8c9;
                            border-radius: 5px;
                            display: block;
                        }
                    }

                    li {
                        width: calc(100% - 20px);
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 10px;
                        border-radius: 7px;
                        padding: 10px;
                        cursor: default;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                        }

                        section {
                            display: flex;
                            margin-right: 10px;
                            align-items: center;

                            .img {
                                width: 40px;
                                height: 40px;
                                min-width: 40px;
                                min-height: 40px;
                                border-radius: 10px;
                                background-size: cover;
                                background-position: center;
                                border: 1px solid #0245f5;
                            }

                            h5 {
                                margin-left: 10px;
                            }
                        }

                        svg {
                            width: 20px;
                            height: fit-content;
                            fill: #ec4040;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

    .create-group {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 500;
        display: flex;
        justify-content: center;
        align-items: center;

        .overlay {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
        }

        .modal {
            width: calc(100% - 20px);
            max-width: 500px;
            height: fit-content;
            // max-height: 600px;
            background-color: #fff;
            z-index: 600;
            border-radius: 7px;
            padding: 10px;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

            .head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 7px;
                border-bottom: 1px solid #d6d6d6;

                svg {
                    width: 25px;
                    height: 25px;
                    transform: rotate(45deg);
                    cursor: pointer;
                    padding: 10px;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.07);

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }
            }

            .body {
                padding-top: 15px;
                display: flex;
                flex-direction: column;
                width: 100%;

                section {
                    width: calc(100% - 70px);
                    max-width: 300px;
                    height: 150px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 5px;
                    margin: auto;
                    cursor: pointer;

                    &:hover {
                        filter: brightness(0.8);
                    }
                }

                h5 {
                    cursor: pointer;
                    margin-top: 15px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .el {
                    margin-top: 25px;
                }

                input {
                    padding: 10px;
                    outline: none;
                    border: 2px solid $grayBackground;
                    border-radius: 5px;
                    font-family: 'Poppins', sans-serif;

                    &:focus {
                        border-color: #0245f5;
                    }
                }

                textarea {
                    padding: 10px;
                    outline: none;
                    border: 2px solid $grayBackground;
                    border-radius: 5px;
                    font-family: 'Poppins', sans-serif;

                    &::-webkit-scrollbar {
                        width: 0px;
                    }

                    &:focus {
                        border-color: #0245f5;
                    }
                }

                .add-tags {
                    width: 100%;

                    input {
                        width: calc(100% - 25px);
                    }
                }

                button {
                    margin-top: 25px;
                    padding: 7px 10px;
                    outline: none;
                    border: none;
                    background-color: #1958f7;
                    font-family: 'Poppins', sans-serif;
                    color: #fff;
                    border-radius: 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: $bluseTextColor;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .groups-feed-area {
        .groups-feed {
            .sidebar {
                display: block;
                width: 100%;
                max-width: 100%;

                .head {
                    input {
                        width: calc(100% - 100px);
                        min-width: 200px;
                    }
                }
            }

            .posts-area {
                width: 100%;
                display: none;

                .group-posts {
                    width: 100%;
                    max-width: 100%;

                    .posts {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
}

.people-area {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: inherit;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    display: none;

    .people-modal {
        width: calc(100% - 40px);
        max-width: 500px;
        height: fit-content;
        max-height: 600px;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(2rem);
        position: fixed;
        padding: 10px;
        border-radius: 8px;
        overflow-y: auto;

        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 7px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            svg {
                // width: 20px;
                // height: fit-content;
                // fill: #444;
                // transform: rotate(45deg);
                // padding: 8px;
                // border: 1px solid rgba(0, 0, 0, 0.2);
                // border-radius: 50%;
                // cursor: pointer;

                // &:hover {
                //     background-color: rgba(255, 255, 255, 0.7);
                // }
                width: 17px;
                height: 17px;
                padding: 10px;
                background-color: #efefef;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.04);
                transform: rotate(45deg);
                margin-right: 7px;
                cursor: pointer;

                &:hover {
                    background-color: #d1d1d1;
                }
            }
        }

        .body {
            overflow-y: auto;

            ul {
                height: calc(100% - 40px);
                max-height: 520px;
                list-style-type: none;
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                overflow-y: auto;
                overflow-x: hidden;
                margin-left: -7px;

                &::-webkit-scrollbar {
                    width: 0.6vw;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    // opacity: hi;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(255, 255, 255, 0.4);
                        border-radius: 5px;
                        display: block;
                    }
                }

                li {
                    display: flex;
                    align-items: center;
                    margin-top: 5px;
                    padding: 7px;
                    width: calc(100% - 15px);
                    border-radius: 5px;
                    cursor: pointer;

                    .img {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-position: center;
                        background-size: cover;
                        border: 1.5px solid #0245f5;
                    }

                    h5 {
                        margin-left: 10px;
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.4);
                    }
                }
            }
        }
    }
}

.people-area.active {
    display: flex;
}

.underline {
    &:hover {
        text-decoration-line: underline;
    }
}

.tag_post_people_area {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: auto;
    }

    .modal {
        width: calc(100% - 20px);
        max-width: 500px;
        height: fit-content;
        max-height: 600px;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(2rem);
        z-index: 1001;
        border-radius: 7px;
        overflow: hidden;

        .head {
            width: calc(100% - 20px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            padding: 10px;

            h5 {
                font-size: 1rem;
            }

            svg {
                // width: 15px;
                // height: fit-content;
                // fill: #4c4c4c;
                // transform: rotate(45deg);
                // padding: 5px;
                // border: 1px solid #4c4c4c;
                // border-radius: 50%;
                // cursor: pointer;

                // &:hover {
                //     filter: brightness(1.4);
                // }
                width: 17px;
                height: 17px;
                padding: 10px;
                background-color: #efefef;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.04);
                transform: rotate(45deg);
                margin-right: 7px;
                cursor: pointer;

                &:hover {
                    background-color: #d1d1d1;
                }
            }
        }

        .body {
            width: 100%;
            height: fit-content;
            // max-height: 600px;
            padding: 20px 7px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 0.6vw;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #c8c8c9;
                border-radius: 5px;
                // opacity: hi;
                display: none;
                padding-left: -20px;

                &:hover {
                    display: block;
                }
            }

            &:hover {
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 5px;
                    display: block;
                }
            }

            ul {
                list-style-type: none;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 5px;
                    padding: 7px 10px;
                    width: calc(100% - 30px);
                    border-radius: 5px;
                    margin-left: -8px;

                    .img {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                        border: 1.5px solid #0245f5;
                    }

                    h5 {
                        margin-left: 15px;
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.4);
                    }
                }
            }
        }
    }
}

.popup {
    transition: all 0.4s ease-in-out;
    transform: scale(1.8);
}

.gray {
    background-color: #f1f1f1;
}

.requests-area {
    width: calc(100% - 270px);
    margin-left: 250px;
    display: flex;
    // justify-content: flex-start;
    align-items: flex-start;
    padding-top: 70px;
    padding-bottom: 25px;

    .requests {
        width: 100%;
        h4 {
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.9);
        }
        .users {
            width: 100%;

            ul {
                list-style-type: none;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                gap: 15px;
                place-items: center;

                li {
                    display: flex;
                    // margin-top: 20px;
                    // padding: 10px;
                    flex-direction: column;
                    width: 150px;
                    height: 250px;
                    border: 2px solid rgba(0, 0, 0, 0.4);
                    border-radius: 5px;
                    align-items: center;
                    justify-content: space-between;

                    section {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .img {
                            width: 150px;
                            height: 130px;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            border-radius: 5px;
                            cursor: pointer;
                            margin-bottom: 10px;

                            .overlay {
                                width: 100%;
                                height: 100%;
                                border-radius: 5px;
                                background-color: rgba(0, 0, 0, 0.07);

                                &:hover {
                                    background-color: rgba(0, 0, 0, 0.2);
                                }
                            }
                        }

                        h5 {
                            margin-left: 27px;
                        }
                    }

                    .bottom {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 10px;
                        margin-left: 15px;
                        margin-top: 5px;

                        .btns {
                            width: fit-content;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;

                            button {
                                margin-right: 15px;
                                padding: 5px 12px;
                                font-family: 'Poppins', sans-serif;
                                width: 130px;
                                outline: none;
                                border: none;
                                border-radius: 5px;
                                box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.03);
                                font-size: 0.8rem;
                                cursor: pointer;
                                font-weight: 500;

                                &:first-child {
                                    background-color: $blueBackground;
                                    margin-bottom: 5px;
                                    color: $bluseTextColor;
                                }

                                &:last-child {
                                    background-color: $grayBackground;
                                    color: $grayTextColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 760px) {
        width: calc(100% - 30px);
        margin: auto;
        margin-left: 15px;
    }
}

.no-feed-text {
    padding: 7px;
    border: 1.5px solid $blueBackground;
    color: #fff;
    background-color: $bluseTextColor;
    font-weight: 400;
    border-radius: 20px;
}

.report-user {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
        width: 100%;
        height: 100%;
        min-height: calc(100vh + 200px);
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 500;
    }

    .modal {
        width: 100%;
        max-width: 500px;
        height: fit-content;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(2rem);
        border-radius: 6px;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
        border: 1px solid #bbbbbb;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        z-index: 600;

        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid rgba(0, 0, 0, 0.06);
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;

            .user {
                display: flex;
                align-items: center;
                margin: 7px;

                .img {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    background-position: center;
                    background-size: cover;
                }

                h5 {
                    margin-left: 15px;
                }
            }

            img {
                width: 17px;
                padding: 10px;
                background-color: #efefef;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.04);
                transform: rotate(45deg);
                margin-right: 7px;
                cursor: pointer;

                &:hover {
                    background-color: #d1d1d1;
                }
            }
        }

        .body {
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            textarea {
                width: 100%;
                max-width: 400px;
                height: 100px;
                padding: 10px;
                font-family: 'Poppins', sans-serif;
                border: none;
                outline: none;
                background-color: transparent;

                &::-webkit-scrollbar {
                    width: 0.6vw;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    // opacity: hi;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        display: block;
                    }
                }
            }

            button {
                outline: none;
                border: none;
                font-family: 'Poppins', sans-serif;
                background-color: #0247f5c4;
                padding: 7px 10px;
                width: calc(100% - 35px);
                color: #fff;
                border-radius: 5px;
                cursor: pointer;
                margin-top: 15px;

                &:hover {
                    background-color: #0245f5;
                }
            }
        }
    }
}

.image-popup {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        position: fixed;
        top: 0;
        left: 0;
    }

    .modal {
        width: 100%;
        max-width: 500px;
        z-index: 600;
        max-height: 600px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &::-webkit-scrollbar {
            width: 0.6vw;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #c8c8c9;
            border-radius: 5px;
            // opacity: hi;
            display: none;

            &:hover {
                display: block;
            }
        }

        &:hover {
            &::-webkit-scrollbar-thumb {
                background-color: #c8c8c9;
                border-radius: 5px;
                display: block;
            }
        }

        img {
            // min-width: fit-content;
            max-width: 100%;
            max-height: 400px;
            border-radius: 5px;
        }

        .btns {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            margin-top: 10px;

            button {
                width: 110px;
                padding: 7px 10px;
                outline: none;
                border: none;
                border-radius: 4px;
                font-family: 'Poppins', sans-serif;
                color: #fff;
                cursor: pointer;

                &:first-child {
                    background-color: #0245f5;
                }

                &:last-child {
                    background-color: #f2281e;
                }
            }
        }
    }
}

.btn-primary {
    background-color: $blueBackground;
    color: $bluseTextColor;
}

.btn-gray {
    background-color: $grayBackground;
    color: $grayTextColor;
}

.center-requests {
    width: 100vw;
    height: 100%;
    min-height: calc(100vh - 20vh);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
        width: 100px;
        height: fit-content;
        fill: #888888;
    }

    h5 {
        color: #888888;
    }
}

.center-notify {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
        width: 100px;
        height: fit-content;
        fill: #888888;
    }

    h5 {
        color: #888888;
    }
}

.new-updates-area {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 300;
    top: -300px;

    .updates-icon {
        width: 150px;
        height: 30px;
        background-color: #fff;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        padding: 3px 7px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        cursor: pointer;
        z-index: 800;
        transition: all 0.4s ease-in-out;

        svg {
            width: 20px;
            height: fit-content;
            margin-right: 3px;
            fill: $bluseTextColor
        }

        h5 {
            font-size: 0.8rem;
            margin-left: 3px;
            color: $bluseTextColor;
        }

        &:hover {
            background-color: $bluseTextColor;
            color: #fff;

            svg {
                fill: #fff;
            }

            h5 {
                color: #fff;
            }
        }
    }
}

.new-updates-area.active {
    margin: auto;
    top: 100px;
    transition: all 1s ease-in-out;
}

.underline-hover {
    cursor: pointer;

    &:hover {
        text-decoration-line: underline;
    }
}

@media screen and (max-width: 600px) {
    .hide-md {
        display: none;
    }
}

.hover-light {
    margin-right: 5px;
    margin-left: 5px;

    .image-update {
        border: 1px solid $bluseTextColor;

        &:nth-child(1) {
            margin-right: -5px;
        }

        &:nth-child(3) {
            margin-left: -5px;
        }
    }

    &:hover {
        .image-update {
            border-color: #fff;
        }
    }
}

.admin-tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    li {
        background-color: rgba(255, 255, 255, 0.5);
        width: fit-content;
        border: 1px solid rgba(255, 255, 255, 0.7);
        max-width: fit-content;
        margin-left: 10px;

        &:hover {
            border-color: #fff;
        }
    }
}

.hover-overlay-1 {
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
        opacity: 0;
        color: #fff;
    }

    &:hover {
        filter: brightness(0.7);

        h5 {
            opacity: 1;
        }
    }
}

.update-group-about {
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-top: 15px;

    section {
        textarea {
            width: calc(100% - 20px);
            padding: 10px;
            height: fit-content;
            height: 130px;
            outline: none;
            border: none;
            background: transparent;
            box-shadow: $meunBoxshadow;
            border-radius: 7px;
            border: 2px solid $blueBackground;
            margin-top: 15px;

            &:focus {
                border-color: $bluseTextColor;
            }
        }

        input {
            width: calc(100% - 20px);
            padding: 10px;
            outline: none;
            border: none;
            background: transparent;
            box-shadow: $meunBoxshadow;
            border-radius: 7px;
            border: 2px solid $blueBackground;
            &:focus {
                border-color: $bluseTextColor;
            }
        }

        button {
            padding: 7px 10px;
            outline: none;
            border: none;
            background-color: $bluseTextColor;
            color: #fff;
            font-family: 'Poppins', sans-serif;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 10px;
        }
    }
}

.search-group-view {
    background-color: #f1f1f1;
    width: 100%;
    display: flex;
    justify-content: center;

    input {
        margin-top: 15px;
        width: calc(100% - 30px);
        padding: 10px;
        background: transparent;
        border: 1px solid rgba(0, 0, 0, 0.2);
        font-family: 'Poppins', sans-serif;
        outline: none;
        border-radius: 8px;
    }
}

.admin-user-view-area {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 800;
        position: fixed;
        top: 0;
        left: 0;
    }

    .modal {
        width: calc(100% - 20px);
        height: fit-content;
        max-width: 400px;
        max-height: 500px;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 1000;
        padding: 10px;
        border-radius: 7px;
        backdrop-filter: blur(1rem);

        .head {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            svg {
                width: 20px;
                height: fit-content;
                fill: #555;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .cover-img {
                width: calc(100% - 40px);
                height: 150px;
                background-size: cover;
                background-position: center;
                border-radius: 7px;
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                background-color: #f1f1f1;

                .img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background-size: cover;
                    background-position: center;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    margin: 10px;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            h3 {
                font-size: 0.9rem;
                color: #333;
                margin-top: 10px;
            }

            textarea {
                width: calc(100% - 40px);
                height: 100px;
                background: transparent;
                outline: none;
                border: none;
                background-color: rgba(0, 0, 0, 0.1);
                padding: 10px;
                font-family: 'Poppins', sans-serif;
                margin-top: 10px;

                &::-webkit-scrollbar {
                    width: 0.6vw;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c8c8c9;
                    border-radius: 5px;
                    // opacity: hi;
                    display: none;

                    &:hover {
                        display: block;
                    }
                }

                &:hover {
                    &::-webkit-scrollbar-thumb {
                        background-color: #c8c8c9;
                        border-radius: 5px;
                        display: block;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .hide-lg {
        display: none;
    }
}

.clear-data-permi-area {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 800;
        position: fixed;
        top: 0;
        left: 0;
    }

    .modal {
        width: calc(100% - 20px);
        height: fit-content;
        max-width: 400px;
        max-height: 200px;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 1000;
        padding: 10px;
        border-radius: 7px;
        backdrop-filter: blur(1rem);

        .head {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            svg {
                fill: #555;
                width: 20px;
                height: fit-content;
                transform: rotate(45deg);
                cursor: pointer;

                &:hover {
                    filter: brightness(1.2);
                }
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                width: 50px;
                height: fit-content;
                fill: #111;
                opacity: 0.3;
            }

            p {
                font-size: 0.8rem;
                color: $bluseTextColor;
                font-weight: 400;
                opacity: 0.7;
                margin: 15px 0px;
            }

            button {
                width: calc(100% - 35px);
                padding: 7px 0px;
                outline: none;
                border: none;
                font-family: 'Poppins', sans-serif;
                color: $bluseTextColor;
                background-color: $blueBackground;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    filter: brightness(0.8);
                }
            }
        }
    }
}

.spinner-profile {
    width: 35px;
}

.center3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 25px;
    }
}

.posts-loading {
    width: calc(100% - 25px);
    max-width: 600px;

    .post-loading {
        display: flex;
        flex-direction: column;

        section {
            width: calc(100% - 20px);
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #eeeeee;
            background-color: #fff;

            .head {
                display: flex;
                align-items: center;
            }

            .description {
                margin-top: 15px;
            }

            .img {
                margin-top: 15px;
            }

            .comment {
                margin-top: 15px;
            }
        }
    }
}

.have_to_accept {
    width: calc(100% - 30px);
    margin-left: 10px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    background-color: #fff;

    section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        .user {
            width: fit-content;

            .img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border: 1.5px solid $bluseTextColor;
            }

            h5 {
                margin-left: 10px;
            }
        }

        .btns {
            display: flex;

            button {
                outline: none;
                border: none;
                padding: 7px 10px;
                font-family: 'Poppins', sans-serif;
                border-radius: 5px;
                cursor: pointer;

                &:nth-child(1) {
                    background: transparent;

                    &:hover {
                        text-decoration-line: underline;
                    }
                }

                &:nth-child(2) {
                    background-color: $blueBackground;
                    color: $bluseTextColor;
                }
            }
        }
    }
}

.group-loading-view {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.profile-loading-view {
    .profile-image {
        height: fit-content;
        width: 100%;
        max-width: 900px;
        display: flex;
        position: relative;
    }
}


.form-area-popup {
    width: fit-content;
    height: 100%;

    form {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        section {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-size: 0.7rem;
                font-weight: 400;
                width: 100%;
                max-width: 300px;
                margin-top: 3px;
                color: #a2a2a2;
            }
        }

        input {
            padding: 10px 15px;
            outline: none;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            width: 100%;
            margin-top: 15px;
            max-width: 350px;
            font-family: 'Poppins', sans-serif;

            &:disabled {
                background-color: #ececec;
            }

            &:focus {
                border-color: #3741C4;
            }
        }

        button {
            outline: none;
            border: none;
            background-color: #3741C4;
            border-radius: 10px;
            width: 100%;
            max-width: 300px;
            padding: 10px 25px;
            font-family: 'Poppins', sans-serif;
            color: #fff;
            margin: auto;
            margin-top: 15px;
            cursor: pointer;
        }

        .bottom {
            display: flex;
            margin-top: 15px;
            width: 100%;
            justify-content: space-between;

            p {
                font-size: 0.7rem;
                font-weight: 400;
                cursor: pointer;

                &:nth-child(1) {
                    &:hover {
                        text-decoration-line: underline;
                    }
                }

                &:nth-child(2) {
                    color: #3741C4;

                    &:hover {
                        text-decoration-line: underline;
                        text-decoration-color: #3741C4;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 830px) {
        justify-content: center;

        .image {
            display: none;
        }

        form {
            width: 100%;
        }
    }
}

.add-username-area {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
        width: calc(100% - 20px);
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;

        section {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        input {
            padding: 10px 15px;
            outline: none;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            width: 100%;
            margin-top: 15px;
            max-width: 300px;
            font-family: 'Poppins', sans-serif;

            &:disabled {
                background-color: #ececec;
            }

            &:focus {
                border-color: #3741C4;
            }
        }

        button {
            outline: none;
            border: none;
            background-color: #3741C4;
            border-radius: 10px;
            width: 100%;
            max-width: 300px;
            padding: 10px 25px;
            font-family: 'Poppins', sans-serif;
            color: #fff;
            margin: auto;
            margin-top: 15px;
            cursor: pointer;
        }
    }
}

.spinner-username {
    width: 20px;
}

.not-found-page {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 280px;
    }

    h3 {
        font-size: 1.2rem;
        color: #111;
    }

    p {
        font-size: 0.8rem;
        font-weight: 300;
    }

    button {
        padding: 10px;
        margin-top: 7px;
        outline: none;
        border: 1px solid $bluseTextColor;
        background-color: $bluseTextColor;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        border-radius: 20px;
        cursor: pointer;
    }
}

.share-dialog {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;

    .overlay {
        width: 100%;
        height: 100%;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.3);
        top: 0;
        left: 0;
    }

    .model {
        width: calc(100% - 20px);
        max-width: 400px;
        height: 100px;
        box-shadow: $meunBoxshadow;
        background-color: #fff;
        font-weight: 400;
        border-radius: 5px;
        z-index: 50000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        input {
            color: #000;
            font-size: 0.9rem;
            padding: 8px;
            background-color: #cfcdcc;
            width: 100%;
            max-width: 350px;
            outline: none;
            border: none;
            font-family: 'Poppins', sans-serif;
            border-radius: 5px;
        }

        h6 {
            font-size: 1rem;
            margin-bottom: 15px;
        }
    }
}

.img-crop {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .reactEasyCrop_Container {
        width: 100%;
        height: calc(100% - 55px);
        margin-top: 55px;
    }
}

.upload-img {
    padding: 7px 15px;
    outline: none;
    background-color: $bluseTextColor;
    color: #fff;
    font-weight: 400;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-family: 'Poppins', sans-serif
}

.crop-overlay {
    display: flex;
    justify-content: center;
    position: relative;
}

.editor {
    position: absolute;
    bottom: 0;
    z-index: 10000;

    input {
    position: absolute;
    // bottom: 0;
    background-color: #fff;
        -webkit-appearance: none;
        /* Override default CSS styles */
        appearance: none;
        width: 100%;
        /* Full-width */
        height: 25px;
        /* Specified height */
        background: #d3d3d3;
        /* Grey background */
        outline: none;
        /* Remove outline */
        opacity: 0.7;
        /* Set transparency (for mouse-over effects on hover) */
        -webkit-transition: .2s;
        /* 0.2 seconds transition on hover */
        transition: opacity .2s;
    }

    /* Mouse-over effects */
    &:hover {
        opacity: 1;
        /* Fully shown on mouse-over */
    }

}

.drop-files-area {
    width: 200px;
    height: 200px;
    border-radius: 5px;
    border: 1px dotted #444;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        width: 50px;
        height: fit-content;
        fill: #888;
    }
    strong {
        font-size: 0.8rem;
        color: #444;
        margin: 10px 0px;
    }
    button {
        cursor: pointer;
        background-color: $bluseTextColor;
        color: #fff;
        padding: 7px 10px;
        border-radius: 5px;
        outline: none;
        border: none;
    }
}

.select-aspect {
    margin-right: 15px;
    outline: none;
    padding: 0px;
    width: 120px;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    .option {
        outline: none;
        padding: 10px;
    }
}

.post-img-div {
    span {
        width: 100%;
    }
}

.Toastify__toast-body > div:last-child {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
}

.Toastify__toast-theme--light {
    border-radius: 10px;
}

.Toastify__toast {
    border-radius: inherit;
}

.Toastify__toast {
    border-radius: 10px;
}

.css-d7l1ni-option {
    background: transparent;
}

.profile-select-posts-type {
    width: fit-content;
    max-width: 600px;
    background-color: #fff;
    box-shadow: $meunBoxshadow;
    border-radius: 5px;
    padding: 5px;
    button {
        background: transparent;
        border: none;
        outline: none;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: rgba(0,0,0,0.8);
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
            background-color: rgba(0,0,0,0.1);
        }
        &:last-child {
            margin-left: 15px;
        }
    }
}

textarea::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.angle-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 20px;
        height: 20px;
        fill: rgba(0,0,0,0.7);
    }
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.svg-div-size-20 {
    svg {
        width: 20px;
        height: 20px;
    }
}

.more-post-btn {
    padding: 6px 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    outline: none;
    border: none;
    background-color: $bluseTextColor;
    cursor: pointer;
    svg {
        fill: #fff;
        width: 15px;
        height: 15px;
    }
    p {
        fill: #fff;
        margin-left: 10px;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
}

.to-bottom {
    background: transparent;
    outline: none;
    border: none;
    position: fixed;
    bottom: 65px;
    right: 20px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #1c91ff;
    // border: 1px solid rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        width: 20px;
        height: 20px;
        fill: #fff;
    }
    @media screen and (min-width: 749px) {
        display: none;
    }
}