@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap");
.whitespace-normal, .groups-feed-area .groups-feed .sidebar-feed .head .results, .chat-area .chat-main .right .body .message section p, .post-view-area .post-view .post .comments ul li .comment p {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}

textarea {
  resize: none;
}

@font-face {
  font-family: sourcecode;
  src: url("fonts/SourceCodePro-Regular.ttf");
}
@font-face {
  font-family: segoeui;
  src: url("fonts/Segoe_UI.woff");
}
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  width: 100%;
}

button {
  outline: none;
}

.app {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.line {
  width: calc(100% - 15px);
  height: 2px;
  background-color: #dbdbdb;
  margin: auto;
  border-radius: 3px;
}

.navbar {
  width: 100%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  z-index: 500;
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
}
.navbar .logo {
  width: 130px;
  margin-left: 25px;
}
.navbar .nav_items {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  list-style-type: none;
}
.navbar .nav_items .nav_item {
  cursor: pointer;
  margin-right: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.navbar .nav_items .nav_item .icon {
  padding: 5px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f1f1f1;
  border: 1px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .nav_items .nav_item .icon svg {
  width: 20px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #555;
}
.navbar .nav_items .nav_item .menu {
  position: absolute;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  top: -500px;
  z-index: 0;
}
.navbar .nav_items .nav_item .menu-active {
  top: 0;
  transition: all 0.2s ease-in-out;
}
.navbar .nav_items .nav_item .mobile-nav {
  margin: auto;
  margin-top: 50px;
}
.navbar .nav_items .nav_item .mobile-nav .mobile-nav-list {
  padding: 10px;
  background-color: #fff;
  width: 270px;
  height: 246px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 7px;
}
.navbar .nav_items .nav_item .mobile-nav .mobile-nav-list li {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
}
.navbar .nav_items .nav_item .mobile-nav .mobile-nav-list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar .nav_items .nav_item .mobile-nav .mobile-nav-list li:nth-child(1) {
  margin-top: 0;
}
.navbar .nav_items .nav_item .mobile-nav .mobile-nav-list li svg {
  width: 20px;
  height: 20px;
  fill: rgba(0, 0, 0, 0.5);
}
.navbar .nav_items .nav_item .mobile-nav .mobile-nav-list li p {
  margin-left: 15px;
  font-weight: 400;
}
.navbar .nav_items .nav_item .notifications-area {
  width: 270px;
  margin-right: 120px;
  margin-top: 50px;
}
.navbar .nav_items .nav_item .notifications-area h5 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
  margin-bottom: 10px;
}
.navbar .nav_items .nav_item .notifications-area .notifications-list {
  width: 100%;
  height: 100%;
  max-height: 350px;
  min-height: 300px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 7px;
  padding: 10px;
  list-style-type: none;
  overflow-y: auto;
}
.navbar .nav_items .nav_item .notifications-area .notifications-list::-webkit-scrollbar {
  width: 0.6vw;
}
.navbar .nav_items .nav_item .notifications-area .notifications-list::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.navbar .nav_items .nav_item .notifications-area .notifications-list::-webkit-scrollbar-thumb:hover {
  display: block;
}
.navbar .nav_items .nav_item .notifications-area .notifications-list:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.navbar .nav_items .nav_item .notifications-area .notifications-list .notification {
  padding: 7px 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.9);
}
.navbar .nav_items .nav_item .notifications-area .notifications-list .notification:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar .nav_items .nav_item .notifications-area .notifications-list .notification p {
  font-size: 0.8rem;
  font-weight: 400;
}
.navbar .nav_items .nav_item .notifications-area .notifications-list .notification h6 {
  font-size: 0.7rem;
}
.navbar .nav_items .nav_item .profile-img-navbar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}
.navbar .nav_items .nav_item .profile-dropdown {
  width: 280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 7px;
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 210px;
  margin-top: 50px;
}
.navbar .nav_items .nav_item .profile-dropdown .profile-nav-link {
  width: 92%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.navbar .nav_items .nav_item .profile-dropdown .profile-nav-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar .nav_items .nav_item .profile-dropdown .footer {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  margin-top: 7px;
}
.navbar .nav_items .nav_item .profile-dropdown .footer li {
  color: #575757;
  font-weight: 500;
  font-size: 0.7rem;
  margin-right: 7px;
  cursor: pointer;
}
.navbar .nav_items .nav_item .profile-dropdown .footer li:hover {
  text-decoration-line: underline;
}
.navbar .nav_items .nav_item .profile-dropdown .copyright {
  font-size: 0.7rem;
  color: #575757;
  font-weight: 400;
  margin-top: 7px;
}
.navbar .nav_items .notify-badge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(242, 71, 71);
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  margin-top: -20px;
  margin-right: -27px;
}
@media screen and (max-width: 600px) {
  .navbar .nav_items .lg {
    display: none;
  }
  .navbar .nav_items .mobile {
    display: flex;
  }
}
@media screen and (max-width: 420px) {
  .navbar .logo {
    margin-left: 10px;
    width: 100px;
  }
  .navbar ul {
    margin-right: 0px;
  }
}

.notifi .notifi-area {
  overflow-y: auto;
  height: 100%;
  max-height: 500px;
  overflow-x: hidden;
}
.notifi .notifi-area::-webkit-scrollbar {
  width: 0.6vw;
}
.notifi .notifi-area::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.notifi .notifi-area::-webkit-scrollbar-thumb:hover {
  display: block;
}
.notifi .notifi-area:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.notifi .notifi-area ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-height: 500px;
}

.notification-icon .notifications {
  position: absolute;
  width: 250px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  background-color: #fff;
  right: 15px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.notification-icon .notifications h4 {
  display: flex;
  align-items: center;
}
.notification-icon .notifications h4 img {
  width: 15px;
  background: transparent;
  border: none;
}
.notification-icon .notifications ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.notification-icon .notifications ul li {
  padding: 8px 0px;
  cursor: pointer;
  width: 100%;
  margin: auto;
  border-radius: 5px;
}
.notification-icon .notifications ul li:hover {
  background-color: #fafafa;
}
.notification-icon .notifications ul li span {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 10px;
}

.none {
  display: none;
}

.icons-d {
  display: none;
}

.user-icon-link {
  display: none;
}

@media screen and (max-width: 850px) {
  .icons-d {
    display: block;
  }
  .group-icon {
    display: none;
  }
  .nav-link {
    display: none;
  }
}
.group-icon {
  display: none;
}

@media screen and (max-width: 500px) {
  .create-icon {
    display: none;
  }
}
.sidebar {
  padding-top: 65px;
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 1;
}
.sidebar::-webkit-scrollbar {
  width: 0.6vw;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.sidebar::-webkit-scrollbar-thumb:hover {
  display: block;
}
.sidebar .nav-profile-image {
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  margin-top: 25px;
  display: flex;
  align-items: center;
  z-index: -2551;
  position: static;
}
.sidebar .nav-profile-image .img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 2px solid #1b81f5;
}
.sidebar .nav-profile-image h3 {
  font-size: 0.8rem;
  margin-left: 10px;
  width: 150px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #7f7f7f;
}
.sidebar .groups {
  width: 100%;
  margin-top: 25px;
}
.sidebar .groups h5 {
  color: #999696;
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 1rem;
  cursor: pointer;
}
.sidebar .groups h5:hover {
  text-decoration-line: underline;
}
.sidebar .groups ul {
  width: 220px;
  background-color: #fff;
  margin: auto;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  padding-top: 0px;
}
.sidebar .groups ul li {
  display: flex;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.sidebar .groups ul li:hover {
  background-color: #f0f1f2;
}
.sidebar .groups ul li .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-left: 15px;
}
.sidebar .groups ul li h6 {
  color: #646464;
  margin-left: 15px;
}
.sidebar .line {
  display: none;
}
@media screen and (max-width: 760px) {
  .sidebar {
    display: none;
  }
}

.home {
  width: calc(100% - 250px);
  margin-left: 250px;
  display: flex;
  justify-content: space-around;
  padding-top: 70px;
  padding-bottom: 25px;
}
.home .post-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 18px);
  margin-top: 25px;
}
.home .post-area .create-top {
  width: calc(100% - 10px);
  max-width: 585px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.home .post-area .create-top .img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 2px solid #eeeeee;
}
.home .post-area .create-top h5 {
  width: 100%;
  max-width: 510px;
  margin-left: 20px;
  padding: 10px 15px;
  background-color: #edebeb;
  border-radius: 25px;
  color: #8c8c8c;
  cursor: pointer;
}
.home .post-area .create-top h5:hover {
  background-color: #e0dede;
}
.home .post-area .posts {
  margin: 0;
  margin-left: 16px;
  margin-top: 5px;
}
.home .desc {
  width: 100%;
  max-width: 330px;
  margin: 10px;
  z-index: 1;
  margin-top: 20px;
}
.home .desc .scroll {
  position: sticky;
  top: 60px;
}
.home .desc .scroll section {
  overflow-y: auto;
  margin-bottom: 25px;
}
.home .desc .scroll section .con::-webkit-scrollbar {
  width: 0.6vw;
}
.home .desc .scroll section .con::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.home .desc .scroll section .con::-webkit-scrollbar-thumb:hover {
  display: block;
}
.home .desc .scroll section .con .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  background-color: #fff;
}
.home .desc .scroll section .con .top h3 {
  color: #3b3a3a;
  font-size: 1rem;
  color: #0245f5;
  opacity: 0.8;
}
.home .desc .scroll section .con .top p {
  margin-top: 15px;
  font-size: 0.85rem;
  font-weight: 400;
  color: #4c4c4c;
}
.home .desc .scroll section .con .top button {
  width: 120px;
  padding: 8px 13px;
  border-radius: 5px;
  margin-top: 15px;
  border: 2px solid #0245f5;
  color: #0245f5;
  font-family: "Poppins", sans-serif;
  background: transparent;
  cursor: pointer;
  transition: linear 0.1s;
}
.home .desc .scroll section .con .top button:hover {
  background-color: #0245f5;
  color: #fff;
}
.home .desc .scroll section .con .top .line {
  margin-top: 15px;
}
.home .desc .scroll section .con .bottom {
  max-height: calc(100vh - 444px);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  overflow-y: auto;
  padding-left: 0px;
}
.home .desc .scroll section .con .bottom h3 {
  color: #3b3a3a;
  font-size: 1rem;
  color: #0245f5;
  opacity: 0.8;
}
.home .desc .scroll section .con .bottom::-webkit-scrollbar {
  width: 0.6vw;
}
.home .desc .scroll section .con .bottom::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.home .desc .scroll section .con .bottom::-webkit-scrollbar-thumb:hover {
  display: block;
}
.home .desc .scroll section .con .bottom ul {
  border-radius: 10px;
  height: 100%;
  list-style-type: none;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  padding-top: 0px;
  padding-bottom: 12px;
}
.home .desc .scroll section .con .bottom ul li {
  display: flex;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.home .desc .scroll section .con .bottom ul li:hover {
  background-color: #f0f1f2;
}
.home .desc .scroll section .con .bottom ul li .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 1.5px solid #1b81f5;
}
.home .desc .scroll section .con .bottom ul li h6 {
  color: #646464;
  margin-left: 15px;
}
@media screen and (max-width: 1100px) {
  .home .desc {
    display: none;
  }
}

.posts {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-width: 600px;
}
.posts .post {
  width: calc(100% - 15px);
  max-width: 700px;
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeee;
  background-color: #fff;
  margin-left: -12px;
}
.posts .post .head {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.posts .post .head section {
  display: flex;
  align-items: center;
}
.posts .post .head section .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  cursor: pointer;
}
.posts .post .head section .text h3 {
  font-size: 0.9rem;
  color: #252525;
}
.posts .post .head section .text h3:hover {
  text-decoration-line: underline;
}
.posts .post .head section .text p {
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #979797;
}
.posts .post .head section .img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #1b81f5;
  cursor: pointer;
}
.posts .post .head section .img section {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.posts .post .head .menu-area {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.posts .post .head .menu-area label {
  width: -moz-fit-content;
  width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  margin: 10px;
  margin-right: 50px;
  padding: 7px;
  background-color: rgb(238, 238, 238);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
  border-radius: 4px;
  margin-top: 40px;
}
.posts .post .head .menu-area label h5 {
  width: 100px;
  font-size: 0.8rem;
  text-align: center;
}
.posts .post .head .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
  z-index: 1;
}
.posts .post .head .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.posts .post .head .menu-area .menu {
  right: 10px;
  width: 180px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  margin-top: 0px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
}
.posts .post .head .menu-area .menu section {
  flex-direction: column;
  top: 50px;
}
.posts .post .head .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.posts .post .head .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.posts .post .head .menu-area .menu span img {
  width: 20px;
}
.posts .post .head .menu-area .menu span h6 {
  margin-left: 10px;
}
.posts .post .head .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
  margin-top: 30px;
}
.posts .post .head svg {
  width: 20px;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
  z-index: 1;
}
.posts .post .head svg:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.posts .post .content {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
}
.posts .post .content p {
  font-weight: 400;
  width: calc(100% - 15px);
  font-size: 0.8rem;
  margin: auto;
}
.posts .post .content .tags {
  margin-top: 15px;
  width: calc(100% - 30px);
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.posts .post .content .tags h5 {
  margin: 10px;
  padding: 5px 10px;
  background-color: #eae8e8;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  cursor: pointer;
}
.posts .post .post-img {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 15px;
  border-radius: 10px;
  cursor: pointer;
}
.posts .post .action {
  display: flex;
  align-items: center;
  margin: 15px;
  margin-left: -5px;
}
.posts .post .action section {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.posts .post .action section:last-child img {
  margin-top: 2px;
}
.posts .post .action section img {
  width: 20px;
  margin: 10px;
  cursor: pointer;
  z-index: 1;
}
.posts .post .action section .support {
  position: relative;
  height: 35px;
}
.posts .post .action section .support h4 {
  position: absolute;
  font-size: 0.7rem;
  padding: 7px;
  background-color: rgba(238, 238, 238, 0.733);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
  border-radius: 4px;
  opacity: 0;
  margin-left: -10px;
  margin-top: -5px;
  z-index: 100;
}
.posts .post .action section .support:hover {
  background-position: right;
  animation: animatesupport 0.8s steps(28) 1;
}
@keyframes animatesupport {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
.posts .post .action section .support:hover h4 {
  opacity: 1;
}
.posts .post .action section img:hover {
  background-position: right;
  animation: animatesupport 0.8s steps(28) 1;
}
@keyframes animatesupport {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
.posts .post .action section span {
  font-size: 0.8rem;
  font-weight: 400;
}
.posts .post .add-comment {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.posts .post .add-comment img {
  margin-top: 10px;
  cursor: pointer;
  margin-left: 10px;
}
.posts .post .add-comment img:hover {
  filter: brightness(2);
}
.posts .post .add-comment textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  outline-color: #87a8fb;
  margin-left: 5px;
  border: none;
  outline: none;
  background: transparent;
}
.posts .post .comment-action {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2 ease-in-out;
}
.posts .post .comment-action .checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.posts .post .comment-action .checkbox label h5 {
  color: gray;
  font-size: 0.7rem;
}
.posts .post .comment-action .checkbox input {
  cursor: pointer;
  margin-left: 10px;
}
.posts .post .comment-action button {
  margin-top: 10px;
  outline: none;
  padding: 7px;
  cursor: pointer;
  border: 1.5px solid #0c6ddc;
  background: transparent;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  color: #0c6ddc;
  transition: all 0.3s ease-in-out;
}
.posts .post .comment-action button:hover {
  background-color: #0c6ddc;
  color: #fff;
}
.posts .post .comments {
  margin-top: 15px;
  width: -moz-fit-content;
  width: fit-content;
}
.posts .post .comments .user-com {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: -moz-fit-content;
  height: fit-content;
}
.posts .post .comments .user-com section {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
  flex-direction: column;
}
.posts .post .comments .user-com section .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.posts .post .comments .user-com section .img h5 {
  margin-left: 20px;
}
.posts .post .comments .user-com .icon {
  width: 20px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 5px;
  border: 1px solid transparent;
}
.posts .post .comments .user-com .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.posts .post .comments .user-com .menu-area {
  position: relative;
}
.posts .post .comments .user-com .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.posts .post .comments .user-com .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.posts .post .comments .user-com .menu-area .menu {
  right: -50px;
  background-color: rgb(255, 255, 255);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: 1rem;
          backdrop-filter: 1rem;
  width: 180px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  margin-top: 5px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  margin-left: 25px;
}
.posts .post .comments .user-com .menu-area .menu section {
  flex-direction: column;
  top: 50px;
}
.posts .post .comments .user-com .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.posts .post .comments .user-com .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.posts .post .comments .user-com .menu-area .menu span img {
  width: 20px;
}
.posts .post .comments .user-com .menu-area .menu span h6 {
  margin-left: 10px;
}
.posts .post .comments .user-com .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}
.posts .post .comments .comment {
  margin-top: 20px;
  border-left: 1px solid #c4c4c4;
  margin-left: 15px;
  margin-top: -8px;
  max-width: 450px;
  width: calc(100% - 50px);
}
.posts .post .comments .comment p {
  padding: 10px;
  background-color: #f6f6f6;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.8rem;
  border-radius: 5px;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
}
.posts .post .comments .comment button {
  border: none;
  background: transparent;
  outline: none;
  margin-left: 35px;
  cursor: pointer;
  color: #4c4c4c;
}
.posts .post .comments .comment button:hover {
  text-decoration-line: underline;
}
.posts .post .comments .comment .reply {
  margin-top: 5px;
  padding-top: 5px;
  width: 100%;
  margin-left: 35px;
}
.posts .post .comments .comment .reply form {
  background: transparent;
}
.posts .post .comments .comment .reply form textarea {
  background-color: rgba(0, 0, 0, 0.1);
}
.posts .post .comments .comment .reply form .user-img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  border: 2px solid #1b81f5;
}
.posts .post .comments .comment .reply button {
  margin-top: 10px;
  outline: none;
  padding: 7px;
  cursor: pointer;
  border: 1.5px solid #0c6ddc;
  background: transparent;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  color: #0c6ddc;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.posts .post .comments .comment .reply button:hover {
  background-color: #0c6ddc;
  color: #fff;
}
.posts .post .replies ul {
  list-style-type: none;
  margin-left: 60px;
}
.posts .post .replies ul li {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 15px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -60px;
}
.posts .post .replies ul li .line {
  width: 30px;
  height: 1px;
  background-color: #c4c4c4;
  margin-top: 22px;
  border-top-left-radius: 5px;
}
.posts .post .replies ul li .repl {
  display: flex;
  flex-direction: column;
}
.posts .post .replies ul li .repl .user {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
}
.posts .post .replies ul li .repl .user .img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.posts .post .replies ul li .repl .user img {
  width: 15px;
  margin-left: 15px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
}
.posts .post .replies ul li .repl .user img:hover {
  background-color: #f6f6f6;
}
.posts .post .replies ul li .repl .user .menu-area {
  position: relative;
}
.posts .post .replies ul li .repl .user .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.posts .post .replies ul li .repl .user .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.posts .post .replies ul li .repl .user .menu-area .menu {
  right: 0px;
  background-color: #e7e7e7;
  width: 180px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(0px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  margin-top: 5px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.posts .post .replies ul li .repl .user .menu-area .menu section {
  flex-direction: column;
  top: 30px;
}
.posts .post .replies ul li .repl .user .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  border-radius: 5px;
  margin: 0px;
  height: 20px;
  cursor: pointer;
}
.posts .post .replies ul li .repl .user .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.posts .post .replies ul li .repl .user .menu-area .menu span img {
  width: 20px;
}
.posts .post .replies ul li .repl .user .menu-area .menu span h6 {
  margin-left: 10px;
}
.posts .post .replies ul li .repl .user .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}
.posts .post .replies ul li .repl .repl-text {
  margin-left: 10px;
}
.posts .post .replies ul li .repl .repl-text p {
  padding: 10px;
  background-color: #f6f6f6;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 500px;
}
.posts .post .replies ul li .repl .repl-text h6 {
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 400;
  margin-left: 30px;
}
.posts .post .replies ul li .repl .repl-text h6:hover {
  text-decoration: underline;
}
.posts .post .replies ul li .repl .repl-text span {
  margin-left: 0px;
}
.posts .post .replies ul li .repl .repl-text .add_sub_reply {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 0px;
}
.posts .post .replies ul li .repl .repl-text .add_sub_reply .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.posts .post .replies ul li .repl .repl-text .add_sub_reply input {
  width: calc(100% - 50px);
  max-width: 400px;
  padding: 10px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  font-family: "Poppins", sans-serif;
  margin-left: 15px;
  padding-left: 15px;
}
.posts .post .replies ul li .repl .repl-text .sub_replies {
  margin-left: 30px;
  margin-right: 5px;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul {
  display: flex;
  flex-direction: column;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user {
  display: flex;
  align-items: center;
  position: relative;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user h5 {
  margin-left: 15px;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area {
  position: relative;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 5px;
  border: 1px solid transparent;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu {
  position: absolute;
  right: 10px;
  width: 170px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  background-color: rgb(255, 255, 255);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  padding-top: 5px;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu section {
  flex-direction: column;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  padding: 10px;
  border-radius: 5px;
  margin-right: -20px;
  padding: 7px 0px;
  cursor: pointer;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span h6 {
  text-decoration-line: none;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span img {
  width: 20px;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span h6 {
  margin-left: 10px;
}
.posts .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}
.posts .post .more {
  font-size: 0.8rem;
  color: #171616;
  cursor: pointer;
  margin-top: 20px;
}
.posts .post .more:hover {
  color: #3a3a3a;
  text-decoration-line: underline;
}
.posts .post span h6 {
  color: #000;
  font-size: 0.7rem;
  font-weight: 400;
}

@media screen and (max-width: 760px) {
  .home {
    width: 100%;
    justify-content: center;
    margin: 0px;
  }
  .home .posts {
    width: 100%;
  }
}
.post-view-area {
  width: calc(100% - 250px);
  margin-left: 250px;
  padding-top: 70px;
  padding-bottom: 25px;
  overflow-x: hidden;
}
.post-view-area .post-view {
  display: flex;
  justify-content: center;
}
.post-view-area .post-view .post {
  width: calc(100% - 20px);
  max-width: 660px;
  background-color: #fff;
  border-radius: 7px;
  padding-bottom: 17px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeee;
  background-color: #fff;
}
.post-view-area .post-view .post .head {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.post-view-area .post-view .post .head img {
  width: 30px;
  cursor: pointer;
  margin-right: 10px;
}
.post-view-area .post-view .post .user {
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
}
.post-view-area .post-view .post .user .img {
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 1.5px solid #0245f5;
}
.post-view-area .post-view .post .user section {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.post-view-area .post-view .post .user section h5 {
  color: #656565;
  font-size: 0.9rem;
}
.post-view-area .post-view .post .user section h5:hover {
  text-decoration-line: underline;
}
.post-view-area .post-view .post .user section p {
  color: #7f7f7f;
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.post-view-area .post-view .post .user .menu-area {
  position: relative;
}
.post-view-area .post-view .post .user .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.post-view-area .post-view .post .user .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.post-view-area .post-view .post .user .menu-area .menu {
  position: absolute;
  right: 10px;
  width: 180px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  background-color: rgb(255, 255, 255);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.post-view-area .post-view .post .user .menu-area .menu section {
  flex-direction: column;
}
.post-view-area .post-view .post .user .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  padding: 10px;
  border-radius: 5px;
  margin-left: 0px;
  cursor: pointer;
}
.post-view-area .post-view .post .user .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.post-view-area .post-view .post .user .menu-area .menu span img {
  width: 20px;
}
.post-view-area .post-view .post .user .menu-area .menu span h6 {
  margin-left: 10px;
}
.post-view-area .post-view .post .user .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}
.post-view-area .post-view .post .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}
.post-view-area .post-view .post .content .tags {
  margin-top: 15px;
  width: calc(100% - 30px);
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.post-view-area .post-view .post .content .tags h5 {
  margin: 10px;
  padding: 5px 10px;
  background-color: #eae8e8;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.post-view-area .post-view .post .post-image {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 15px;
  border-radius: 7px;
}
.post-view-area .post-view .post .action {
  display: flex;
  align-items: center;
  margin: 15px;
  margin-left: -5px;
}
.post-view-area .post-view .post .action section {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.post-view-area .post-view .post .action section:last-child {
  margin-top: -8px;
}
.post-view-area .post-view .post .action section img {
  width: 21px;
  margin: 10px;
  cursor: pointer;
  z-index: 1;
}
.post-view-area .post-view .post .action section .support h4 {
  position: absolute;
  font-size: 0.7rem;
  padding: 7px;
  background-color: rgba(238, 238, 238, 0.733);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(1.5rem);
          backdrop-filter: blur(1.5rem);
  border-radius: 4px;
  opacity: 0;
  margin-left: -10px;
  margin-top: -5px;
  z-index: 100;
}
.post-view-area .post-view .post .action section .support:hover h4 {
  opacity: 1;
}
.post-view-area .post-view .post .action section span {
  font-size: 0.8rem;
  font-weight: 400;
}
.post-view-area .post-view .post .add-comment-area {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.post-view-area .post-view .post .add-comment-area form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.post-view-area .post-view .post .add-comment-area form img {
  margin-top: 10px;
  cursor: pointer;
  margin-left: 10px;
}
.post-view-area .post-view .post .add-comment-area form img:hover {
  filter: brightness(2);
}
.post-view-area .post-view .post .add-comment-area form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  outline-color: #87a8fb;
  margin-left: 5px;
  border: none;
  outline: none;
  background: transparent;
}
.post-view-area .post-view .post .comment-action {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2 ease-in-out;
}
.post-view-area .post-view .post .comment-action .checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.post-view-area .post-view .post .comment-action .checkbox label h5 {
  color: gray;
  font-size: 0.7rem;
}
.post-view-area .post-view .post .comment-action .checkbox input {
  cursor: pointer;
  margin-left: 10px;
}
.post-view-area .post-view .post .comment-action button {
  margin-top: 10px;
  outline: none;
  padding: 7px;
  cursor: pointer;
  border: 1.5px solid #0c6ddc;
  background: transparent;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  color: #0c6ddc;
  transition: all 0.3s ease-in-out;
}
.post-view-area .post-view .post .comment-action button:hover {
  background-color: #0c6ddc;
  color: #fff;
}
.post-view-area .post-view .post .comments ul {
  list-style-type: none;
  margin-left: 25px;
}
.post-view-area .post-view .post .comments ul li {
  margin-top: 5px;
}
.post-view-area .post-view .post .comments ul li section {
  display: flex;
  align-items: center;
  margin-left: -25px;
  width: -moz-fit-content;
  width: fit-content;
}
.post-view-area .post-view .post .comments ul li section img {
  margin-left: 10px;
  margin-top: 5px;
}
.post-view-area .post-view .post .comments ul li section .avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 10px;
  border: 1.5px solid #0245f5;
}
.post-view-area .post-view .post .comments ul li section section {
  display: flex;
}
.post-view-area .post-view .post .comments ul li section section h5 {
  font-size: 0.83rem;
  color: #3f3e3e;
}
.post-view-area .post-view .post .comments ul li section section span {
  font-size: 0.8rem;
  color: #5e5c5c;
  font-weight: 400;
  margin-left: 5px;
}
.post-view-area .post-view .post .comments ul li .menu-area {
  position: relative;
}
.post-view-area .post-view .post .comments ul li .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.post-view-area .post-view .post .comments ul li .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.post-view-area .post-view .post .comments ul li .menu-area .menu {
  right: 0px;
  width: 180px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(0px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  margin-top: 5px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.post-view-area .post-view .post .comments ul li .menu-area .menu section {
  flex-direction: column;
  top: 30px;
  margin: 0px;
  padding: 0px;
}
.post-view-area .post-view .post .comments ul li .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  border-radius: 5px;
  margin: 0px;
  height: 20px;
  cursor: pointer;
}
.post-view-area .post-view .post .comments ul li .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.post-view-area .post-view .post .comments ul li .menu-area .menu span img {
  width: 20px;
}
.post-view-area .post-view .post .comments ul li .menu-area .menu span h6 {
  margin-left: 10px;
}
.post-view-area .post-view .post .comments ul li .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}
.post-view-area .post-view .post .comments ul li .comment {
  border-left: 1px solid #c4c4c4;
  margin-left: 0px;
  padding-left: -15px;
}
.post-view-area .post-view .post .comments ul li .comment p {
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 10px;
  max-width: calc(100% - 80px);
  width: -moz-fit-content;
  width: fit-content;
  color: #3f3e3e;
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 7px;
  margin-left: 25px;
}
.post-view-area .post-view .post .comments ul li .comment button {
  padding: 2px 10px;
  background: transparent;
  border: none;
  margin-left: 27px;
  margin-top: 5px;
  cursor: pointer;
  color: #4c4c4c;
}
.post-view-area .post-view .post .comments ul li .comment .reply {
  margin-top: 5px;
  padding-top: 5px;
  width: calc(100% - 30px);
  margin-left: 35px;
}
.post-view-area .post-view .post .comments ul li .comment .reply form {
  width: calc(100% - 30px);
  display: flex;
  align-items: flex-start;
}
.post-view-area .post-view .post .comments ul li .comment .reply form img {
  margin-top: 10px;
  cursor: pointer;
}
.post-view-area .post-view .post .comments ul li .comment .reply form img:hover {
  filter: brightness(2);
}
.post-view-area .post-view .post .comments ul li .comment .reply form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  outline-color: #87a8fb;
  margin-left: 10px;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.post-view-area .post-view .post .comments ul li .comment .reply form .user-img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  border: 2px solid #1b81f5;
}
.post-view-area .post-view .post .comments ul li .comment .reply button {
  margin-top: 10px;
  outline: none;
  padding: 7px;
  cursor: pointer;
  border: 1.5px solid #0c6ddc;
  background: transparent;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  color: #0c6ddc;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  margin-right: 10px;
}
.post-view-area .post-view .post .comments ul li .comment .reply button:hover {
  background-color: #0c6ddc;
  color: #fff;
}
.post-view-area .post-view .post .comments span h6 {
  color: #000;
  font-size: 0.7rem;
  font-weight: 400;
}
.post-view-area .post-view .post .comments .user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: -moz-fit-content;
  height: fit-content;
}
.post-view-area .post-view .post .comments .user section {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
  flex-direction: column;
}
.post-view-area .post-view .post .comments .user section .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.post-view-area .post-view .post .comments .user section .img h5 {
  margin-left: 20px;
}
.post-view-area .post-view .post .comments .user .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.post-view-area .post-view .post .comments .user .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.post-view-area .post-view .post .comments .user .menu-area {
  position: relative;
}
.post-view-area .post-view .post .comments .user .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.post-view-area .post-view .post .comments .user .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.post-view-area .post-view .post .comments .user .menu-area .menu {
  position: absolute;
  right: -40px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  width: 180px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  margin: 0px;
}
.post-view-area .post-view .post .comments .user .menu-area .menu section {
  flex-direction: column;
}
.post-view-area .post-view .post .comments .user .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.post-view-area .post-view .post .comments .user .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.post-view-area .post-view .post .comments .user .menu-area .menu span img {
  width: 20px;
}
.post-view-area .post-view .post .comments .user .menu-area .menu span h6 {
  margin-left: 10px;
}
.post-view-area .post-view .post .comments .user .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}
.post-view-area .post-view .post .replies ul {
  list-style-type: none;
  margin-left: 60px;
}
.post-view-area .post-view .post .replies ul li {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 15px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -60px;
}
.post-view-area .post-view .post .replies ul li .line {
  width: 30px;
  height: 1px;
  background-color: #c4c4c4;
  margin-top: 22px;
  border-top-left-radius: 5px;
}
.post-view-area .post-view .post .replies ul li .repl {
  display: flex;
  flex-direction: column;
  margin-top: -25px;
}
.post-view-area .post-view .post .replies ul li .repl .user {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
}
.post-view-area .post-view .post .replies ul li .repl .user .img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.post-view-area .post-view .post .replies ul li .repl .user img {
  width: 15px;
  margin-left: 15px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
}
.post-view-area .post-view .post .replies ul li .repl .user img:hover {
  background-color: #f6f6f6;
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area {
  position: relative;
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 0px;
  border: 1px solid transparent;
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .menu {
  right: 0px;
  background-color: #e7e7e7;
  width: 180px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(0px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  margin-top: 5px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .menu section {
  flex-direction: column;
  top: 30px;
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  border-radius: 5px;
  margin: 0px;
  height: 20px;
  cursor: pointer;
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .menu span img {
  width: 20px;
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .menu span h6 {
  margin-left: 10px;
}
.post-view-area .post-view .post .replies ul li .repl .user .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}
.post-view-area .post-view .post .replies ul li .repl .repl-text {
  margin-top: -25px;
  margin-left: 10px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text p {
  padding: 10px;
  background-color: #f6f6f6;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 500px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text h6 {
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 400;
  margin-left: 30px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text span {
  margin-left: 30px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .add_sub_reply {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 0px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .add_sub_reply .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .add_sub_reply input {
  width: calc(100% - 50px);
  max-width: 400px;
  padding: 10px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  font-family: "Poppins", sans-serif;
  margin-left: 15px;
  padding-left: 15px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies {
  margin-left: 30px;
  margin-right: 5px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul {
  display: flex;
  flex-direction: column;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user {
  display: flex;
  align-items: center;
  position: relative;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user h5 {
  margin-left: 15px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area {
  position: relative;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .icon {
  width: 20px;
  cursor: pointer;
  padding: 7px;
  border-radius: 50%;
  margin-top: 7px;
  margin-left: 5px;
  border: 1px solid transparent;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(227, 227, 227, 0.8196078431);
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu {
  position: absolute;
  right: 10px;
  width: 170px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  z-index: -5;
  background-color: rgb(255, 255, 255);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  padding-top: 5px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu section {
  flex-direction: column;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span {
  display: flex;
  align-items: center;
  width: 160px;
  padding: 10px;
  border-radius: 5px;
  margin-right: -20px;
  cursor: pointer;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span h6 {
  text-decoration-line: none;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span:hover {
  background-color: rgb(243, 243, 243);
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span img {
  width: 20px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .menu span h6 {
  margin-left: 10px;
}
.post-view-area .post-view .post .replies ul li .repl .repl-text .sub_replies ul li .sub_user .menu-area .show-menu {
  z-index: 1;
  opacity: 1;
  transform: translateY(0);
}
.post-view-area .post-view .post .replies span h6 {
  color: #000;
  font-size: 0.7rem;
  font-weight: 400;
}

.post-desc p {
  width: calc(100% - 25px);
  font-weight: 400;
  color: #656565;
  font-size: 0.9rem;
  margin: auto;
}
.post-desc p button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #0245f5;
  font-size: 0.9rem;
  background: transparent;
  outline: none;
  border: none;
  margin-left: 7px;
  cursor: pointer;
}

@media screen and (max-width: 760px) {
  .post-view-area {
    width: 100%;
    justify-content: center;
    margin: auto;
  }
}
.create-post-area {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 800;
  overflow-y: auto;
}
.create-post-area .overlay {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding-left: 8px;
}
.create-post-area .overlay .modal {
  width: calc(100% - 20px);
  max-width: 500px;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  border-radius: 6px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border: 1px solid #bbbbbb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: -7px;
  margin-right: 10px;
  position: static;
}
.create-post-area .overlay .modal .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
}
.create-post-area .overlay .modal .head .user {
  display: flex;
  align-items: center;
  margin: 7px;
}
.create-post-area .overlay .modal .head .user .img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}
.create-post-area .overlay .modal .head img {
  width: 17px;
  padding: 10px;
  background-color: #efefef;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  transform: rotate(45deg);
  margin-right: 7px;
  cursor: pointer;
}
.create-post-area .overlay .modal .head img:hover {
  background-color: #d1d1d1;
}
.create-post-area .overlay .modal .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.create-post-area .overlay .modal .body textarea {
  width: calc(100% - 50px);
  outline: none;
  border: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  margin: auto;
  margin-top: 15px;
  color: #4c4c4c;
  height: 100px;
}
.create-post-area .overlay .modal .body textarea::-webkit-scrollbar {
  width: 0.6vw;
}
.create-post-area .overlay .modal .body textarea::-webkit-scrollbar-thumb {
  background-color: #a7a7a8;
  border-radius: 5px;
}
.create-post-area .overlay .modal .body .img-area {
  margin-top: 10px;
}
.create-post-area .overlay .modal .body .img-area .icon {
  width: 11px;
  cursor: pointer;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: rotate(45deg);
  margin-left: 20px;
}
.create-post-area .overlay .modal .body .img-area .img {
  width: calc(100% - 25px);
  height: -moz-fit-content;
  height: fit-content;
  max-height: 300px;
  overflow-y: auto;
  text-align: center;
}
.create-post-area .overlay .modal .body .img-area .img img {
  width: calc(100% - 80px);
  border-radius: 5px;
}
.create-post-area .overlay .modal .body .img-area .img::-webkit-scrollbar {
  width: 0.6vw;
}
.create-post-area .overlay .modal .body .img-area .img::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.create-post-area .overlay .modal .body .img-area .img::-webkit-scrollbar-thumb:hover {
  display: block;
}
.create-post-area .overlay .modal .body .img-area .img:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
@media screen and (max-height: 655px) {
  .create-post-area .overlay .modal .body .img-area .img {
    max-height: 200px;
  }
}
@media screen and (max-height: 650px) {
  .create-post-area .overlay .modal .body .img-area .img {
    max-height: 180px;
  }
}
.create-post-area .overlay .modal .tags {
  display: flex;
  width: calc(100% - 80px);
  max-width: 400px;
  margin: auto;
  margin-top: 25px;
  height: 50px;
  flex-direction: row-reverse;
  justify-content: flex-start;
  overflow-y: auto;
}
.create-post-area .overlay .modal .tags::-webkit-scrollbar {
  height: 0.6vh;
}
.create-post-area .overlay .modal .tags::-webkit-scrollbar-thumb {
  background-color: #f7f7fa;
  border-radius: 5px;
}
.create-post-area .overlay .modal .tags h5 {
  padding: 4px 8px;
  background-color: rgba(254, 254, 254, 0.5568627451);
  display: flex;
  align-items: center;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.135);
  font-size: 0.8rem;
  height: 14px;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.create-post-area .overlay .modal .tags h5 img {
  width: 10px;
  transform: rotate(45deg);
  margin-left: 6px;
}
.create-post-area .overlay .modal .tags h5:last-child:hover {
  background-color: #cecccc;
}
.create-post-area .overlay .modal .tags h5:last-child img {
  transform: rotate(0deg);
}
.create-post-area .overlay .modal .anony {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 2px 6px;
  background-color: #e6e5e5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 130px;
}
.create-post-area .overlay .modal .anony:hover {
  background-color: #d5d3d3;
}
.create-post-area .overlay .modal .anony .cls-anony-text {
  color: #3a3a3a;
  margin-left: 10px;
  font-size: 0.7rem;
  cursor: pointer;
  min-width: 150px;
  font-weight: 400;
}
.create-post-area .overlay .modal .anony input {
  cursor: pointer;
}
@media screen and (max-width: 355px) {
  .create-post-area .overlay .modal .anony {
    min-width: 120px;
    margin-left: 0px;
  }
}
.create-post-area .overlay .modal .bottom {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 15px;
}
.create-post-area .overlay .modal .bottom .post-create-btn {
  width: 80px;
}
.create-post-area .overlay .modal .bottom button {
  outline: none;
  border: none;
  background-color: #1b81f5;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding: 7px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 30px;
}
.create-post-area .overlay .modal .bottom button:hover {
  background-color: #336cfb;
}
.create-post-area .overlay .modal .bottom button:nth-child(1) {
  width: 150px;
}
.create-post-area .overlay .modal .bottom button:nth-child(2) {
  width: 30;
  display: flex;
  align-items: center;
}
.create-post-area .overlay .modal .bottom button:nth-child(2) img {
  width: 18px;
}
.create-post-area .overlay .modal .bottom label {
  border-radius: 4px;
  cursor: pointer;
  width: 25px;
  height: 30px;
  width: 30;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.create-post-area .overlay .modal .bottom label button {
  background: transparent;
}
.create-post-area .overlay .modal .bottom label button:hover {
  background: transparent;
}
.create-post-area .overlay .modal .bottom label img {
  width: 18px;
  margin: 0px 3px;
}
.create-post-area .overlay .modal .bottom .tag-people {
  width: 20px;
  cursor: pointer;
}
@media screen and (max-width: 355px) {
  .create-post-area .overlay .modal .bottom label {
    margin-left: -3px;
  }
}

.tags-area {
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 600px;
  background-color: rgba(255, 255, 255, 0.894);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  border-radius: 6px;
  border: 1px solid #bbbbbb;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 300;
  display: none;
  margin-left: -12px;
}
.tags-area .head {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
}
.tags-area .head h3 {
  margin-left: 15px;
  cursor: pointer;
  font-size: 0.9rem;
}
.tags-area .head p {
  margin-right: 40px;
  font-size: 0.8rem;
  font-weight: 400;
}
.tags-area .search {
  width: calc(100% - 50px);
  background-color: #dbdbdb;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 15px;
}
.tags-area .search input {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
}
.tags-area span {
  font-weight: 400;
  float: left;
  margin-top: 10px;
}
.tags-area .selected {
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  overflow-y: auto;
  height: 100px;
  overflow-y: auto;
}
.tags-area .selected h5 {
  margin-left: 15px;
  padding: 5px 10px;
  background-color: #eae8e8;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  display: flex;
  align-items: center;
}
.tags-area .selected h5 img {
  width: 12px;
  transform: rotate(45deg);
  margin-left: 10px;
}
.tags-area .body {
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  overflow-y: auto;
}
.tags-area .body h5 {
  margin-left: 15px;
  padding: 5px 10px;
  background-color: #eae8e8;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}

.tags-active {
  display: flex;
}

.create-active {
  display: block;
}

.explore-area {
  width: calc(100% - 250px);
  margin-left: 250px;
  display: flex;
  justify-content: space-around;
  padding-top: 70px;
  padding-bottom: 25px;
  margin-top: 20px;
}
.explore-area .explore {
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  padding: 0px 0px 10px 0px;
}
.explore-area .explore .head {
  width: 100%;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  align-items: center;
}
.explore-area .explore .head img {
  width: 15px;
  margin: 10px;
  padding: 7px;
  border-radius: 50%;
  border: 1px solid #d5d4d4;
  cursor: pointer;
}
.explore-area .explore .head img:hover {
  background-color: #f5f5f5;
}
.explore-area .explore .search {
  margin: 10px;
  position: relative;
}
.explore-area .explore .search input {
  width: calc(100% - 25px);
  max-width: 350px;
  padding: 10px;
  background-color: #f4f4f4;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
}
.explore-area .explore .search .results {
  position: absolute;
  width: calc(100% - 25px);
  max-width: 350px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  top: -500px;
  opacity: 0;
  left: 0;
}
.explore-area .explore .search .results p {
  font-weight: 400;
  font-size: 0.8rem;
  color: gray;
  margin-bottom: 15px;
}
.explore-area .explore .search .results span {
  color: #444;
  font-size: 0.8rem;
  margin-top: 25px;
  font-weight: 400;
}
.explore-area .explore .search .results ul {
  list-style-type: none;
}
.explore-area .explore .search .results ul li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.explore-area .explore .search .results ul li section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(100% - 16px);
  padding: 8px;
  border-radius: 5px;
}
.explore-area .explore .search .results ul li section .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border: 1.5px solid #0245f5;
}
.explore-area .explore .search .results ul li section h5 {
  font-size: 0.8rem;
  margin-left: 15px;
  color: #4c4c4c;
}
.explore-area .explore .search .results ul li section:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  background-color: #f5f5f5;
}
.explore-area .explore .search .results.active {
  opacity: 1;
  position: absolute;
  transform: translateY(0);
  top: 50px;
}
.explore-area .explore .tags {
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
  overflow-y: auto;
}
.explore-area .explore .tags h5 {
  margin-left: 15px;
  padding: 5px 10px;
  background-color: #eae8e8;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}
.explore-area .explore .tags h5:hover {
  background-color: #d1cfcf;
}
.explore-area .explore .service-links {
  margin-top: 5px;
  margin-left: 10px;
}
.explore-area .explore .service-links a {
  text-decoration-line: none;
  color: #0245f5;
}
.explore-area .explore .service-links h6 {
  margin-top: 10px;
  font-size: 0.8rem;
}
@media screen and (min-width: 761px) {
  .explore-area .explore .service-links {
    display: none;
  }
}
.explore-area .explore h3 {
  margin-top: 15px;
  color: #4c4c4c;
  margin-left: 10px;
}
.explore-area .explore .groups {
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
  overflow-y: auto;
}
.explore-area .explore .groups section {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.explore-area .explore .groups section .img {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  background-repeat: no-repeat;
}
.explore-area .explore .groups section h4 {
  font-size: 0.7rem;
  margin: 10px;
  color: #646464;
}

@media screen and (max-width: 760px) {
  .explore-area {
    width: calc(100vw - 20px);
    margin-left: 0px;
    margin: auto;
  }
  .groups-feed-area .groups-feed .group-posts {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 760px) {
  .explore-area {
    margin-right: 10px;
  }
}
.search-area {
  width: calc(100% - 250px);
  margin-left: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 25px;
}
.search-area .head {
  width: calc(100% - 30px);
  max-width: 600px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  position: sticky;
  top: 70px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.07);
}
.search-area .head input {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.04);
  padding: 10px;
  width: calc(100% - 23px);
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  margin: auto;
}
.search-area .head input:focus {
  border-color: #1b81f5;
}
.search-area .results-bar {
  position: absolute;
  width: calc(100% - 25px);
  max-width: 350px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  top: -500px;
  opacity: 0;
  left: 0;
}
.search-area .results-bar p {
  font-weight: 400;
  font-size: 0.8rem;
  color: gray;
  margin-bottom: 15px;
}
.search-area .results-bar span {
  color: #444;
  font-size: 0.8rem;
  margin-top: 25px;
  font-weight: 400;
}
.search-area .results-bar ul {
  list-style-type: none;
}
.search-area .results-bar ul li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.search-area .results-bar ul li section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(100% - 16px);
  padding: 8px;
  border-radius: 5px;
}
.search-area .results-bar ul li section .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border: 1.5px solid #0245f5;
}
.search-area .results-bar ul li section h5 {
  font-size: 0.8rem;
  margin-left: 15px;
  color: #4c4c4c;
}
.search-area .results-bar ul li section:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  background-color: #f5f5f5;
}
.search-area .results-bar.active {
  opacity: 1;
  position: absolute;
  transform: translateY(0);
  top: 50px;
}
.search-area .results {
  width: calc(100% - 10px);
  display: flex;
  max-width: 600px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 7px;
}
.search-area .results h5 {
  margin: 10px;
  font-size: 1rem;
  color: #4c4c4c;
}
.search-area .results .people {
  border-radius: 5px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.search-area .results .people section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
}
.search-area .results .people section .user {
  display: flex;
  align-items: center;
}
.search-area .results .people section .user .img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.search-area .results .people section .user .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 15px;
}
.search-area .results .people section .user .text h3 {
  font-size: 0.9rem;
}
.search-area .results .people section .user .text span {
  font-weight: 300;
  font-size: 0.8rem;
}
.search-area .results .people section button {
  outline: none;
  background-color: rgba(53, 110, 255, 0.2862745098);
  color: #0245f5;
  font-family: "Poppins", sans-serif;
  padding: 4px 10px;
  border-radius: 5px;
  letter-spacing: 0.5px;
  cursor: pointer;
  border: 1px solid rgba(2, 71, 245, 0.4352941176);
}
.search-area .results h4 {
  font-size: 0.8rem;
  color: #444;
}
@media screen and (max-width: 760px) {
  .search-area {
    width: 100%;
    margin-left: 0px;
  }
  .search-area .results .people section .user .text h3 {
    width: 150px;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 880px) {
  .side-menu {
    z-index: 1;
    left: -300px;
  }
  .active {
    left: 0;
  }
  .chat-main {
    overflow-x: hidden;
  }
  .chat-main .chat-area {
    margin-left: 0px;
    width: 100vw;
  }
}
.drawer-active {
  left: 0;
}

.active {
  left: 0;
}

.back-icon {
  display: block;
}
@media screen and (min-width: 880px) {
  .back-icon {
    display: none;
  }
}

.edite-profile {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
}
.edite-profile .overlay {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  min-height: calc(100vh + 180px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
.edite-profile .overlay .modal {
  width: calc(100% - 20px);
  max-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  border-radius: 6px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border: 1px solid #bbbbbb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
.edite-profile .overlay .modal .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
}
.edite-profile .overlay .modal .head .head-user {
  display: flex;
  align-items: center;
  margin: 7px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
}
.edite-profile .overlay .modal .head .head-user h5 {
  margin-left: 15px;
  font-size: 1rem;
}
.edite-profile .overlay .modal .head .head-user .img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}
.edite-profile .overlay .modal .head img {
  width: 17px;
  padding: 10px;
  background-color: #efefef;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  transform: rotate(45deg);
  margin-right: 7px;
  cursor: pointer;
}
.edite-profile .overlay .modal .head img:hover {
  background-color: #d1d1d1;
}
.edite-profile .overlay .modal .head img:nth-child(1) {
  transform: rotate(0deg);
}
.edite-profile .overlay .modal .head img:nth-child(2) {
  margin-right: 15px;
}
.edite-profile .overlay .modal .image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 15px;
  cursor: pointer;
}
.edite-profile .overlay .modal .description {
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-left: 25px;
}
.edite-profile .overlay .modal .description h4 {
  content: "About";
  position: absolute;
  font-size: 0.8rem;
  margin-top: -20px;
}
.edite-profile .overlay .modal .description textarea {
  padding: 10px;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 10px;
  width: 250px;
  font-family: "Poppins", sans-serif;
  min-height: -moz-fit-content;
  min-height: fit-content;
  height: 80px;
}
.edite-profile .overlay .modal .more {
  margin: auto;
  width: calc(100% - 20px);
  margin-left: 25px;
}
.edite-profile .overlay .modal .more p {
  width: 100%;
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.edite-profile .overlay .modal .more p img {
  width: 20px;
  margin-right: 10px;
}
.edite-profile .overlay .modal .more p .section input {
  font-family: "Poppins", sans-serif;
  min-width: 200px;
  background: transparent;
  outline: none;
  border: none;
  margin-left: 10px;
}
.edite-profile .overlay .modal .more p .section img {
  width: 15px;
  cursor: pointer;
  margin-left: 10px;
}
.edite-profile .overlay .modal .hobbies {
  margin-top: 15px;
  width: calc(100% - 40px);
}
.edite-profile .overlay .modal .hobbies section {
  display: flex;
  flex-wrap: wrap;
}
.edite-profile .overlay .modal .hobbies section h5 {
  padding: 5px 10px;
  background-color: #f0f1f2;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.edite-profile .overlay .modal .hobbies input {
  margin-left: 5px;
  outline: none;
  border: none;
  margin-top: 10px;
  padding: 10px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  width: 250px;
  background-color: #efefef;
  border: 2px solid rgba(0, 0, 0, 0.1);
}
.edite-profile .overlay .modal .hobbies input:focus {
  border: 2px solid rgba(0, 0, 0, 0.25);
}

.tags-view-area {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
}
.tags-view-area .head-top {
  background-color: #fff;
  max-width: 600px;
  display: flex;
  width: calc(100% - 15px);
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 50px;
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  z-index: 10;
  margin-left: -5px;
}
.tags-view-area .head-top img {
  width: 25px;
  cursor: pointer;
}
.tags-view-area .head-top h4 {
  margin-left: 15px;
}

.group-side-view {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: -100vw;
}
.group-side-view .overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  transition-delay: 0s;
}
.group-side-view .items {
  background-color: #fff;
  height: 100%;
  width: 100%;
  z-index: 400;
  max-width: 250px;
  position: fixed;
  min-height: 100vh;
  overflow: auto;
  border-right: 2px solid #d5d4d4;
  left: -300px;
  transition: all 0.2s ease-in-out;
}
.group-side-view .items ul {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  align-items: flex-start;
}
.group-side-view .items ul li {
  display: flex;
  align-items: center;
  padding: 17px;
  width: 100%;
  margin-top: 8px;
  cursor: pointer;
  padding: 10px;
}
.group-side-view .items ul li:hover {
  background-color: #efefef;
}
.group-side-view .items ul li .img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
}
.group-side-view .items ul li h4 {
  margin-left: 15px;
  font-size: 0.9rem;
  color: #3f3e3e;
}

.left-0 {
  left: 0;
  transition: all 0.2s ease-in-out;
}
.left-0 .items {
  left: 0;
}
.left-0 .overlay {
  display: block;
}

.mt-25 {
  margin-top: 25px;
}

.friends-area {
  width: 100%;
  height: 100%;
}
.friends-area .overlay {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
}
.friends-area .overlay .modal {
  width: 100%;
  max-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  border-radius: 6px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border: 1px solid #bbbbbb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
.friends-area .overlay .modal .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
}
.friends-area .overlay .modal .head .head-user {
  display: flex;
  align-items: center;
  margin: 7px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
}
.friends-area .overlay .modal .head .head-user h5 {
  margin-left: 15px;
  font-size: 1rem;
}
.friends-area .overlay .modal .head .head-user .img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}
.friends-area .overlay .modal .head img {
  width: 17px;
  padding: 10px;
  background-color: #efefef;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  transform: rotate(45deg);
  margin-right: 7px;
  cursor: pointer;
  transform: rotate(45deg);
}
.friends-area .overlay .modal .head img:hover {
  background-color: #d1d1d1;
}
.friends-area .overlay .modal .find {
  width: calc(100% - 80px);
  margin-top: 15px;
}
.friends-area .overlay .modal .find input {
  height: 100%;
  width: calc(100% - 40px);
  padding: 13px;
  margin: auto;
  background-color: #f7f7fa;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
}
.friends-area .overlay .modal .find input:hover {
  border: 2px solid rgba(0, 0, 0, 0.3);
}
.friends-area .overlay .modal .find input:focus {
  border: 2px solid rgba(0, 0, 0, 0.3);
}
.friends-area .overlay .modal .find .body {
  overflow-y: auto;
  margin-top: 10px;
}
.friends-area .overlay .modal .find .body ul {
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  justify-content: center;
}
.friends-area .overlay .modal .find .body ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}
.friends-area .overlay .modal .find .body ul li div {
  margin: 5px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
}

.update-post-area {
  width: 100%;
  height: 100%;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
}
.update-post-area .overlay {
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.update-post-area .overlay .modal {
  width: calc(100% - 25px);
  max-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #d1cfcf;
}
.update-post-area .overlay .modal .head {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.update-post-area .overlay .modal .head img {
  width: 20px;
  padding: 10px;
  border-radius: 50%;
  background-color: #e0e4eb;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.back-btn {
  position: fixed;
  margin-top: 60px;
  margin-left: 15px;
  bottom: 10px;
  left: 0;
  z-index: 600;
}
.back-btn img {
  width: 15px;
  background-color: #e6e4e4;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
}

.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  max-width: 900px;
  padding-bottom: 25px;
  margin: auto;
  overflow-x: hidden;
}
.profile .cover-image {
  width: 100%;
  height: 300px;
  max-width: 900px;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  background-repeat: no-repeat;
}
.profile .cover-image .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.profile .cover-image .overlay label {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}
.profile .cover-image .overlay label img {
  width: 15px;
}
.profile .user {
  display: flex;
  width: calc(100% - 20px);
  justify-content: space-between;
  align-items: center;
}
.profile .user .sec1 {
  display: flex;
  align-items: flex-end;
  width: -moz-fit-content;
  width: fit-content;
}
.profile .user .sec1 section {
  padding-bottom: 15px;
  margin-left: 15px;
}
.profile .user .sec1 section h3 {
  font-size: 1.5rem;
  color: #252525;
}
.profile .user .sec1 section h5 {
  color: #646464;
}
.profile .user .sec1 .profile-image {
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 2px solid #3b6eee;
  margin-top: -75px;
  margin-left: 35px;
}
.profile .user .sec1 .profile-image .overlay2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.profile .user .sec1 .profile-image .overlay2:hover {
  opacity: 1;
}
.profile .user .sec1 .profile-image .overlay2 img {
  width: 20px;
}
@media screen and (max-width: 500px) {
  .profile .user .sec1 {
    justify-content: row;
  }
}
.profile .user .btns {
  margin-right: 25px;
  display: flex;
  align-items: center;
}
.profile .user .btns button {
  padding: 7px 10px;
  outline: none;
  border: 1px solid rgba(182, 182, 182, 0.5);
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
  font-weight: 400;
  border-radius: 7px;
  cursor: pointer;
}
.profile .user .btns img {
  width: 25px;
  cursor: pointer;
  margin-left: 10px;
}
@media screen and (max-width: 500px) {
  .profile .user .btns {
    margin: 0px;
    margin-top: 15px;
  }
  .profile .user .btns button {
    margin: 0px;
  }
  .profile .user .btns img {
    margin-top: 10px;
  }
}
@media screen and (max-width: 650px) {
  .profile .user .sec1 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .profile .user .sec1 .profile-image {
    margin-left: 0px;
  }
}
.profile .user-about {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: auto;
}
.profile .user-about .user-bio {
  width: calc(100% - 20px);
  max-width: 300px;
  min-width: 220px;
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: 50px;
  margin: 10px;
}
.profile .user-about .user-bio h4 {
  color: #3b3a3a;
  margin-top: 15px;
}
.profile .user-about .user-bio .bio {
  width: calc(100% - 20px);
  margin-top: 15px;
  min-width: 220px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(236, 196, 196, 0.06);
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
}
.profile .user-about .user-bio .bio .about {
  width: 100%;
  margin-top: 10px;
  margin: auto;
}
.profile .user-about .user-bio .bio .about p {
  font-weight: 400;
  font-size: 0.9rem;
  margin-bottom: 10px;
  line-height: 20px;
  font-family: "segoeui", sans-serif;
}
.profile .user-about .user-bio .bio .hobbies {
  max-width: calc(100% - 20px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.profile .user-about .user-bio .bio .hobbies h5 {
  padding: 5px 10px;
  margin-top: 5px;
  background-color: #e7e7e7;
  margin: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.profile .user-about .user-bio .bio .more {
  margin: auto;
  width: calc(100% - 20px);
}
.profile .user-about .user-bio .bio .more p {
  width: 100%;
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.profile .user-about .user-bio .bio .more p img {
  width: 20px;
  margin-right: 10px;
}
.profile .user-about .user-bio .bio .friends-head {
  padding: 5px;
}
.profile .user-about .user-bio .bio .friends-head h5 {
  color: #1b81f5;
  cursor: pointer;
  margin-top: 15px;
}
.profile .user-about .user-bio .bio .friends {
  background-color: #f1f1f1;
  max-height: 250px;
  margin-top: 15px;
  overflow-y: auto;
  border-radius: 5px;
}
.profile .user-about .user-bio .bio .friends ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
  list-style-type: none;
}
.profile .user-about .user-bio .bio .friends ul li {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 2px;
  border-radius: 5px;
  border: 2px solid #fff;
}
.profile .user-about .user-bio .activities {
  width: calc(100% - 40px);
  margin-top: 10px;
  min-width: 220px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 370px);
  margin-left: 15px;
  overflow-y: auto;
}
.profile .user-about .user-bio .activities::-webkit-scrollbar {
  width: 0.6vw;
}
.profile .user-about .user-bio .activities::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.profile .user-about .user-bio .activities::-webkit-scrollbar-thumb:hover {
  display: block;
}
.profile .user-about .user-bio .activities:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.profile .user-about .user-bio .activities h4 {
  color: #3b3a3a;
  margin-top: 15px;
}
.profile .user-about .user-bio .activities ul {
  list-style-type: none;
  height: 100%;
}
.profile .user-about .user-bio .activities ul li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 17px;
}
.profile .user-about .user-bio .activities ul li .img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile .user-about .user-bio .activities ul li p {
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 8px;
  cursor: pointer;
}
.profile .user-about .user-bio .activities ul li p strong {
  margin-right: 8px;
}
.profile .user-about .user-bio .activities ul li p:hover {
  text-decoration-line: underline;
}
@media screen and (max-width: 440px) {
  .profile .user-about .user-bio .activities {
    max-height: 350px;
  }
}
.profile .user-about .accept-posts {
  width: calc(100% - 20px);
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  min-width: 220px;
  border-radius: 5px;
  border: 1px solid rgba(236, 196, 196, 0.06);
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeee;
  background-color: #fff;
}
.profile .user-about .accept-posts section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.profile .user-about .accept-posts section .image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.profile .user-about .accept-posts section h3 {
  font-size: 0.9rem;
  margin-left: 10px;
}
.profile .user-about .accept-posts section button {
  margin: 10px;
  padding: 5px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 5px;
}
.profile .user-about .accept-posts section button:first-child {
  margin-left: 4px;
  border-color: #0245f5;
}
.profile .user-about .accept-posts section button:last-child {
  margin-left: 2px;
  border-color: #cacaca;
}
.profile .user-about .accept-posts p {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 2px;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
  margin-top: 10px;
  cursor: pointer;
}
.profile .user-about .accept-posts p:hover {
  color: #0245f5;
  text-decoration-line: underline;
}
.profile .user-about .user-posts {
  width: 100%;
  display: flex;
  margin-right: -10px;
}
.profile .user-about .user-posts h4 {
  color: #3b3a3a;
  margin-top: 60px;
}
.profile .user-about .user-posts .post {
  min-width: 500px;
}
.profile .user-about .user-posts .post .support h4 {
  top: -15px;
}
@media screen and (max-width: 850px) {
  .profile .user-about {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .profile .user-about .user-bio {
    max-width: 500px;
    position: static;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .profile .user-about .bio {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: static;
    margin-left: 0px;
  }
  .profile .user-about .bio .about {
    margin: auto;
  }
  .profile .user-about .bio .about p {
    width: calc(100% - 20px);
  }
  .profile .user-about .bio .more {
    margin: auto;
    width: calc(100% - 20px);
  }
  .profile .user-about .bio .more p {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .profile .user-about .bio .more p img {
    width: 20px;
    margin-right: 10px;
  }
  .profile .user-about .activities {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: static;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .profile .user-about .profile_user_posts {
    margin-left: 20px;
  }
}
@media screen and (max-width: 500px) {
  .profile .user {
    flex-direction: column;
  }
  .profile .user .sec1 {
    flex-direction: column;
    align-items: center;
  }
  .profile .user .btns {
    margin-top: 0;
  }
}

.group-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  max-width: 900px;
  padding-bottom: 25px;
  margin: auto;
  padding-top: 55px;
}
.group-area .cover-image {
  width: 100%;
  height: 300px;
  max-width: 900px;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}
.group-area .cover-image .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.group-area .cover-image .overlay label {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}
.group-area .cover-image .overlay label img {
  width: 25px;
}
.group-area .cover-image .overlay .sec1 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.group-area .cover-image .overlay .sec1 section {
  padding-bottom: 15px;
  margin-left: 15px;
}
.group-area .cover-image .overlay .sec1 section h3 {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 400;
}
.group-area .cover-image .overlay .sec1 section h5 {
  color: #e8e8e8;
  font-size: 0.8rem;
  font-weight: 300;
}
.group-area .cover-image .overlay .sec1 .profile-image {
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 2px solid #3b6eee;
  margin-left: 20px;
}
.group-area .cover-image .overlay .sec1 .profile-image .overlay2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.group-area .cover-image .overlay .sec1 .profile-image .overlay2:hover {
  opacity: 1;
}
.group-area .cover-image .overlay .sec1 .profile-image .overlay2 img {
  width: 20px;
}
.group-area .second-area {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-top: 15px;
}
.group-area .second-area section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.group-area .second-area section p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #444;
}
.group-area .second-area section button {
  padding: 7px 10px;
  background-color: #e93a4b;
  outline: none;
  border: 1px solid rgba(182, 182, 182, 0.5);
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
  font-weight: 400;
  color: #fff;
  border-radius: 7px;
  cursor: pointer;
}
.group-area .second-area section:nth-child(1) {
  margin-left: 15px;
}
.group-area .second-area section:nth-child(2) {
  margin-right: 15px;
}
.group-area .user {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.group-area .user .btns {
  margin-right: 25px;
  display: flex;
  align-items: center;
}
.group-area .user .btns button {
  padding: 7px 10px;
  background-color: #0245f5;
  outline: none;
  border: 1px solid rgba(182, 182, 182, 0.5);
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.06);
  font-weight: 400;
  color: #fff;
  border-radius: 7px;
  cursor: pointer;
}
.group-area .user .btns img {
  width: 25px;
  cursor: pointer;
  margin-left: 10px;
}
@media screen and (max-width: 500px) {
  .group-area .user .btns {
    flex-direction: column;
    margin: 0px;
  }
  .group-area .user .btns button {
    margin: 0px;
  }
  .group-area .user .btns img {
    margin-top: 10px;
  }
}
@media screen and (max-width: 650px) {
  .group-area .user .sec1 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .group-area .user .sec1 .profile-image {
    margin-left: 0px;
  }
}
.group-area .user-about {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 20px);
  max-width: 1000px;
}
.group-area .user-about .bio {
  width: calc(100% - 20px);
  margin-top: 25px;
  max-width: 230px;
  min-width: 220px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: 55px;
}
.group-area .user-about .bio h4 {
  color: #3b3a3a;
  margin-top: 15px;
}
.group-area .user-about .bio .about {
  width: 100%;
  margin-top: 10px;
  margin: auto;
}
.group-area .user-about .bio .about p {
  font-weight: 400;
  font-size: 0.9rem;
  margin-bottom: 10px;
  line-height: 20px;
  font-family: "segoeui", sans-serif;
}
.group-area .user-about .bio .hobbies {
  max-width: calc(100% - 20px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.group-area .user-about .bio .hobbies h5 {
  padding: 5px 10px;
  margin-top: 5px;
  background-color: #e7e7e7;
  margin: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.group-area .user-about .bio .more {
  margin: auto;
  width: calc(100% - 20px);
}
.group-area .user-about .bio .more p {
  width: 100%;
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.group-area .user-about .bio .more p img {
  width: 20px;
  margin-right: 10px;
}
.group-area .user-about .bio .friends-head {
  padding: 5px;
}
.group-area .user-about .bio .friends-head h5 {
  color: #1b81f5;
  cursor: pointer;
  margin-top: 15px;
}
.group-area .user-about .bio .friends {
  background-color: #f1f1f1;
  max-height: 250px;
  margin-top: 15px;
  overflow-y: auto;
  border-radius: 5px;
}
.group-area .user-about .bio .friends ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
  list-style-type: none;
}
.group-area .user-about .bio .friends ul li {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 2px;
  border-radius: 5px;
  border: 2px solid #fff;
}
@media screen and (max-width: 680px) {
  .group-area .user-about {
    flex-direction: column;
    align-items: center;
    justify-content: none;
  }
  .group-area .user-about .bio {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: static;
  }
  .group-area .user-about .bio .about {
    margin: auto;
  }
  .group-area .user-about .bio .about p {
    width: calc(100% - 20px);
  }
  .group-area .user-about .bio .more {
    margin: auto;
    width: calc(100% - 20px);
  }
  .group-area .user-about .bio .more p {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .group-area .user-about .bio .more p img {
    width: 20px;
    margin-right: 10px;
  }
}
.group-area .user-about .group-members {
  font-size: 0.8rem;
  color: #1b81f5;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.group-area .user-about .members {
  max-width: calc(100% - 20px);
}
.group-area .user-about .members ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
}
.group-area .user-about .members li {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  margin: 5px;
}
.group-area .create-top {
  width: calc(100% - 14px);
  max-width: 600px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  margin: auto;
  margin-top: 25px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-left: -12px;
}
.group-area .create-top .img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 2px solid #eeeeee;
}
.group-area .create-top h5 {
  width: 100%;
  max-width: 510px;
  margin-left: 20px;
  padding: 10px 15px;
  background-color: #edebeb;
  border-radius: 25px;
  color: #8c8c8c;
  cursor: pointer;
}
.group-area .create-top h5:hover {
  background-color: #e0dede;
}
.group-area .group-posts-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 570px;
  margin-left: 18px;
}
.group-area .group-posts {
  width: 100%;
  max-width: 570px;
  display: flex;
  justify-content: center;
}
.group-area .group-posts h4 {
  color: #3b3a3a;
  margin-top: 60px;
}
.group-area .group-posts .posts {
  margin: 0px;
}
.group-area .group-posts .post .support h4 {
  top: -15px;
}
.group-area .group-posts .post .head {
  width: 100%;
}
.group-area .group-posts .post .head section {
  display: flex;
}
.group-area .group-posts .post .action {
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (min-width: 760px) {
  .group-icon {
    display: none;
  }
}

.chat-area {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-area .chat-main {
  background-color: #fff;
  width: 100%;
  max-width: 1000px;
  height: calc(100vh - 120px);
  padding: 10px 0px 0px 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.chat-area .chat-main .left {
  height: 100%;
  width: 100%;
  max-width: 280px;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  padding-right: 5px;
  background-color: #fff;
}
.chat-area .chat-main .left .top {
  width: 100%;
  padding: 10px 0px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.chat-area .chat-main .left .top .user {
  display: flex;
  align-items: center;
}
.chat-area .chat-main .left .top .user .img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.chat-area .chat-main .left .top .user section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
}
.chat-area .chat-main .left .top .user section h5 {
  font-size: 0.9rem;
}
.chat-area .chat-main .left .top .user section p {
  font-size: 0.8rem;
}
.chat-area .chat-main .left .top .close-icon {
  display: none;
}
.chat-area .chat-main .left::-webkit-scrollbar {
  width: 0.4vw;
  padding-right: 10px;
}
.chat-area .chat-main .left::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 5px;
  opacity: 0;
  cursor: pointer;
}
.chat-area .chat-main .left::-webkit-scrollbar-thumb:hover {
  opacity: 1;
  background-color: rgb(217, 217, 217);
}
.chat-area .chat-main .left ul {
  list-style-type: none;
  overflow-y: auto;
  height: calc(100% - 65px);
}
.chat-area .chat-main .left ul::-webkit-scrollbar {
  width: 0.6vw;
}
.chat-area .chat-main .left ul::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.chat-area .chat-main .left ul::-webkit-scrollbar-thumb:hover {
  display: block;
}
.chat-area .chat-main .left ul:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.chat-area .chat-main .left ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.chat-area .chat-main .left ul li:hover {
  background-color: #f3f3f3;
}
.chat-area .chat-main .left ul li section {
  display: flex;
  align-items: center;
}
.chat-area .chat-main .left ul li section .img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid rgba(0, 0, 0, 0.2);
}
.chat-area .chat-main .left ul li section h4 {
  font-weight: 400;
  font-size: 0.8rem;
  margin-left: 15px;
}
.chat-area .chat-main .left ul li .icons {
  display: flex;
  align-items: center;
}
.chat-area .chat-main .left ul li .icons span {
  width: 18px;
  height: 18px;
  background-color: #336cfb;
  padding: 5px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
}
.chat-area .chat-main .left ul li .icons svg {
  width: 18px;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0px 7px;
  fill: #575757;
}
.chat-area .chat-main .left .user-chats form {
  position: relative;
}
.chat-area .chat-main .left .user-chats form input {
  width: 100%;
  max-width: 450px;
  padding: 6px 10px;
  outline: none;
  border: 1px solid #999;
  font-family: "Poppins", sans-serif;
  background: transparent;
  border-radius: 5px;
  font-size: 0.8rem;
}
.chat-area .chat-main .left .user-chats form .results {
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  height: -moz-fit-content;
  height: fit-content;
  max-height: 350px;
}
.chat-area .chat-main .left .user-chats form .results ul {
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 350px;
  overflow-y: auto;
}
.chat-area .chat-main .left .user-chats form .results ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.chat-area .chat-main .left .user-chats form .results ul li .img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 15px;
}
.chat-area .chat-main .right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat-area .chat-main .right .top {
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
}
.chat-area .chat-main .right .top .back-icon {
  width: 25px;
  cursor: pointer;
  display: none;
  margin-right: 15px;
  margin-left: -10px;
}
.chat-area .chat-main .right .top .user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-area .chat-main .right .top .user section {
  margin-left: 15px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.chat-area .chat-main .right .top .user section .img {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.2);
}
.chat-area .chat-main .right .top .user section h5 {
  margin-left: 15px;
}
.chat-area .chat-main .right .top .menu-icon {
  position: relative;
}
.chat-area .chat-main .right .top .menu-icon img {
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 4px;
}
.chat-area .chat-main .right .top .menu-icon img:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.chat-area .chat-main .right .top .menu-icon .menu {
  width: 150px;
  background-color: #fff;
  opacity: 0;
  z-index: 200;
  position: absolute;
  right: 0px;
  padding: 0px 10px;
  border-radius: 5px;
  right: 10px;
  transform: translateY(-10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  top: -500px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.chat-area .chat-main .right .top .menu-icon .menu ul {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  list-style-type: none;
}
.chat-area .chat-main .right .top .menu-icon .menu ul li {
  width: 150px;
  cursor: pointer;
  padding: 10px 10px;
  font-size: 0.8rem;
  font-weight: 400;
  border-radius: 5px;
}
.chat-area .chat-main .right .top .menu-icon .menu ul li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.chat-area .chat-main .right .top .menu-icon .menu.active {
  opacity: 1;
  position: absolute;
  right: 0;
  top: 50px;
  left: -120px;
}
.chat-area .chat-main .right .body {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding: 15px 0px;
}
.chat-area .chat-main .right .body::-webkit-scrollbar {
  width: 0.6vw;
}
.chat-area .chat-main .right .body scrollbar {
  width: 0.6vw;
}
.chat-area .chat-main .right .body::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.chat-area .chat-main .right .body::-webkit-scrollbar-thumb:hover {
  display: block;
}
.chat-area .chat-main .right .body scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.chat-area .chat-main .right .body scrollbar-thumb:hover {
  display: block;
}
.chat-area .chat-main .right .body:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.chat-area .chat-main .right .body .message {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}
.chat-area .chat-main .right .body .message section {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 325px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.chat-area .chat-main .right .body .message section p {
  font-size: 0.8rem;
  font-weight: 400;
  max-width: calc(100% - 16px);
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: none;
}
.chat-area .chat-main .right .body .message section p span {
  color: #888888;
  text-align: right;
}
.chat-area .chat-main .right .body .message section .taged_message {
  margin-bottom: 3px;
}
.chat-area .chat-main .right .body .message section .taged_message p {
  border-radius: 3px;
}
.chat-area .chat-main .right .body .my_message {
  width: 100%;
  justify-content: flex-end;
}
.chat-area .chat-main .right .body .my_message section {
  justify-content: flex-end;
}
.chat-area .chat-main .right .body .my_message section p {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 0px 15px;
}
.chat-area .chat-main .right .body .frined_message {
  width: 100%;
  justify-content: flex-start;
}
.chat-area .chat-main .right .body .frined_message section {
  justify-content: flex-start;
}
.chat-area .chat-main .right .body .frined_message section p {
  background-color: rgb(0, 123, 255);
  color: #fff;
  border-radius: 0px 15px 15px 15px;
}
.chat-area .chat-main .right .body .frined_message section p span {
  color: #d2d2d2;
}
@media screen and (max-width: 480px) {
  .chat-area .chat-main .right .body .message section {
    max-width: 300px;
  }
  .chat-area .chat-main .right .body .message section p {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 410px) {
  .chat-area .chat-main .right .body .message section {
    max-width: 280px;
  }
  .chat-area .chat-main .right .body .message section p {
    font-size: 0.8rem;
  }
}
.chat-area .chat-main .right .taged_reply {
  width: calc(100% - 20px);
  height: -moz-fit-content;
  height: fit-content;
  max-height: 200px;
  background: transparent;
}
.chat-area .chat-main .right .taged_reply p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  width: calc(100% - 120px);
  font-size: 0.8rem;
  font-weight: 400;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: none;
}
.chat-area .chat-main .right .taged_reply p svg {
  margin-bottom: 5px;
  width: 15px;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  transform: rotate(45deg);
  margin-top: -5px;
}
.chat-area .chat-main .right .bottom {
  width: 100%;
  padding: 10px 0px 10px 0px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  max-height: 80px;
}
.chat-area .chat-main .right .bottom img {
  width: 20px;
  margin-right: 8px;
  cursor: pointer;
}
.chat-area .chat-main .right .bottom input {
  padding: 7px 10px;
  margin-left: 8px;
  background-color: #e1e1e1;
  outline: none;
  border: none;
  width: calc(100% - 120px);
  height: 25px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
.chat-area .chat-main .right .bottom .svg {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 50%;
  background-color: rgba(2, 71, 245, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}
.chat-area .chat-main .right .bottom .svg img {
  margin-left: 8px;
}
.chat-area .chat-main .without-messages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.chat-area .chat-main .without-messages button {
  outline: none;
  background-color: #0245f5;
  border: none;
  padding: 6px 10px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  color: #fff;
  margin-top: 10px;
}
@media screen and (max-width: 750px) {
  .chat-area .chat-main {
    height: calc(100vh - 15px);
  }
  .chat-area .chat-main .left {
    position: fixed;
    left: -320px;
    top: 0;
    z-index: 600;
    transition: all 0.2s ease-in-out;
  }
  .chat-area .chat-main .left .top {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .chat-area .chat-main .left .top .user {
    display: flex;
    align-items: center;
  }
  .chat-area .chat-main .left .top .user .img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .chat-area .chat-main .left .top .user section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;
  }
  .chat-area .chat-main .left .top .user section h5 {
    font-size: 0.9rem;
  }
  .chat-area .chat-main .left .top .user section p {
    font-size: 0.8rem;
  }
  .chat-area .chat-main .left .top .close-icon {
    display: block;
    width: 20px;
    transform: rotate(45deg);
    margin-right: 15px;
    cursor: pointer;
  }
  .chat-area .chat-main .right .top {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 10px;
    background-color: #fff;
  }
  .chat-area .chat-main .right .top .back-icon {
    display: block;
  }
  .chat-area .chat-main .right .body {
    height: calc(100% - 50px);
    margin-top: 50px;
  }
  .chat-area .chat-main .left-active {
    left: 0;
    transition: all 0.2s ease-in-out;
  }
  .chat-area .chat-main .chat-bottom-area {
    width: calc(100% + 10px);
    margin-left: -10px;
  }
}

.form-area {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-area .image {
  width: 50%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #242b81;
}
.form-area .image img {
  width: 90%;
  max-width: 550px;
  border-radius: 5px;
  max-height: 100vh;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1);
}
.form-area form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-area form section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-area form section span {
  font-size: 0.7rem;
  font-weight: 400;
  width: 100%;
  max-width: 300px;
  margin-top: 3px;
  color: #a2a2a2;
}
.form-area form input {
  padding: 10px 15px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
  margin-top: 15px;
  max-width: 300px;
  font-family: "Poppins", sans-serif;
}
.form-area form input:disabled {
  background-color: #ececec;
}
.form-area form input:focus {
  border-color: #3741C4;
}
.form-area form button {
  outline: none;
  border: none;
  background-color: #3741C4;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  padding: 10px 25px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin: auto;
  margin-top: 15px;
  cursor: pointer;
}
.form-area form .bottom {
  display: flex;
  margin-top: 15px;
  width: 100%;
  max-width: 300px;
  justify-content: space-between;
}
.form-area form .bottom p {
  font-size: 0.7rem;
  font-weight: 400;
  cursor: pointer;
}
.form-area form .bottom p:nth-child(1):hover {
  text-decoration-line: underline;
}
.form-area form .bottom p:nth-child(2) {
  color: #3741C4;
}
.form-area form .bottom p:nth-child(2):hover {
  text-decoration-line: underline;
  text-decoration-color: #3741C4;
}
@media screen and (max-width: 830px) {
  .form-area {
    justify-content: center;
  }
  .form-area .image {
    display: none;
  }
  .form-area form {
    width: 100%;
    max-width: 100vw;
  }
  .form-area form section {
    width: calc(100% - 50px);
  }
  .form-area form .bottom {
    width: calc(100% - 30px);
  }
}

.red {
  color: #f02c2c;
}

.mt-7 {
  margin-top: 70px;
}

.center {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center section {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.center section p {
  color: #3b3a3a;
  font-weight: 300;
  font-size: 0.8rem;
}
.center section button {
  margin-top: 10px;
  padding: 10px;
  outline: none;
  border: 1px solid #0245f5;
  border-radius: 5px;
  background-color: #1b81f5;
  color: #fff;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.left-btn {
  margin-top: 15px;
  padding: 5px 10px;
  outline: none;
  background: transparent;
  border: none;
  color: #f32d37;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.left-btn:hover {
  text-decoration-line: underline;
}

.main {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
}

.chat-friends {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
}
.chat-friends .overlay {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 500;
}
.chat-friends .modal {
  width: 280px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 800;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
}
.chat-friends .modal .head {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 7px;
}
.chat-friends .modal .head img {
  width: 15px;
  transform: rotate(45deg);
  padding: 7px;
  background-color: #f5f5f5;
  border-radius: 50%;
  cursor: pointer;
}
.chat-friends .modal .body {
  width: 250px;
}
.chat-friends .modal .body ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-friends .modal .body ul::-webkit-scrollbar {
  width: 0.6vw;
}
.chat-friends .modal .body ul::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.chat-friends .modal .body ul::-webkit-scrollbar-thumb:hover {
  display: block;
}
.chat-friends .modal .body ul:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.chat-friends .modal .body ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
}
.chat-friends .modal .body ul li:hover {
  background-color: #f5f5f5;
}
.chat-friends .modal .body ul li .img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 15px;
}
.chat-friends .modal .body ul li h4 {
  font-size: 0.8rem;
}

.not-found1 {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 15px;
  color: #3a3a3a;
  width: 100%;
}

.not-found2 {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 15px;
  color: #3a3a3a;
  width: 100%;
  display: flex;
  justify-content: center;
}

.groups-feed-area {
  width: 100%;
  height: 100vh;
  margin-top: 50px;
}
.groups-feed-area .groups-feed {
  width: 100%;
  display: flex;
  margin: auto;
}
.groups-feed-area .groups-feed .sidebar-feed {
  width: 100%;
  max-width: 280px;
  background-color: #fff;
  padding: 20px 0px 0px 0px;
  height: calc(100vh - 70px);
  position: sticky;
  top: 50px;
  overflow-y: auto;
}
.groups-feed-area .groups-feed .sidebar-feed::-webkit-scrollbar {
  width: 0.6vw;
}
.groups-feed-area .groups-feed .sidebar-feed::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.groups-feed-area .groups-feed .sidebar-feed::-webkit-scrollbar-thumb:hover {
  display: block;
}
.groups-feed-area .groups-feed .sidebar-feed:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.groups-feed-area .groups-feed .sidebar-feed .head {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  position: sticky;
  top: 0px;
  background-color: #fff;
  height: 60px;
}
.groups-feed-area .groups-feed .sidebar-feed .head svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 50%;
  fill: #444;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.groups-feed-area .groups-feed .sidebar-feed .head svg:hover {
  background-color: #f1f1f1;
}
.groups-feed-area .groups-feed .sidebar-feed .head input {
  width: 190px;
  padding: 12px 15px;
  border-radius: 5px;
  background-color: #f1f1f1;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results {
  position: absolute;
  width: calc(100% - 25px);
  width: 200px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  top: -1000px;
  opacity: 0;
  margin-left: 50px;
  z-index: -5;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results::-webkit-scrollbar {
  width: 0.6vw;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results::-webkit-scrollbar-thumb:hover {
  display: block;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results p {
  font-weight: 400;
  font-size: 0.8rem;
  color: gray;
  margin-bottom: 15px;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results span {
  color: #444;
  font-size: 0.8rem;
  margin-top: 25px;
  font-weight: 400;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results ul {
  list-style-type: none;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results ul li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results ul li section {
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(100% - 16px);
  padding: 8px;
  border-radius: 5px;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results ul li section .img {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border: 1.5px solid #0245f5;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results ul li section h5 {
  font-size: 0.8rem;
  margin-left: 15px;
  color: #4c4c4c;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results ul li section:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  background-color: #f5f5f5;
}
.groups-feed-area .groups-feed .sidebar-feed .head .results-active {
  opacity: 1;
  position: absolute;
  top: 50px;
  overflow-y: auto;
  max-height: 450px;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  height: -moz-fit-content;
  height: fit-content;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list::-webkit-scrollbar {
  width: 0.6vw;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list::-webkit-scrollbar-thumb:hover {
  display: block;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list ul li {
  display: flex;
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.1s ease-in-out;
  height: 50px;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list ul li .img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 10px;
  border: 1px solid #0245f5;
  background-size: cover;
  background-position: center;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list ul li section {
  margin-left: 15px;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list ul li section h5 {
  font-size: 0.8rem;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list ul li section p {
  font-size: 0.7rem;
  color: gray;
  font-weight: 400;
}
.groups-feed-area .groups-feed .sidebar-feed .group-list ul li:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
@media screen and (max-width: 760px) {
  .groups-feed-area .groups-feed .sidebar-feed {
    max-width: 100%;
  }
}
.groups-feed-area .groups-feed .posts-area {
  width: calc(100% - 250px);
  display: flex;
  justify-content: center;
}
.groups-feed-area .groups-feed .posts-area .group-posts {
  width: calc(100% - 20px);
  max-width: 600px;
}

.appbar {
  width: 100%;
  height: 50px;
  background-color: #0c6ddc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
}
.appbar svg {
  width: 20px;
  margin-left: 15px;
  fill: #fff;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
}
.appbar .admin-profile-drop {
  position: relative;
}
.appbar .admin-profile-drop .img {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 20px;
  border: 1.5px solid #fff;
  cursor: pointer;
}
.appbar .admin-profile-drop section {
  width: 230px;
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  right: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin-top: 10px;
  transform: translateY(-10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  top: -600px;
}
.appbar .admin-profile-drop section .profile-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appbar .admin-profile-drop section .profile-view h5 {
  margin-top: 7px;
  font-weight: 400;
  color: #444;
}
.appbar .admin-profile-drop section .profile-view p {
  font-weight: 300;
  color: #666;
  font-size: 0.8rem;
}
.appbar .admin-profile-drop section .profile-view span {
  font-weight: 400;
  color: #666;
  font-size: 0.8rem;
  cursor: pointer;
}
.appbar .admin-profile-drop section .profile-view span:hover {
  text-decoration-line: underline;
}
.appbar .admin-profile-drop section .profile-view .pro-img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1.5px solid #0245f5;
}
.appbar .admin-profile-drop section .profile-view .pro-img:hover {
  filter: brightness(0.8);
}
.appbar .admin-profile-drop .active {
  position: absolute;
  left: -200px;
  opacity: 1;
  transform: translateY(0);
  top: 50px;
}

.admin-drawer {
  width: 250px;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(191, 206, 246, 0.65);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  position: fixed;
  top: 50px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
  z-index: 100;
  left: -300px;
  transition: all 0.3s ease-in-out;
}
.admin-drawer ul {
  width: 100%;
  height: 100%;
  margin-left: 7px;
}
.admin-drawer ul li {
  width: calc(100% - 35px);
  height: 20px;
  display: flex;
  padding: 10px;
  margin-top: 15px;
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  color: #575757;
}
.admin-drawer ul li svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  fill: #3c3c3c;
}
.admin-drawer ul li:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.admin-drawer.active {
  left: 0;
}

.admin-area {
  padding-top: 50px;
}
.admin-area .overview {
  width: 100%;
  padding-bottom: 25px;
}
.admin-area .overview .head {
  max-width: 1300px;
  margin: auto;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.admin-area .overview .head section {
  width: 250px;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1);
  border: 2px solid #87a8fb;
  margin: 15px;
  transition: all 0.2s ease-in-out;
}
.admin-area .overview .head section:hover {
  border-color: #0245f5;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
}
.admin-area .overview .head section:nth-child(1) h4 {
  font-size: 1.1rem;
  color: #555;
}
.admin-area .overview .head section:nth-child(1) ul {
  list-style-type: none;
}
.admin-area .overview .head section:nth-child(1) ul li {
  display: flex;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.05);
  margin-top: 10px;
}
.admin-area .overview .head section:nth-child(1) ul li h5 {
  color: #777;
}
.admin-area .overview .head section:nth-child(1) ul li p {
  margin-left: 10px;
  font-weight: 400;
  font-size: 0.9rem;
}
.admin-area .overview .head section:nth-child(2) .no-admins {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-area .overview .head section:nth-child(2) .no-admins h3 {
  color: #888;
}
.admin-area .overview .head section:nth-child(2) h4 {
  font-size: 1.1rem;
  color: #555;
}
.admin-area .overview .head section:nth-child(2) ul {
  list-style-type: none;
  overflow-y: auto;
  height: 180px;
}
.admin-area .overview .head section:nth-child(2) ul::-webkit-scrollbar {
  width: 0.6vw;
}
.admin-area .overview .head section:nth-child(2) ul::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.admin-area .overview .head section:nth-child(2) ul::-webkit-scrollbar-thumb:hover {
  display: block;
}
.admin-area .overview .head section:nth-child(2) ul:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.admin-area .overview .head section:nth-child(2) ul li {
  display: flex;
  padding: 5px;
  margin-top: 5px;
}
.admin-area .overview .head section:nth-child(2) ul li h5 {
  color: #777;
}
.admin-area .overview .head section:nth-child(2) ul li p {
  margin-left: 10px;
  font-weight: 400;
  font-size: 0.9rem;
}
.admin-area .overview .reports {
  width: calc(100% - 20px);
  max-width: 1000px;
  margin: auto;
  margin-top: 25px;
}
.admin-area .overview .reports .top {
  width: calc(100% - 20px);
  height: 30px;
  padding: 7px 10px;
  background-color: #0245f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.admin-area .overview .reports .top h4 {
  color: #fff;
  font-weight: 500;
}
.admin-area .overview .reports .search {
  width: calc(100% - 2px);
  display: flex;
  justify-content: center;
  background-color: #f1f1f1;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.admin-area .overview .reports .search input {
  width: calc(100% - 30px);
  padding: 10px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Poppins", sans-serif;
  outline: none;
  border-radius: 8px;
  margin-top: 10px;
}
.admin-area .overview .reports ul {
  list-style-type: none;
  padding: 10px;
  background-color: #f1f1f1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
}
.admin-area .overview .reports ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-top: 15px;
}
.admin-area .overview .reports ul li h5 {
  color: #444;
  font-weight: 500;
}
.admin-area .overview .reports ul li p {
  font-size: 0.8rem;
  font-weight: 400;
}
.admin-area .overview .reports ul li .btns {
  display: flex;
  align-items: center;
}
.admin-area .overview .reports ul li .btns button {
  padding: 4px 10px;
  outline: none;
  border-radius: 4px;
  border: none;
  font-family: "Poppins", sans-serif;
  background: transparent;
  cursor: pointer;
}
.admin-area .overview .reports ul li .btns button svg {
  width: 20px;
  height: 25px;
  padding: 7px;
}
.admin-area .admin-create {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}
.admin-area .admin-create form {
  margin-top: 35px;
  width: 100%;
  max-width: 800px;
  background-color: #f1f1f1;
}
.admin-area .admin-create form .top {
  width: calc(100% - 20px);
  height: 30px;
  background-color: #0245f5;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 10px;
}
.admin-area .admin-create form .top h4 {
  color: #fff;
  font-weight: 500;
}
.admin-area .admin-create form .users {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.admin-area .admin-create form .users .search {
  width: 100%;
}
.admin-area .admin-create form .users .search input {
  width: calc(100% - 30px);
  padding: 10px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Poppins", sans-serif;
  outline: none;
  border-radius: 8px;
}
.admin-area .admin-create form .users ul {
  list-style-type: none;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.admin-area .admin-create form .users ul li {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  border-radius: 7px;
  padding: 10px;
  cursor: default;
}
.admin-area .admin-create form .users ul li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.admin-area .admin-create form .users ul li section {
  display: flex;
  margin-right: 10px;
  align-items: center;
}
.admin-area .admin-create form .users ul li section .img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 1px solid #0245f5;
}
.admin-area .admin-create form .users ul li section h5 {
  margin-left: 10px;
}
.admin-area .admin-create form .users ul li svg {
  width: 20px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #0245f5;
  cursor: pointer;
}
.admin-area .admin-created {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}
.admin-area .admin-created form {
  margin-top: 35px;
  width: 100%;
  max-width: 800px;
  background-color: #f1f1f1;
}
.admin-area .admin-created form .top {
  width: calc(100% - 20px);
  height: 30px;
  background-color: #0245f5;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 10px;
}
.admin-area .admin-created form .top h4 {
  color: #fff;
  font-weight: 500;
}
.admin-area .admin-created form .users {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.admin-area .admin-created form .users .search {
  width: 100%;
}
.admin-area .admin-created form .users .search input {
  width: calc(100% - 30px);
  padding: 10px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Poppins", sans-serif;
  outline: none;
  border-radius: 8px;
}
.admin-area .admin-created form .users ul {
  list-style-type: none;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.admin-area .admin-created form .users ul li {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  border-radius: 7px;
  padding: 10px;
  cursor: default;
}
.admin-area .admin-created form .users ul li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.admin-area .admin-created form .users ul li section {
  display: flex;
  margin-right: 10px;
  align-items: center;
}
.admin-area .admin-created form .users ul li section .img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 1px solid #0245f5;
}
.admin-area .admin-created form .users ul li section h5 {
  margin-left: 10px;
}
.admin-area .admin-created form .users ul li svg {
  width: 20px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #ec4040;
  cursor: pointer;
}
.admin-area .admin-groups {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}
.admin-area .admin-groups form {
  margin-top: 35px;
  width: 100%;
  max-width: 800px;
  background-color: #f1f1f1;
}
.admin-area .admin-groups form .top {
  width: calc(100% - 20px);
  height: 30px;
  background-color: #0245f5;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin-area .admin-groups form .top h4 {
  color: #fff;
  font-weight: 500;
}
.admin-area .admin-groups form .top svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  fill: "#fff";
  background-color: rgba(0, 0, 0, 0.07);
}
.admin-area .admin-groups form .top svg:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.admin-area .admin-groups form .users {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.admin-area .admin-groups form .users .search {
  width: 100%;
}
.admin-area .admin-groups form .users .search input {
  width: calc(100% - 30px);
  padding: 10px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Poppins", sans-serif;
  outline: none;
  border-radius: 8px;
}
.admin-area .admin-groups form .users ul {
  list-style-type: none;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  height: 100%;
  max-height: 800px;
  overflow-y: auto;
}
.admin-area .admin-groups form .users ul::-webkit-scrollbar {
  width: 0.6vw;
}
.admin-area .admin-groups form .users ul::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.admin-area .admin-groups form .users ul::-webkit-scrollbar-thumb:hover {
  display: block;
}
.admin-area .admin-groups form .users ul:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.admin-area .admin-groups form .users ul li {
  width: calc(100% - 20px);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 7px;
  padding: 10px;
  cursor: default;
}
.admin-area .admin-groups form .users ul li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.admin-area .admin-groups form .users ul li section {
  display: flex;
  margin-right: 10px;
  align-items: center;
}
.admin-area .admin-groups form .users ul li section .img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  border: 1px solid #0245f5;
}
.admin-area .admin-groups form .users ul li section h5 {
  margin-left: 10px;
}
.admin-area .admin-groups form .users ul li svg {
  width: 20px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #ec4040;
  cursor: pointer;
}
.admin-area .create-group {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-area .create-group .overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.admin-area .create-group .modal {
  width: calc(100% - 20px);
  max-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  z-index: 600;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.admin-area .create-group .modal .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 7px;
  border-bottom: 1px solid #d6d6d6;
}
.admin-area .create-group .modal .head svg {
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.07);
}
.admin-area .create-group .modal .head svg:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.admin-area .create-group .modal .body {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.admin-area .create-group .modal .body section {
  width: calc(100% - 70px);
  max-width: 300px;
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  margin: auto;
  cursor: pointer;
}
.admin-area .create-group .modal .body section:hover {
  filter: brightness(0.8);
}
.admin-area .create-group .modal .body h5 {
  cursor: pointer;
  margin-top: 15px;
}
.admin-area .create-group .modal .body h5:hover {
  text-decoration: underline;
}
.admin-area .create-group .modal .body .el {
  margin-top: 25px;
}
.admin-area .create-group .modal .body input {
  padding: 10px;
  outline: none;
  border: 2px solid #cacaca;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
}
.admin-area .create-group .modal .body input:focus {
  border-color: #0245f5;
}
.admin-area .create-group .modal .body textarea {
  padding: 10px;
  outline: none;
  border: 2px solid #cacaca;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
}
.admin-area .create-group .modal .body textarea::-webkit-scrollbar {
  width: 0px;
}
.admin-area .create-group .modal .body textarea:focus {
  border-color: #0245f5;
}
.admin-area .create-group .modal .body .add-tags {
  width: 100%;
}
.admin-area .create-group .modal .body .add-tags input {
  width: calc(100% - 25px);
}
.admin-area .create-group .modal .body button {
  margin-top: 25px;
  padding: 7px 10px;
  outline: none;
  border: none;
  background-color: #1958f7;
  font-family: "Poppins", sans-serif;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.admin-area .create-group .modal .body button:hover {
  background-color: #0245f5;
}

@media screen and (max-width: 760px) {
  .groups-feed-area .groups-feed .sidebar {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .groups-feed-area .groups-feed .sidebar .head input {
    width: calc(100% - 100px);
    min-width: 200px;
  }
  .groups-feed-area .groups-feed .posts-area {
    width: 100%;
    display: none;
  }
  .groups-feed-area .groups-feed .posts-area .group-posts {
    width: 100%;
    max-width: 100%;
  }
  .groups-feed-area .groups-feed .posts-area .group-posts .posts {
    margin-left: 0px;
  }
}
.people-area {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: inherit;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: none;
}
.people-area .people-modal {
  width: calc(100% - 40px);
  max-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 600px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  position: fixed;
  padding: 10px;
  border-radius: 8px;
  overflow-y: auto;
}
.people-area .people-modal .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.people-area .people-modal .head svg {
  width: 17px;
  height: 17px;
  padding: 10px;
  background-color: #efefef;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  transform: rotate(45deg);
  margin-right: 7px;
  cursor: pointer;
}
.people-area .people-modal .head svg:hover {
  background-color: #d1d1d1;
}
.people-area .people-modal .body {
  overflow-y: auto;
}
.people-area .people-modal .body ul {
  height: calc(100% - 40px);
  max-height: 520px;
  list-style-type: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: -7px;
}
.people-area .people-modal .body ul::-webkit-scrollbar {
  width: 0.6vw;
}
.people-area .people-modal .body ul::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.people-area .people-modal .body ul::-webkit-scrollbar-thumb:hover {
  display: block;
}
.people-area .people-modal .body ul:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  display: block;
}
.people-area .people-modal .body ul li {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 7px;
  width: calc(100% - 15px);
  border-radius: 5px;
  cursor: pointer;
}
.people-area .people-modal .body ul li .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border: 1.5px solid #0245f5;
}
.people-area .people-modal .body ul li h5 {
  margin-left: 10px;
}
.people-area .people-modal .body ul li:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.people-area.active {
  display: flex;
}

.underline:hover {
  text-decoration-line: underline;
}

.tag_post_people_area {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.tag_post_people_area .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}
.tag_post_people_area .modal {
  width: calc(100% - 20px);
  max-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 600px;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  z-index: 1001;
  border-radius: 7px;
  overflow: hidden;
}
.tag_post_people_area .modal .head {
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 10px;
}
.tag_post_people_area .modal .head h5 {
  font-size: 1rem;
}
.tag_post_people_area .modal .head svg {
  width: 17px;
  height: 17px;
  padding: 10px;
  background-color: #efefef;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  transform: rotate(45deg);
  margin-right: 7px;
  cursor: pointer;
}
.tag_post_people_area .modal .head svg:hover {
  background-color: #d1d1d1;
}
.tag_post_people_area .modal .body {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px 7px;
  overflow-y: auto;
}
.tag_post_people_area .modal .body::-webkit-scrollbar {
  width: 0.6vw;
}
.tag_post_people_area .modal .body::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
  padding-left: -20px;
}
.tag_post_people_area .modal .body::-webkit-scrollbar-thumb:hover {
  display: block;
}
.tag_post_people_area .modal .body:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: block;
}
.tag_post_people_area .modal .body ul {
  list-style-type: none;
}
.tag_post_people_area .modal .body ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  padding: 7px 10px;
  width: calc(100% - 30px);
  border-radius: 5px;
  margin-left: -8px;
}
.tag_post_people_area .modal .body ul li .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 1.5px solid #0245f5;
}
.tag_post_people_area .modal .body ul li h5 {
  margin-left: 15px;
}
.tag_post_people_area .modal .body ul li:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.popup {
  transition: all 0.4s ease-in-out;
  transform: scale(1.8);
}

.gray {
  background-color: #f1f1f1;
}

.requests-area {
  width: calc(100% - 270px);
  margin-left: 250px;
  display: flex;
  align-items: flex-start;
  padding-top: 70px;
  padding-bottom: 25px;
}
.requests-area .requests {
  width: 100%;
}
.requests-area .requests h4 {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.9);
}
.requests-area .requests .users {
  width: 100%;
}
.requests-area .requests .users ul {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  place-items: center;
}
.requests-area .requests .users ul li {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 250px;
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
}
.requests-area .requests .users ul li section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.requests-area .requests .users ul li section .img {
  width: 150px;
  height: 130px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}
.requests-area .requests .users ul li section .img .overlay {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.07);
}
.requests-area .requests .users ul li section .img .overlay:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.requests-area .requests .users ul li section h5 {
  margin-left: 27px;
}
.requests-area .requests .users ul li .bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-top: 5px;
}
.requests-area .requests .users ul li .bottom .btns {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.requests-area .requests .users ul li .bottom .btns button {
  margin-right: 15px;
  padding: 5px 12px;
  font-family: "Poppins", sans-serif;
  width: 130px;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.03);
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 500;
}
.requests-area .requests .users ul li .bottom .btns button:first-child {
  background-color: #87a8fb;
  margin-bottom: 5px;
  color: #0245f5;
}
.requests-area .requests .users ul li .bottom .btns button:last-child {
  background-color: #cacaca;
  color: #575757;
}
@media screen and (max-width: 760px) {
  .requests-area {
    width: calc(100% - 30px);
    margin: auto;
    margin-left: 15px;
  }
}

.no-feed-text {
  padding: 7px;
  border: 1.5px solid #87a8fb;
  color: #fff;
  background-color: #0245f5;
  font-weight: 400;
  border-radius: 20px;
}

.report-user {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-user .overlay {
  width: 100%;
  height: 100%;
  min-height: calc(100vh + 200px);
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 500;
}
.report-user .modal {
  width: 100%;
  max-width: 500px;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  border-radius: 6px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border: 1px solid #bbbbbb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 600;
}
.report-user .modal .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.report-user .modal .head .user {
  display: flex;
  align-items: center;
  margin: 7px;
}
.report-user .modal .head .user .img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}
.report-user .modal .head .user h5 {
  margin-left: 15px;
}
.report-user .modal .head img {
  width: 17px;
  padding: 10px;
  background-color: #efefef;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.04);
  transform: rotate(45deg);
  margin-right: 7px;
  cursor: pointer;
}
.report-user .modal .head img:hover {
  background-color: #d1d1d1;
}
.report-user .modal .body {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.report-user .modal .body textarea {
  width: 100%;
  max-width: 400px;
  height: 100px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none;
  background-color: transparent;
}
.report-user .modal .body textarea::-webkit-scrollbar {
  width: 0.6vw;
}
.report-user .modal .body textarea::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.report-user .modal .body textarea::-webkit-scrollbar-thumb:hover {
  display: block;
}
.report-user .modal .body textarea:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.report-user .modal .body button {
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  background-color: rgba(2, 71, 245, 0.768627451);
  padding: 7px 10px;
  width: calc(100% - 35px);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}
.report-user .modal .body button:hover {
  background-color: #0245f5;
}

.image-popup {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-popup .overlay {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}
.image-popup .modal {
  width: 100%;
  max-width: 500px;
  z-index: 600;
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-popup .modal::-webkit-scrollbar {
  width: 0.6vw;
}
.image-popup .modal::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.image-popup .modal::-webkit-scrollbar-thumb:hover {
  display: block;
}
.image-popup .modal:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}
.image-popup .modal img {
  max-width: 100%;
  max-height: 400px;
  border-radius: 5px;
}
.image-popup .modal .btns {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}
.image-popup .modal .btns button {
  width: 110px;
  padding: 7px 10px;
  outline: none;
  border: none;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  cursor: pointer;
}
.image-popup .modal .btns button:first-child {
  background-color: #0245f5;
}
.image-popup .modal .btns button:last-child {
  background-color: #f2281e;
}

.btn-primary {
  background-color: #87a8fb;
  color: #0245f5;
}

.btn-gray {
  background-color: #cacaca;
  color: #575757;
}

.center-requests {
  width: 100vw;
  height: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.center-requests svg {
  width: 100px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #888888;
}
.center-requests h5 {
  color: #888888;
}

.center-notify {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.center-notify svg {
  width: 100px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #888888;
}
.center-notify h5 {
  color: #888888;
}

.new-updates-area {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 300;
  top: -300px;
}
.new-updates-area .updates-icon {
  width: 150px;
  height: 30px;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 3px 7px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
  z-index: 800;
  transition: all 0.4s ease-in-out;
}
.new-updates-area .updates-icon svg {
  width: 20px;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: 3px;
  fill: #0245f5;
}
.new-updates-area .updates-icon h5 {
  font-size: 0.8rem;
  margin-left: 3px;
  color: #0245f5;
}
.new-updates-area .updates-icon:hover {
  background-color: #0245f5;
  color: #fff;
}
.new-updates-area .updates-icon:hover svg {
  fill: #fff;
}
.new-updates-area .updates-icon:hover h5 {
  color: #fff;
}

.new-updates-area.active {
  margin: auto;
  top: 100px;
  transition: all 1s ease-in-out;
}

.underline-hover {
  cursor: pointer;
}
.underline-hover:hover {
  text-decoration-line: underline;
}

@media screen and (max-width: 600px) {
  .hide-md {
    display: none;
  }
}
.hover-light {
  margin-right: 5px;
  margin-left: 5px;
}
.hover-light .image-update {
  border: 1px solid #0245f5;
}
.hover-light .image-update:nth-child(1) {
  margin-right: -5px;
}
.hover-light .image-update:nth-child(3) {
  margin-left: -5px;
}
.hover-light:hover .image-update {
  border-color: #fff;
}

.admin-tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.admin-tags li {
  background-color: rgba(255, 255, 255, 0.5);
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.7);
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 10px;
}
.admin-tags li:hover {
  border-color: #fff;
}

.hover-overlay-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hover-overlay-1 h5 {
  opacity: 0;
  color: #fff;
}
.hover-overlay-1:hover {
  filter: brightness(0.7);
}
.hover-overlay-1:hover h5 {
  opacity: 1;
}

.update-group-about {
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 15px;
}
.update-group-about section textarea {
  width: calc(100% - 20px);
  padding: 10px;
  height: -moz-fit-content;
  height: fit-content;
  height: 130px;
  outline: none;
  border: none;
  background: transparent;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 2px solid #87a8fb;
  margin-top: 15px;
}
.update-group-about section textarea:focus {
  border-color: #0245f5;
}
.update-group-about section input {
  width: calc(100% - 20px);
  padding: 10px;
  outline: none;
  border: none;
  background: transparent;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  border: 2px solid #87a8fb;
}
.update-group-about section input:focus {
  border-color: #0245f5;
}
.update-group-about section button {
  padding: 7px 10px;
  outline: none;
  border: none;
  background-color: #0245f5;
  color: #fff;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.search-group-view {
  background-color: #f1f1f1;
  width: 100%;
  display: flex;
  justify-content: center;
}
.search-group-view input {
  margin-top: 15px;
  width: calc(100% - 30px);
  padding: 10px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-family: "Poppins", sans-serif;
  outline: none;
  border-radius: 8px;
}

.admin-user-view-area {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-user-view-area .overlay {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 800;
  position: fixed;
  top: 0;
  left: 0;
}
.admin-user-view-area .modal {
  width: calc(100% - 20px);
  height: -moz-fit-content;
  height: fit-content;
  max-width: 400px;
  max-height: 500px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  padding: 10px;
  border-radius: 7px;
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
}
.admin-user-view-area .modal .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.admin-user-view-area .modal .head svg {
  width: 20px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #555;
  transform: rotate(45deg);
  cursor: pointer;
}
.admin-user-view-area .modal .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.admin-user-view-area .modal .body .cover-img {
  width: calc(100% - 40px);
  height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 7px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: #f1f1f1;
}
.admin-user-view-area .modal .body .cover-img .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}
.admin-user-view-area .modal .body h3 {
  font-size: 0.9rem;
  color: #333;
  margin-top: 10px;
}
.admin-user-view-area .modal .body textarea {
  width: calc(100% - 40px);
  height: 100px;
  background: transparent;
  outline: none;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
}
.admin-user-view-area .modal .body textarea::-webkit-scrollbar {
  width: 0.6vw;
}
.admin-user-view-area .modal .body textarea::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: none;
}
.admin-user-view-area .modal .body textarea::-webkit-scrollbar-thumb:hover {
  display: block;
}
.admin-user-view-area .modal .body textarea:hover::-webkit-scrollbar-thumb {
  background-color: #c8c8c9;
  border-radius: 5px;
  display: block;
}

@media screen and (min-width: 600px) {
  .hide-lg {
    display: none;
  }
}
.clear-data-permi-area {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clear-data-permi-area .overlay {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 800;
  position: fixed;
  top: 0;
  left: 0;
}
.clear-data-permi-area .modal {
  width: calc(100% - 20px);
  height: -moz-fit-content;
  height: fit-content;
  max-width: 400px;
  max-height: 200px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  padding: 10px;
  border-radius: 7px;
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
}
.clear-data-permi-area .modal .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.clear-data-permi-area .modal .head svg {
  fill: #555;
  width: 20px;
  height: -moz-fit-content;
  height: fit-content;
  transform: rotate(45deg);
  cursor: pointer;
}
.clear-data-permi-area .modal .head svg:hover {
  filter: brightness(1.2);
}
.clear-data-permi-area .modal .body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clear-data-permi-area .modal .body svg {
  width: 50px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #111;
  opacity: 0.3;
}
.clear-data-permi-area .modal .body p {
  font-size: 0.8rem;
  color: #0245f5;
  font-weight: 400;
  opacity: 0.7;
  margin: 15px 0px;
}
.clear-data-permi-area .modal .body button {
  width: calc(100% - 35px);
  padding: 7px 0px;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  color: #0245f5;
  background-color: #87a8fb;
  border-radius: 5px;
  cursor: pointer;
}
.clear-data-permi-area .modal .body button:hover {
  filter: brightness(0.8);
}

.spinner-profile {
  width: 35px;
}

.center3 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center3 svg {
  width: 25px;
}

.posts-loading {
  width: calc(100% - 25px);
  max-width: 600px;
}
.posts-loading .post-loading {
  display: flex;
  flex-direction: column;
}
.posts-loading .post-loading section {
  width: calc(100% - 20px);
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  background-color: #fff;
}
.posts-loading .post-loading section .head {
  display: flex;
  align-items: center;
}
.posts-loading .post-loading section .description {
  margin-top: 15px;
}
.posts-loading .post-loading section .img {
  margin-top: 15px;
}
.posts-loading .post-loading section .comment {
  margin-top: 15px;
}

.have_to_accept {
  width: calc(100% - 30px);
  margin-left: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  background-color: #fff;
}
.have_to_accept section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.have_to_accept section .user {
  width: -moz-fit-content;
  width: fit-content;
}
.have_to_accept section .user .img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1.5px solid #0245f5;
}
.have_to_accept section .user h5 {
  margin-left: 10px;
}
.have_to_accept section .btns {
  display: flex;
}
.have_to_accept section .btns button {
  outline: none;
  border: none;
  padding: 7px 10px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  cursor: pointer;
}
.have_to_accept section .btns button:nth-child(1) {
  background: transparent;
}
.have_to_accept section .btns button:nth-child(1):hover {
  text-decoration-line: underline;
}
.have_to_accept section .btns button:nth-child(2) {
  background-color: #87a8fb;
  color: #0245f5;
}

.group-loading-view {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.profile-loading-view .profile-image {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  max-width: 900px;
  display: flex;
  position: relative;
}

.form-area-popup {
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
}
.form-area-popup form {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-area-popup form section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-area-popup form section span {
  font-size: 0.7rem;
  font-weight: 400;
  width: 100%;
  max-width: 300px;
  margin-top: 3px;
  color: #a2a2a2;
}
.form-area-popup form input {
  padding: 10px 15px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
  margin-top: 15px;
  max-width: 350px;
  font-family: "Poppins", sans-serif;
}
.form-area-popup form input:disabled {
  background-color: #ececec;
}
.form-area-popup form input:focus {
  border-color: #3741C4;
}
.form-area-popup form button {
  outline: none;
  border: none;
  background-color: #3741C4;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  padding: 10px 25px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin: auto;
  margin-top: 15px;
  cursor: pointer;
}
.form-area-popup form .bottom {
  display: flex;
  margin-top: 15px;
  width: 100%;
  justify-content: space-between;
}
.form-area-popup form .bottom p {
  font-size: 0.7rem;
  font-weight: 400;
  cursor: pointer;
}
.form-area-popup form .bottom p:nth-child(1):hover {
  text-decoration-line: underline;
}
.form-area-popup form .bottom p:nth-child(2) {
  color: #3741C4;
}
.form-area-popup form .bottom p:nth-child(2):hover {
  text-decoration-line: underline;
  text-decoration-color: #3741C4;
}
@media screen and (max-width: 830px) {
  .form-area-popup {
    justify-content: center;
  }
  .form-area-popup .image {
    display: none;
  }
  .form-area-popup form {
    width: 100%;
  }
}

.add-username-area {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-username-area form {
  width: calc(100% - 20px);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-username-area form section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-username-area form input {
  padding: 10px 15px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
  margin-top: 15px;
  max-width: 300px;
  font-family: "Poppins", sans-serif;
}
.add-username-area form input:disabled {
  background-color: #ececec;
}
.add-username-area form input:focus {
  border-color: #3741C4;
}
.add-username-area form button {
  outline: none;
  border: none;
  background-color: #3741C4;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  padding: 10px 25px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin: auto;
  margin-top: 15px;
  cursor: pointer;
}

.spinner-username {
  width: 20px;
}

.not-found-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.not-found-page img {
  width: 280px;
}
.not-found-page h3 {
  font-size: 1.2rem;
  color: #111;
}
.not-found-page p {
  font-size: 0.8rem;
  font-weight: 300;
}
.not-found-page button {
  padding: 10px;
  margin-top: 7px;
  outline: none;
  border: 1px solid #0245f5;
  background-color: #0245f5;
  color: #fff;
  font-family: "Poppins", sans-serif;
  border-radius: 20px;
  cursor: pointer;
}

.share-dialog {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
}
.share-dialog .overlay {
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
.share-dialog .model {
  width: calc(100% - 20px);
  max-width: 400px;
  height: 100px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-weight: 400;
  border-radius: 5px;
  z-index: 50000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.share-dialog .model input {
  color: #000;
  font-size: 0.9rem;
  padding: 8px;
  background-color: #cfcdcc;
  width: 100%;
  max-width: 350px;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
}
.share-dialog .model h6 {
  font-size: 1rem;
  margin-bottom: 15px;
}

.img-crop {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-crop .reactEasyCrop_Container {
  width: 100%;
  height: calc(100% - 55px);
  margin-top: 55px;
}

.upload-img {
  padding: 7px 15px;
  outline: none;
  background-color: #0245f5;
  color: #fff;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-family: "Poppins", sans-serif;
}

.crop-overlay {
  display: flex;
  justify-content: center;
  position: relative;
}

.editor {
  position: absolute;
  bottom: 0;
  z-index: 10000;
  /* Mouse-over effects */
}
.editor input {
  position: absolute;
  background-color: #fff;
  -webkit-appearance: none;
  /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  /* Full-width */
  height: 25px;
  /* Specified height */
  background: #d3d3d3;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}
.editor:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

.drop-files-area {
  width: 200px;
  height: 200px;
  border-radius: 5px;
  border: 1px dotted #444;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.drop-files-area svg {
  width: 50px;
  height: -moz-fit-content;
  height: fit-content;
  fill: #888;
}
.drop-files-area strong {
  font-size: 0.8rem;
  color: #444;
  margin: 10px 0px;
}
.drop-files-area button {
  cursor: pointer;
  background-color: #0245f5;
  color: #fff;
  padding: 7px 10px;
  border-radius: 5px;
  outline: none;
  border: none;
}

.select-aspect {
  margin-right: 15px;
  outline: none;
  padding: 0px;
  width: 120px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
}
.select-aspect .option {
  outline: none;
  padding: 10px;
}

.post-img-div span {
  width: 100%;
}

.Toastify__toast-body > div:last-child {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
}

.Toastify__toast-theme--light {
  border-radius: 10px;
}

.Toastify__toast {
  border-radius: inherit;
}

.Toastify__toast {
  border-radius: 10px;
}

.css-d7l1ni-option {
  background: transparent;
}

.profile-select-posts-type {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 600px;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
}
.profile-select-posts-type button {
  background: transparent;
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.profile-select-posts-type button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.profile-select-posts-type button:last-child {
  margin-left: 15px;
}

textarea::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.angle-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.angle-icon svg {
  width: 20px;
  height: 20px;
  fill: rgba(0, 0, 0, 0.7);
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.svg-div-size-20 svg {
  width: 20px;
  height: 20px;
}

.more-post-btn {
  padding: 6px 10px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  outline: none;
  border: none;
  background-color: #0245f5;
  cursor: pointer;
}
.more-post-btn svg {
  fill: #fff;
  width: 15px;
  height: 15px;
}
.more-post-btn p {
  fill: #fff;
  margin-left: 10px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.to-bottom {
  background: transparent;
  outline: none;
  border: none;
  position: fixed;
  bottom: 65px;
  right: 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #1c91ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.to-bottom svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
@media screen and (min-width: 749px) {
  .to-bottom {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */